<script>
  import { createEventDispatcher, onMount } from "svelte";
  import axios from "axios";
  import Input from "./Input.svelte";
  import CustomSelect from "./CustomSelect.svelte";
  import { userStore, isAuthorized, submitAllowed } from "../store.js";
  import { translate } from "../translation";
  import { get } from "svelte/store";

  const dispatch = createEventDispatcher();
  let notifications = [];
  let loading = false;
  let canSubmit = false;
  let formData = {};
  let allInputsValid = false;
  let inputValidity = {};

  export let header = "";
  export let inputs = [];
  export let apiEndpoint = "";
  export let payload = {};

  function addNotification(header, message, type = "info", duration = 3000) {
    dispatch("notify", { header, message, type, duration });
  }

  const validatePasswords = () => {
    if (formData.pass_new && formData.pass_new_double) {
      const newPassword = formData.pass_new || "";
      const confirmPassword = formData.pass_new_double || "";
      const passwordsMatch =
        newPassword !== "" && newPassword === confirmPassword;
      return passwordsMatch;
    } else {
      return true;
    }
  };

  const validateForm = () => {
    const allInputsValid = Object.values(inputValidity).every(Boolean);
    return allInputsValid && validatePasswords();
  };

  onMount(() => {
    inputs.forEach((input) => {
      formData[input.identifier] = input.default || "";
    });
    console.log("onMount - formData:", formData);
    submitAllowed.subscribe((value) => {
      canSubmit = value && validateForm();
    });
  });

  const handleSubmit = async () => {
    if (canSubmit) {
      loading = true;
      try {
        formData["token"] = get(userStore).token;
        const response = await axios.post(apiEndpoint, formData);
        console.log(response);
        if (response.data.status == "success") {
          addNotification("Супер", "Всё получилось!", "info", 4000);
        } else {
          addNotification(
            "Упс",
            "Убедитесь, что все данные заполнены верно и попробуйте снова",
            "error",
            4000
          );
        }
      } catch (error) {
        console.error("Ошибка загрузки формы [CLSRM-FORM]", error);
      } finally {
        loading = false;
      }
    }
  };

  const updateFormData = (identifier, value) => {
    formData[identifier] = value;
    canSubmit = validateForm();
  };

  const handleValidityChange = (event) => {
    const { identifier, isValid } = event.detail;
    inputValidity[identifier] = isValid;
    canSubmit = validateForm();
  };
</script>

<div class="user--generic-form-main-inside-full">
  <h1>{header}</h1>
  <form
    class="user--generic-form-main-form-wrap-ns"
    on:submit|preventDefault={handleSubmit}
  >
    {#each inputs as input}
      {#if input.type === "custom-select"}
        <CustomSelect
          {input}
          on:change={(e) => updateFormData(input.identifier, e.detail.value)}
        />
      {:else}
        <Input
          {input}
          on:change={(e) => updateFormData(input.identifier, e.detail.value)}
          on:validityChange={handleValidityChange}
        />
      {/if}
    {/each}
    {#if loading}
      <div class="centered-div">
        <div class="loading-huge jello-horizontal"></div>
      </div>
    {:else}
      <button
        class="user--class-generic-button-3 {!canSubmit
          ? 'disabled-button'
          : ''}"
        disabled={!canSubmit}
        type="submit">{translate("Готово", "Complete", "完成")}</button
      >
    {/if}
  </form>
</div>

<style>
  .centered-div {
    width: 100%;
    height: max-content;
    padding-block: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading-huge {
    width: 50px;
    height: 50px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
</style>
