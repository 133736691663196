<script>
    import { onMount } from 'svelte';
  
    export let siteKey = '0a8e059a-d942-4108-be04-7c3ec62a3fe8';
    export let onVerifyCallback = () => {}; 
  
    let hcaptchaWidgetId;
    let captchaResponse = null;
    const resetCaptchaIcon = `
<svg fill="#85ADD1" style="width: 100%; height: 100%" viewBox="0 0 1920 1920">
    <path d="M960 0v112.941c467.125 0 847.059 379.934 847.059 847.059 0 467.125-379.934 847.059-847.059 847.059-467.125 0-847.059-379.934-847.059-847.059 0-267.106 126.607-515.915 338.824-675.727v393.374h112.94V112.941H0v112.941h342.89C127.058 407.38 0 674.711 0 960c0 529.355 430.645 960 960 960s960-430.645 960-960S1489.355 0 960 0" fill-rule="evenodd"/>
</svg>`;
  
    function onVerify(response) {
      captchaResponse = response;
    //  console.log("Captcha verified, response:", response);
      onVerifyCallback(response);
    }
  
    onMount(() => {
      hcaptchaWidgetId = hcaptcha.render('hcaptcha-container', {
        sitekey: siteKey,
        callback: onVerify
      });
    });
  
    function resetCaptcha() {
      if (hcaptchaWidgetId !== null) {
        hcaptcha.reset(hcaptchaWidgetId);
      }
    }
  </script>
  
  <div class="captcha-wrapper">
  <div id="hcaptcha-container"></div>
  
  <div class="reset-captcha-btn" on:click={resetCaptcha}>{@html resetCaptchaIcon}</div>
</div>
  <style>
    .captcha-wrapper {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
    }
    .reset-captcha-btn {
        width: 30px;
        height: 30px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  </style>
  