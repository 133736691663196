<script>
  import { onMount } from "svelte";
  import { writable } from "svelte/store";
  import { fly, blur } from "svelte/transition";
  import { translate } from "../translation";
  import { isAuthorized, userStore, API_BASE_URL } from "../store.js";
  import GenericSelect from "./GenericSelect.svelte";
  import PushNotification from "./PushNotification.svelte";
  import axios from "axios";

  export let showNewCourseInterface;    
  export let setShowNewCourseInterface; 
  export let courseCreationTrigger;  
  export let setCourseCreationTrigger; 
  export let onCourseCreated;

  let notifications = [];
  function addNotification(header, message, type = "info", duration = 3000) {
    notifications = [...notifications, { header, message, type, duration }];
    setTimeout(() => {
      notifications = notifications.slice(1);
    }, duration);
  }

  // Define the writable stores for form fields
  let courseName = writable("");
  let courseAge = writable("");
  let courseAccess = writable("private");
  let collectionType = writable("coursebook");
  let selectedLevel = writable("");

  const levels = ["a1", "a2", "b1", "b2", "c1", "c2"];
  const columnOneArr = [
    translate("Начальный (I) - A1", "Beginner (I) - A1", "初级 (I) - A1"),
    translate("Начальный (II) - A2", "Beginner (II) - A2", "初级 (II) - A2"),
    translate("Средний (I) - B1", "Intermediate (I) - B1", "中级 (I) - B1"),
    translate("Средний (II) - B2", "Intermediate (II) - B2", "中级 (II) - B2"),
    translate("Продвинутый (I) - C1", "Advanced (I) - C1", "高级 (I) - C1"),
    translate("Продвинутый (II) - C2", "Advanced (II) - C2", "高级 (II) - C2"),
  ];
  const columnTwoArr = [
    translate(
      "Подходит для учащихся, которые только начинают учить язык.",
      "Suitable for students who are just beginning to learn the language.",
      "适合刚开始学习语言的学生。"
    ),
    translate(
      "Подходит для учащихся с самой начальной базой знаний.",
      "Suitable for students with a very basic knowledge base.",
      "适合具有最基础知识的学生。"
    ),
    translate(
      "Подходит для учащихся, которые уже имеют умеренно проработанные языковые компетенции в основных языковых аспектах",
      "Suitable for students who already have moderately developed language competencies in the fundamental aspects of the language.",
      "适合在语言的基本方面已经具有适度发展语言能力的学生。"
    ),
    translate(
      "Подходит для учащихся, которые уже имеют хорошо развитые языковые компетенции.",
      "Suitable for students who already have well-developed language competencies.",
      "适合已经拥有良好语言能力的学生。"
    ),
    translate(
      "Подходит для учащихся, которые стремятся к совершенствованию владения языком.",
      "Suitable for students who are aiming for mastery of the language.",
      "适合追求语言精通的学生。"
    ),
    translate(
      "Подходит для учащихся, которые стремятся достичь высокого уровня владения языком.",
      "Suitable for students who are striving to achieve a high level of language proficiency.",
      "适合努力达到高水平语言能力的学生。"
    ),
  ];

  let courseUploading = false;
  async function createCourse() {
    courseUploading = true;
    const newCourse = {
      courseName: $courseName,
      token: $userStore.token,
      courseAge: $courseAge,
      courseAccess: $courseAccess,
      collectionType: $collectionType,
      selectedLevel: $selectedLevel,
    };
    console.log("Composed newCourse object", newCourse);

    try {
      const response = await axios.post(
        `${$API_BASE_URL}/createCourse`,
        newCourse
      );
      console.log(response.data);
      if (response.data.success) {
        // setCourseCreationTrigger(true);  
        setShowNewCourseInterface(false); 
        hidePopup();
        if (onCourseCreated) {
          onCourseCreated();
        }
      } else {
        console.error("Failed to create course:", response.data.message);
      }
    } catch (error) {
      console.log("course creation err");
      // console.log(error);
    }
  }

  const courseAccessOptions = [
    {
      value: "private",
      description: translate("Приватный", "Private", "私人"),
    },
    { value: "public", description: translate("Публичный", "Public", "公开") },
  ];

  const collectionTypeOptions = [
    {
      value: "coursebook",
      description: translate("Пособие", "Coursebook", "课本"),
    },
    {
      value: "workbook",
      description: translate("Сборник упражнений", "Workbook", "练习册"),
    },
    {
      value: "standardcourse",
      description: translate("Стандартный курс", "Standard course", "标准课程"),
    },
  ];

  function showPopup() {
    setShowNewCourseInterface(true); 
    fetchCourses();
  }

  function hidePopup() {
    setShowNewCourseInterface(false); 
  }
</script>

{#each notifications as { header, message, type, duration }}
  <PushNotification {header} {message} {type} {duration} />
{/each}
{#if showNewCourseInterface}
  <div
    class="course-creation"
    in:blur={{ duration: 500 }}
    out:blur={{ duration: 500 }}
  >
    <div class="course-creation-header">Создание курса</div>
    <div class="course-creation-exp">
      Создайте курс, рассчитанный на определённую группу учащихся.
    </div>

    <!-- Language Level Selection -->
    <div class="level-selection">
      <h5>Выберите уровень языка</h5>
      <div class="levels-flex">
        {#each levels as level, index}
          <div
            on:click={() => selectedLevel.set(level)}
            class="level-main-wrap {$selectedLevel === level ? 'selected' : ''}"
          >
            <h6>{columnOneArr[index]}</h6>
            <p>{columnTwoArr[index]}</p>
          </div>
        {/each}
      </div>
    </div>

    <!-- Course Information -->
    <div class="course-info">
      <h5>Заполните основную информацию</h5>
      <div class="course-info-main-wrap">
        <label>{translate("Название курса", "Course name", "课程名称")}</label>
        <input
          type="text"
          bind:value={$courseName}
          placeholder={translate(
            "Китайский - начальный (1) - 13-15 лет",
            "Fill in here",
            "输入一下"
          )}
        />
      </div>
      <div class="course-info-main-wrap">
        <label
          >{translate("Возраст учащихся", "Students' age", "学生的年龄")}</label
        >
        <input type="text" bind:value={$courseAge} placeholder="13-15" />
      </div>
      <div class="course-info-main-wrap">
        <label>{translate("Доступ", "Access", "权限")}</label>
        <GenericSelect
          bind:selected={$courseAccess}
          options={courseAccessOptions}
          placeholder={translate(
            "Выберите доступ",
            "Select access",
            "选择权限"
          )}
        />
      </div>
    </div>

    <!-- Collection Type -->
    <div class="collection-type">
      <h5>Тип коллекции</h5>
      <div class="collection-type-main-wrap">
        <label
          >{translate("Выбранный тип", "Selected type", "选择的类型")}</label
        >
        <GenericSelect
          bind:selected={$collectionType}
          options={collectionTypeOptions}
          placeholder={translate("Выберите тип", "Select type", "选择类型")}
        />
      </div>
    </div>
    {#if courseUploading}
      <div style="margin: 0 auto;" class="loading-huge jello-horizontal"></div>
    {:else}
      <div class="user--class-generic-button-3" on:click={createCourse}>
        Создать курс
      </div>
      <div class="user--class-generic-button-3" on:click={hidePopup}>
        Отменить
      </div>
    {/if}
  </div>
{/if}

<style>
  .course-creation {
    display: flex;
    flex-direction: column;
    gap: 60px;
    width: 90%;
    margin: 0 auto;
    padding: 30px;
    border: 2px solid #d9d9d9;
    border-radius: 40px;
    position: relative;
  }
  .course-creation-header {
    font-size: 30px;
    font-weight: 700;
    color: #000000;
    width: max-content;
    height: max-content;
    justify-self: center;
    align-self: center;
    text-align: center;
  }
  .course-creation-exp {
    font-size: 20px;
    color: #5c5c5c;
    width: max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .level-selection,
  .course-info,
  .collection-type {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  .level-selection h5,
  .course-info h5,
  .collection-type h5 {
    font-size: 25px;
    font-weight: 650;
    color: #5c5c5c;
    text-align: left;
  }
  .level-main-wrap,
  .course-info-main-wrap,
  .collection-type-main-wrap {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border: 2px solid #0c5ca450;
    border-radius: 40px;
    padding: 25px;
  }
  .level-main-wrap label,
  .course-info-main-wrap label,
  .collection-type-main-wrap label {
    font-size: 20px;
    font-weight: 800;
    color: #000000;
    margin: 0 auto;
    justify-self: center;
  }
  .level-main-wrap input,
  .course-info-main-wrap input,
  .collection-type-main-wrap input {
    width: 100%;
    height: max-content;
    padding-block: 10px;
    padding-inline: 20px;
    text-align: center;
    font-size: 22px;
    font-weight: 350;
    color: #5c5c5c;
    border: 1px solid #0c5ca450;
    border-radius: 50px;
  }
  .level-main-wrap h6 {
    font-size: 25px;
    font-weight: 700;
    color: #000000;
    margin: 0 auto;
  }
  .level-main-wrap p {
    font-size: 20px;
    color: #5c5c5c;
    margin: 0 auto;
    font-weight: 350;
  }
  .levels-flex {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  .selected {
    background-color: #0c5ca420;
  }
  .level-main-wrap {
    cursor: pointer;
    transition: all 0.2s linear;
  }
  .level-main-wrap:hover {
    background-color: #0c5ca420;
  }
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @media (max-width: 750px) {
    .level-main-wrap input,
    .course-info-main-wrap input,
    .collection-type-main-wrap input {
      width: 100%;
    }
    .course-creation {
      width: 100%;
      padding: 20px;
      gap: 20px;
    }
    .course-creation-exp {
      width: 100%;
      text-align: center;
    }
    .level-main-wrap, .course-info-main-wrap, .collection-type-main-wrap {
      width: 100%;
    }
    .level-selection, .course-info, .collection-type {
      text-align: center;
      width: 100%;
    }
  }
</style>
