<!-- Generic custom select component -->
<script>
    import { createEventDispatcher } from 'svelte';
    export let options = [];
    export let selected = '';
    export let placeholder = 'Select an option';
    let expandIcon = `<svg style="width: 100%; height: 100%" viewBox="0 0 24 24" fill="none"><path d="M18 9L12 15L6 9" stroke="#0c5ca450"/></svg>`;
  
    const dispatch = createEventDispatcher();
    let showOptions = false;
  
    function selectOption(option) {
      selected = option.value;
      showOptions = false;
      dispatch('change', { value: selected });
    }
  
    $: displayText = options.find(option => option.value === selected)?.description || placeholder;
  </script>
  
  <style>
    .custom-select-wrapper {
      position: relative;
      width: 100%;
    }
    .custom-select {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background: #fff;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .options-wrapper {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 4px;
      background: #fff;
      max-height: 200px;
      overflow-y: auto;
      z-index: 1000;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    }
    .option {
      padding: 10px;
      cursor: pointer;
    }
    .option:hover {
      background: #f1f1f1;
    }
    .exp-icon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  </style>
  
  <div class="custom-select-wrapper">
    <div class="custom-select" on:click={() => (showOptions = !showOptions)}>
      <span>{displayText}</span>
      <div class="exp-icon">{@html expandIcon}</div> 
    </div>
    {#if showOptions}
      <div class="options-wrapper">
        {#each options as option}
          <div class="option" on:click={() => selectOption(option)}>
            {option.description}
          </div>
        {/each}
      </div>
    {/if}
  </div>
  