  <script>
    import { onMount } from "svelte";
    import gsap from "gsap";
    import {notification} from '../store.js';

    export let message = "";
    export let header = "";
    export let type = "info";
    export let duration = 30000; // Duration before the notification disappears
    let visible = true;
    let notificationElement;
    let expandedHeight = 0;

    let isMobile = false;

    if (typeof window !== "undefined") {
      isMobile = window.innerWidth < 800;
      window.addEventListener("resize", () => {
        isMobile = window.innerWidth < 800;
      });
    }

    onMount(() => {
      // Calculate and set the height in advance
      notificationElement.style.height = "auto"; // Set height to auto to calculate
      notificationElement.style.width = isMobile ? "95%" : "50%"; // Expand width to calculate height
      notificationElement.style.padding = "1rem";
      notificationElement.style.zIndex = 999999;
      expandedHeight = notificationElement.offsetHeight; // Get the calculated height
      notificationElement.style.width = "80px"; // Reset width
      notificationElement.style.height = "80px"; // Reset height to initial value

      // Animate the notification appearing from the top
      gsap
        .fromTo(
          notificationElement,
          { opacity: 0, scale: 0, y: -50 }, // Start state
          {
            opacity: 1,
            scale: 1,
            y: "5%",
            width: "80px",
            height: "80px",
            duration: 0.2, // Faster initial animation
            ease: "power4.inOut", // Custom ease for [SLOW -- FAST] effect
          }
        )
        .then(() => {
          // Apply heartbeat animation
          gsap.to(notificationElement, {
            scale: 1.1,
            duration: 0.25,
            repeat: 1,
            yoyo: true,
            ease: "power2.inOut",
          });

          // Pause for a fraction before expanding
          setTimeout(() => {
            // Expand the notification width to 50-60% and reveal text
            gsap.to(notificationElement, {
              width: isMobile ? "95%" : "50%",
              height: `${expandedHeight}px`,
              padding: "1rem",
              borderRadius: "40px", // Use 40px border-radius for the expanded state
              duration: 0.3, // Faster expansion
              ease: "power2.out", // Custom ease for expansion
              onComplete: () => {
      const headerElement = notificationElement.querySelector(".header");
    const bodyElement = notificationElement.querySelector(".notification-body");

    const elementsToAnimate = [];

    if (headerElement) elementsToAnimate.push(headerElement);
    if (bodyElement) elementsToAnimate.push(bodyElement);

    if (elementsToAnimate.length > 0) {
                gsap.to(
                  elementsToAnimate,
                  {
                    opacity: 1,
                    duration: 0.2, // Faster text reveal
                  }
                );
    }
              },
            });
          }, 500); // Pause for 0.5s before expansion
        });

      // Trigger upward fade-out animation before hiding the notification
      setTimeout(() => {
    // First, fade out the text
    if (!notificationElement) return;
    const headerElement = notificationElement.querySelector(".header");
    const bodyElement = notificationElement.querySelector(".notification-body");

    const elementsToAnimate = [];

    if (headerElement) elementsToAnimate.push(headerElement);
    if (bodyElement) elementsToAnimate.push(bodyElement);

    gsap.to(elementsToAnimate, {
      opacity: 0,
      duration: 0.2,
      onComplete: () => {
        // Then move the block up while fading it out
        gsap.to(notificationElement, {
          y: "-100%", // Move upward off-screen
          opacity: 0, // Fade out completely
          duration: 0.3,
          ease: "power4.out", // Fast-Slow effect
          onComplete: () => {
            visible = false; // Finally hide the element
          },
        });
      },
    });
  }, duration - 1000); // Trigger the upward fade-out animation 1 second before the timeout

    });
  </script>

  {#if visible}
    <div bind:this={notificationElement} class="notification {type}">
      {#if header}
        <div class="header">{header}</div>
      {/if}
      {#if message}
        <div class="notification-body">{message}</div>
      {/if}
    </div>
  {/if}

  <style>
    .notification {
      position: fixed;
      top: 5%; /* Appears near the top */
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%; /* Start as a circle */
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px -5px;
      color: white;
      z-index: 9999;
      width: 80px; /* Small width to start */
      height: 80px; /* Small height to start */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 0;
      overflow: hidden;
    }

    .header,
    .notification-body {
      opacity: 0; /* Start invisible */
      text-align: center;
      transition: opacity 0.15s ease; /* Faster transition */
    }

    .header {
      font-size: 25px;
      font-weight: 800;
    }

    .notification-body {
      font-size: 18px;
      color: #5c5c5c;
      font-weight: 350;
    }

    .info {
      background-color: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(10px);
      color: #000000;
      -webkit-backdrop-filter: blur(10px);
      border: 3px solid rgba(76, 175, 80, 0.5);
    }

    .success {
      background-color: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(10px);
      color: #000000;
      -webkit-backdrop-filter: blur(10px);
    }

    .error {
      background-color: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(10px);
      color: #000000;
      -webkit-backdrop-filter: blur(10px);
      border: 3px solid rgba(249, 127, 119, 0.5);
    }

    @media (max-width: 750px) {
      .header {
        font-size: 22px;
      }
      .notification-body {
        font-size: 18px;
      }
    }
  </style>
