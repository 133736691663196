<script>
    import { writable, derived, get } from 'svelte/store';
    import { sendSocketEvent } from "../websocket.js";
    import {userStore, highlightAnswer} from '../store.js';
    import { onMount } from "svelte";

    // IMPORTANT: isAllCorrect + isCreative -- preview each component and assign values
    // these variables dynamically!! 


    const iconSubmitted = `<svg fill="#4CBB9A" style="width: 100%; height: 100%" viewBox="0 0 32 32"><path d="m16 0c8.836556 0 16 7.163444 16 16s-7.163444 16-16 16-16-7.163444-16-16 7.163444-16 16-16zm0 2c-7.7319865 0-14 6.2680135-14 14s6.2680135 14 14 14 14-6.2680135 14-14-6.2680135-14-14-14zm6.6208153 9.8786797c.3905243.3905242.3905243 1.0236892 0 1.4142135l-7.0710678 7.0710678c-.3626297.3626297-.9344751.3885319-1.3269928.0777064l-.0872208-.0777064-4.24264068-4.2426407c-.39052429-.3905242-.39052429-1.0236892 0-1.4142135.39052428-.3905243 1.02368928-.3905243 1.41421358 0l3.5348268 3.5348268 6.3646681-6.3632539c.3905243-.3905243 1.0236893-.3905243 1.4142136 0z"/></svg>`;

    export let slideData;
    export let exerciseID;
    export let location; // location can be 'revision', 'homework', or 'classroom'
    export let completedData;

    let hintExpanded = writable(false);
    function expandHint() {
    hintExpanded.set(!$hintExpanded);
  }

  let selectedTableList = writable(
  slideData.visibleArray.map(() => '')
);

console.log(
  '[DEBUG] Selected table list:', $selectedTableList
)

let answerFeedback = writable(
  slideData.answer.map(() => '')
);


    let submitted = writable(false);

    function handleInput(itemIndex, value) {
  selectedTableList.update(answers => {
    answers[itemIndex] = value || '';  // Ensure we store a string, even if the value is null or undefined

  
       // Recomposed event detail object
       const eventDetails = {
  actionName: {
    kind: "tablelist", // This represents the type of the action
    blockIndex: 0, // hardcoded
    itemIndex: itemIndex, // Index of the item being updated
    answer: value,  // The value entered by the student
    id: exerciseID  // The exercise ID
  },
  isAllCorrect: false,  // Set to false initially
  answers: get(selectedTableList).map(ans => ans ? ans.toLocaleLowerCase('ru') : '')
};

    // Send the WebSocket event to the teacher panel
    sendSocketEvent('studentAnswerDynamic', eventDetails);

    console.log('Updated answers:', answers); // Debugging output
    return answers;
  });
}

$: if ($highlightAnswer.id === exerciseID) {
  const eventAnswer = $highlightAnswer.answer;
  const itemIndex = $highlightAnswer.itemIndex;

  // Ensure that itemIndex is valid
  if (typeof itemIndex !== 'undefined' && slideData.answer[itemIndex]) {
    // Update feedback based on the highlighted answer
    answerFeedback.update(fb => {
      fb[itemIndex] = eventAnswer === slideData.answer[itemIndex].toLocaleLowerCase('ru') ? 'correct' : 'incorrect';
      return fb;
    });

    // Handle input with the highlighted answer
    handleInput(itemIndex, eventAnswer);
  } else {
    console.error(`Invalid itemIndex or answer: itemIndex = ${itemIndex}, answer = ${slideData.answer[itemIndex]}`);
  }
}



function checkIfAllFieldsFilled() {
    return get(selectedTableList).every(field => field && field.trim() !== '');
}


function checkTableListAnswers(correctAnswers, userAnswers) {
  let totalQuestions = 0;
  let totalScore = 0;

  for (let itemIndex = 0; itemIndex < correctAnswers.length; itemIndex++) {
    const correctAnswer = typeof correctAnswers[itemIndex] === 'string' ? correctAnswers[itemIndex].trim().toLowerCase() : '';
    const userAnswer = typeof userAnswers[itemIndex] === 'string' ? userAnswers[itemIndex].trim().toLowerCase() : '';

    if (correctAnswer) {
      totalQuestions++;
      const correctWords = correctAnswer.split(' ');
      const userWords = userAnswer.split(' ');
      let matchingWords = 0;
      for (const word of userWords) {
        if (correctWords.includes(word)) matchingWords++;
      }
      totalScore += (matchingWords / correctWords.length) * 100;
      answerFeedback.update(fb => {
        fb[itemIndex] = (matchingWords / correctWords.length) === 1 ? 'correct' : 'incorrect';
        return fb;
      });
    } else {
      answerFeedback.update(fb => {
        fb[itemIndex] = 'not-defined';
        return fb;
      });
    }
  }
  return { totalQuestions, totalScore };
}



function submitAnswers() {
  const correctAnswers = slideData.answer.map(answer => typeof answer === 'string' ? answer.toLocaleLowerCase('ru').trim() : '');
  const userAnswers = get(selectedTableList).map(answer => typeof answer === 'string' ? answer.toLocaleLowerCase('ru').trim() : '');

  const { totalQuestions, totalScore } = checkTableListAnswers(correctAnswers, userAnswers);

  submitted.set(true);

  let endpoint;
  if (location === "revision") {
    endpoint = "exerciseCompletedRevision";
  } else if (location === "homework") {
    endpoint = "exerciseCompletedRevision";
  } else if (location === "classroom") {
    endpoint = "exerciseCompleted";
  }


  const eventDetails = {
      actionName: {
        exerciseID: exerciseID,
        isCorrect: isAllCorrect ? 'true' : 'false', // Convert boolean to string
        isCreative: false,
        partialScore: totalScore
      },
      answers: userAnswers, // User's answers
      isAllCorrect: isAllCorrect ? 'true' : 'false' // Whether all answers are correct
    };

  // Send the event with the necessary data
  sendSocketEvent(endpoint, eventDetails);
}


    const allFieldsFilled = derived(selectedTableList, $selectedTableList => {
    return $selectedTableList.every(field => typeof field === 'string' && field.trim() !== '');
});



let tipsWithClass = derived(selectedTableList, $selectedTableList => {
    const allAnswers = $selectedTableList.map(answer => answer ? answer.toLocaleLowerCase('ru').trim() : '');
    return slideData.answer.map(answer => {
        return allAnswers.includes(answer ? answer.toLocaleLowerCase('ru').trim() : '') ? 'coloured' : '';
    });
});


onMount(() => {
  // Check if completedData exists and if studentAnswer is not null
  if (completedData && completedData.studentAnswer != null) {
    try {
      // Parse the stored student answers
      const parsedStudentAnswer = JSON.parse(completedData.studentAnswer);
      if (Array.isArray(parsedStudentAnswer)) {
        // Loop through the parsed answers and fill the corresponding inputs
        parsedStudentAnswer.forEach((answer, itemIndex) => {
          handleInput(itemIndex, answer.toLocaleLowerCase('ru').trim());  // Update the inputs with previous answers
        });

        // Update the feedback for each answer based on comparison with the correct answers
        answerFeedback.update(fb => {
          const ua = get(selectedTableList); // Get the user answers from the store
          for (let itemIndex = 0; itemIndex < slideData.answer.length; itemIndex++) {
            if (
              ua[itemIndex] &&
              ua[itemIndex].toLocaleLowerCase('ru') === slideData.answer[itemIndex].toLocaleLowerCase('ru')
            ) {
              fb[itemIndex] = 'correct';
            } else {
              fb[itemIndex] = 'incorrect';
            }
          }
          return fb;
        });

        // Mark the exercise as submitted to prevent further edits
        submitted.set(true);
      }
    } catch (error) {
      console.error("Error parsing completedData studentAnswer:", error);
    }
  }
});


function proceedNext() {
  sendSocketEvent('proceedSelf', '');
    }


</script>

{#if slideData.displayTips && slideData.displayTips === "true"}
<div class="user--exercise-multiple_sequence_sdisplay">
  {#each Array.from(new Set(slideData.answer.flat())) as answer, index}
    <span class="user--exercise-multiple_sequence_hint {get(tipsWithClass)[index]}" data-repetitions={slideData.answer.flat().filter(a => a === answer).length}>
      {answer}
    </span>
  {/each}
</div>
{/if}

<div class="user--class-exercise-header">
  {@html slideData.textContent}
</div>

{#if slideData.hint && slideData.hint[0] && $hintExpanded}
<div class="user--exercise-hint-mini">
  {slideData.hint[0]}
</div>
{/if}

<div class="table-list-exercise">
<div class="user--exercise-block-tablelist-wrapper">

    <div class="user--exercise-match-container">
      {#each slideData.visibleArray as item, itemIndex}
        <div class="user--exercise-item-wrapper">
          <p class="user--exercise-match-description">{item}</p>
          <input
            type="text"
            class="user--class-exercise-match-input"
            placeholder="Ответ"
            data-block-index={0}
            data-item-index={itemIndex}
            bind:value={$selectedTableList[itemIndex]} 
            on:input={(event) => handleInput(itemIndex, event.target.value)}
            style="border: 3px solid {$answerFeedback[itemIndex] === 'correct' ? 'rgba(135, 194, 177, 1)' : $answerFeedback[itemIndex] === 'incorrect' ? 'rgba(255, 147, 147, 1)' : 'transparent'}"
          />
        </div>
      {/each}
    </div>

</div>
<div class="user--class-exercise-buttons-wrap">
  {#if $submitted}
  {#if location == "classroom"}
    <div class="submitted-icon">{@html iconSubmitted}</div>
  {:else if (location == "homework" || location == "revision")}
  <button class="user--class-generic-button-3"  on:click={(event) => {
    event.currentTarget.classList.add('inactive');
    event.currentTarget.textContent = 'Загружаю...';
    proceedNext();
  }}
  >Продолжить</button
>
  {/if}
    {:else}
{#if $userStore.student_status != 0}
<button on:click={submitAnswers} class="user--class-generic-button-3 { $allFieldsFilled ? '' : 'user--button-clicked' }">Ответить</button>
{/if}
{/if}

{#if slideData.hint && slideData.hint.some((hint) => hint !== "")}
<button class="user--class-generic-button-3" on:click={expandHint}
  >Подсказка</button
>
{/if}
</div>
</div>

  
<style>
  .correct {
    border-color: rgba(135, 194, 177, 1);
  }
  .incorrect {
    border-color: rgba(255, 147, 147, 1);
  }
  .not-defined {
    border-color: rgba(192, 192, 192, 1);
  }
  .coloured {
       border: 2px solid #0C5CA4;
    }
  .centered-div {
    width: 60px;
    height: 60px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  .submitted-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  .user--exercise-item-wrapper {
    transition: border-color 0.3s;
  }
  .coloured {
    border: 2px solid #0C5CA4;
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
</style>
