<script>
  import { userStore, API_BASE_URL } from "../store.js";
  import { blur } from "svelte/transition";
  import { writable, derived, get } from "svelte/store";
  import { onMount } from "svelte";
  import axios from "axios";
  import Pagination from "./Pagination.svelte";
  import { translate } from "../translation";

  let loading = writable(true);
  let data = [];
  const goals = writable([]);
  let currentPage = writable(1);
  const itemsPerPage = 6;

  const fetchUserGoals = async () => {
    try {
      const response = await axios.post(`${$API_BASE_URL}/fetchUserGoals`, {
        token: get(userStore).token,
      });
      console.log("BACKEND RESPONSE: ", response.data);
      if (response.data.status === "success") {
        data = response.data.goals;
        initializeGoals();
      } else {
        loading.set(false);
      }
    } catch (error) {
      loading.set(false);
    }
  };

  const initializeGoals = () => {
    let delay = 0;
    if (data.length === 0) {
      goals.set([
        {
          empty: true,
          message: translate(
            "В этом разделе пока пусто",
            "There is nothing yet",
            "这里还没什么内容"
          ),
        },
      ]);
    } else {
      const mappedGoals = data.map((block, index) => ({
        ...block,
        delay: `${delay + index * 100}ms`,
      }));
      goals.set(mappedGoals);
    }
    loading.set(false);
  };

  const paginatedGoals = derived(
    [goals, currentPage],
    ([$goals, $currentPage]) => {
      const start = ($currentPage - 1) * itemsPerPage;
      const end = $currentPage * itemsPerPage;
      return $goals.slice(start, end);
    }
  );

  const handlePageChange = (page) => {
    currentPage.set(page);
  };
  $: completedGoals = get(goals).filter((goal) => goal.isCompleted);

  onMount(() => {
    fetchUserGoals();
    console.log("goals??");
  });
</script>

<div class="user--block-expanded-wrapper" in:blur={{ duration: 200 }}>
  {#if $loading}
    <div class="loading-huge jello-horizontal"></div>
  {:else}
    <div class="user--popup-goals-wrap">
      {#if $goals.length === 0 || $goals[0]?.empty}
        <div class="user--popup-indicator-empty-block">
          {$goals[0]?.message}
        </div>
      {:else}
        <div class="user--popup-goals-upcoming">
          <h1>{translate("Активные", "In progress", "进行中")}</h1>
          {#each $paginatedGoals as goal (goal.goal_id)}
            {#if !goal.isCompleted}
              <div
                class="user--popup-goals-block slide-in-blurred-bottom"
                style="animation-delay: {goal.delay}"
              >
                <div class="user--popup-goals-block-p1">
                  <div class="user--popup-story-block-circle"></div>
                  <h2>{goal.name}</h2>
                </div>
                <div class="user--popup-goals-block-p2">
                  <span>{goal.AchievementDesc}</span>
                  <div class="user--popup-story-block-circle">
                    <svg viewBox="0 0 448 512">
                      <path
                        d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            {/if}
          {/each}
        </div>
        {#if completedGoals.length > 0}
          <div class="user--popup-goals-finished">
            <h1>{translate("Завершенные", "Completed", "已完成")}</h1>
            {#each $paginatedGoals as goal (goal.goal_id)}
              {#if goal.isCompleted}
                <div
                  class="user--popup-goals-block slide-in-blurred-bottom"
                  style="animation-delay: {goal.delay}"
                >
                  <div
                    class="user--popup-goals-block-p1"
                    style="border: 2.5px solid #87C2B1; fill: #87C2B1;"
                  >
                    <div class="user--popup-story-block-circle">
                      <svg viewBox="0 0 512 512">
                        <path
                          d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                        />
                      </svg>
                    </div>
                    <h2>{goal.name}</h2>
                  </div>
                  <div class="user--popup-goals-block-p2">
                    <span>{goal.AchievementDesc}</span>
                    <div
                      class="user--popup-story-block-circle"
                      style="border: 2.5px solid #87C2B1; fill: #87C2B1;"
                    >
                      <svg viewBox="0 0 448 512">
                        <path
                          d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              {/if}
            {/each}
          </div>
        {/if}
        <Pagination
          totalItems={$goals.length}
          {itemsPerPage}
          {currentPage}
          onPageChange={handlePageChange}
        />
      {/if}
    </div>
  {/if}
</div>

<style>
  .centered-div {
    width: 60px;
    height: 60px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
</style>
