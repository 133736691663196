<script>
  import { fly, blur } from "svelte/transition";
  import { onMount, onDestroy } from "svelte";
  import { get } from "svelte/store";
  import SlideComponent from "./constructor/SlideComponent.svelte";
  import { translate } from "../translation.js";
  import axios from "axios";
  import { userStore, trackContentUpload, API_BASE_URL } from "../store.js";

  import PushNotification from "./PushNotification.svelte";
  let notifications = [];
  function addNotification(header, message, type = "info", duration = 3000) {
    notifications = [...notifications, { header, message, type, duration }];
    setTimeout(() => {
      notifications = notifications.slice(1);
    }, duration);
  }

  const handleNotification = (event) => {
    const { header, message, type, duration } = event.detail;
    addNotification(header, message, type, duration);
  };


  let wrapper;
  let role;
  let data;
  let componentId = 1; // compID for tracking
  let orderedComponents = []; // dynamic order
  let uploading = false;
  let slideNameInput;
  let slideName;


  const plusIcon = `
<svg style="width: 100%; height: 100%;" viewBox="0 0 32 32" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-466.000000, -1089.000000)" fill="#ffffff">
            <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" sketch:type="MSShapeGroup">

</path>
        </g>
    </g>
</svg>`;


  const minusIcon = `<svg style="width: 100%; height: 100%;" viewBox="0 0 32 32" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-518.000000, -1089.000000)" fill="#ffffff">
            <path d="M540,1106 L528,1106 C527.447,1106 527,1105.55 527,1105 C527,1104.45 527.447,1104 528,1104 L540,1104 C540.553,1104 541,1104.45 541,1105 C541,1105.55 540.553,1106 540,1106 L540,1106 Z M534,1089 C525.163,1089 518,1096.16 518,1105 C518,1113.84 525.163,1121 534,1121 C542.837,1121 550,1113.84 550,1105 C550,1096.16 542.837,1089 534,1089 L534,1089 Z" id="minus-circle" sketch:type="MSShapeGroup">

</path>
        </g>
    </g>
</svg>`;

  const uploadIcon = `<svg style="width: 100%; height: 100%" viewBox="0 0 24 24" fill="none">
<path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#ffffff"/>
</svg>`;

  export let locationType;
  export let constructorObject;
  export let contents;
  export let returnToSelect;  

  const returnBtn = `<svg style="width: 100%; height: 100%" viewBox="0 0 48 48" fill="none" >
<path d="M12.9998 8L6 14L12.9998 21" stroke="#85ADD1" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 14H28.9938C35.8768 14 41.7221 19.6204 41.9904 26.5C42.2739 33.7696 36.2671 40 28.9938 40H11.9984" stroke="#85ADD1" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;


  // constructorObject structure:

  // [classNumber, courseId, section, slideIndex]

  export let slideID;
  // -- will be passed from MaterialsPro -- console.log('createSlide mounted. ', contents);

  // Add the rest block

  const componentButtons = [
    "header",
    "lottie",
    "questionsBlock",
    "contentImageBlock",
    "delimeter",
    "dataExample",
    "blockDialogue",
    "imageBlock",
    "tableBlock",
    "itemsGrid",
    "audioBlock",
    "pdfSlide",
    "intBlackboard",
    "hanzi"
  ];
  const componentButtonsDescriptor = [
    "Блок с текстом",
    "Анимация",
    "Добавить пронумерованный список",
    "Блок с текстом и изображением",
    "Разделитель",
    "Блок с примерами",
    "Добавить диалог",
    "Добавить изображение",
    "Добавить таблицу",
    "Изображения и описания к ним",
    "Добавить аудио",
    "PDF-слайд",
    "Интерактивная доска",
    "Пропись иероглифов"
  ];

  const addComponentToSlide = (component) => {
    try {
      const id = componentId++;
      let newComponent;
      if (component && typeof component === "object") {
        newComponent = component;
        console.log("the check is passed");
      } else {
        newComponent = { id, role: component };

        if (component === "header") {
          newComponent = {
            ...newComponent,
            textContent: "",
            customBackground: "",
          };
        } else if (component === "lottie") {
          newComponent = { ...newComponent, lottieLink: null };
        } else if (component === "questionsBlock") {
          newComponent = {
            ...newComponent,
            questions: [],
            customBackground: "",
          };
        } else if (component === "delimeter") {
          newComponent = { ...newComponent };
        } else if (component === "dataExample") {
          newComponent = {
            ...newComponent,
            textContent: [],
            example: [],
            pinyin: [],
            customBackground: "",
            additionalField: "",
          };
        } else if (component === "blockDialogue") {
          newComponent = {
            ...newComponent,
            textContent: [],
            dialogue: [],
            pinyin: [],
            imageArray: [],
            customBackground: "",
            additionalField: "",
          };
        } else if (component === "imageBlock") {
          newComponent = {
            ...newComponent,
            textContent: "",
            customBackground: "",
            imgSrc: null,
          };
        } else if (component === "audioBlock") {
          newComponent = { ...newComponent, audioSrc: null };
        } else if (component === "pdfSlide") {
          newComponent = { ...newComponent, pdfLink: null };
        } else if (component === "hanzi") {
          newComponent = { 
            ...newComponent,
            chars: [],
            isTest: "false",
         };
        } else if (component === "contentComplexBlock") {
          newComponent = {
            id,
            role: "contentComplexBlock",
            customBackground: "",
            order: [],
            flagObject: {
              hasText: false,
              hasVideo: false,
              hasAudio: false,
              hasImage: false,
              hasScribble: false,
              hasTable: false,
            },
            elements: [],
          };
        }
      }

      orderedComponents = [...orderedComponents, newComponent];

      addNotification(
        "Компонент добавлен",
        ``,
        "info",
        4000
      );
      setTimeout(() => {
            const newElement = document.querySelector(
                `.slide-preview > :nth-child(${orderedComponents.length})`
            );
            if (newElement) {
                newElement.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }, 100);
    } catch (error) {
      alert(
        "Произошла ошибка, пожалуйста, сообщите администрации! Код ошибки: addComponentToSlide -",
        error
      );
    }
  };

  const handleUpdate = (event) => {
    try {
      const { comp } = event.detail;
      orderedComponents = orderedComponents.map((ccomp) =>
        ccomp.id === comp.id ? { ...ccomp, ...comp } : ccomp
      );
    } catch (error) {
      console.error(
        "Произошла ошибка, пожалуйста, сообщите администрации! Код ошибки: handleUpdate:",
        error
      );
    }
  };

  const handleMove = (event) => {
    try {
      const { direction, comp } = event.detail;
      let index = orderedComponents.findIndex((ccomp) => ccomp.id === comp.id);
      if (direction === "up" && index > 0) {
        [orderedComponents[index - 1], orderedComponents[index]] = [
          orderedComponents[index],
          orderedComponents[index - 1],
        ];
      } else if (direction === "down" && index < orderedComponents.length - 1) {
        [orderedComponents[index + 1], orderedComponents[index]] = [
          orderedComponents[index],
          orderedComponents[index + 1],
        ];
      }
    } catch (error) {
      console.error(
        "Произошла ошибка, пожалуйста, сообщите администрации! Код ошибки: handleMove:",
        error
      );
    }
  };

  const handleDelete = (event) => {
    try {
      const { comp } = event.detail;
      orderedComponents = orderedComponents.filter(
        (ccomp) => ccomp.id !== comp.id
      );
    } catch (error) {
      console.error(
        "Произошла ошибка, пожалуйста, сообщите администрации! Код ошибки: handleDelete:",
        error
      );
    }
  };

  const slideCreator = (wrapperElement) => {
    try {
      wrapper = wrapperElement;
      // console.log('Initializing slide creator with wrapper:', wrapper);
    } catch (error) {
      console.error(
        "Произошла ошибка, пожалуйста, сообщите администрации! Код ошибки: slideCreator:",
        error
      );
    }
  };

  function initializeExistingData(data) {
    try {
      if (data) {
        // Initialize slide-specific properties
        if (data.slideName) {
          slideName = data.slideName;
        }
        if (data.classNumber) {
          constructorObject.classNumber = data.classNumber;
        }
        if (data.slideNumber) {
          constructorObject.slideIndex = data.slideNumber;
        }
        if (data.slideSection) {
          constructorObject.section = data.slideSection;
        }
        if (data.slideCourse) {
          constructorObject.courseId = data.slideCourse;
        }

        // Initialize contents
        if (data.contents && Array.isArray(data.contents)) {
          data.contents.forEach((component) => {
            addComponentToSlide(component);
          });
        }
      }
    } catch (error) {
      console.error(
        "Произошла ошибка, пожалуйста, сообщите администрации! Код ошибки: initializeExistingData:",
        error
      );
    }
  }

  // slide uploader + routing

  function submitSlide() {
    // console.log('COMPOSING MAIN OBJECT. ORDER COMPONENTS:', orderedComponents)
    uploading = true;
    const slideData = {
      token: $userStore.token,
      classNumber: constructorObject.classNumber,
      slideNumber: constructorObject.slideIndex,
      slideSection: constructorObject.section,
      slideCourse: constructorObject.courseId,
      slideName: slideName || "",
      isPassed: "false",
      contents: orderedComponents,
    };

    console.log(slideData);

    let uploadAllowed = true; // for debug. Remove in production.
    if (uploadAllowed) {
      axios
        .post(`${$API_BASE_URL}/uploadSlide`, slideData)
        .then((response) => {
          console.log(response.data);
          if (response.data.result === "success") {
            // Handle successful upload
            //  console.log('Slide uploaded successfully.');
            trackContentUpload.update((value) => !value);
          } else {
            console.error("Failed to upload slide:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error uploading slide:", error);
        });
    } else {
      // console.log("the object to be uploaded is displayed above");
    }
  }

  onMount(() => {
    slideCreator(wrapper);

    if (
      contents != undefined &&
      Array.isArray(contents.contents) &&
      contents.contents.length > 0
    ) {
      initializeExistingData(contents);
    }
  });
</script>

<!-- 
<div class="inner-icon">{@html minusIcon}</div><span class="inner-text">Изображение</span> -->
{#each notifications as { header, message, type, duration }}
  <PushNotification {header} {message} {type} {duration} />
{/each}

<div class="constructor-wrapper" in:blur={{ duration: 500 }}>
  <div class="return-btn" on:click={() => {returnToSelect(null)}}>{@html returnBtn}</div>
  <div class="user--class-exercise-header">
    Наполните слайд элементами из панели инструментов
  </div>
  <input
    bind:this={slideNameInput}
    bind:value={slideName}
    class="user--class-exercise-header-slidename exercise-builder-header"
    placeholder="Название слайда"
  />
  <div bind:this={wrapper} class="slide-wrapper">
    <div class="slide-builder">
      <div class="button-block">
        {#each componentButtons as component, index}
          <button
            class="user--class-generic-button-3-tiny component-choice-btn"
            on:click={() => addComponentToSlide(component)}
            >{componentButtonsDescriptor[index]}</button
          >
        {/each}
      </div>
      <div class="slide-preview">
        {#each orderedComponents as component, index (component.id)}
          <SlideComponent
            comp={component}
            compIndex={componentId}
            role={component.role}
            editData={component}
            {index}
            totalCount={orderedComponents.length}
            on:update={handleUpdate}
            on:move={handleMove}
            on:delete={handleDelete}
          />
        {/each}
      </div>
    </div>
  </div>
  {#if uploading}
    <div class="loading-huge jello-horizontal"></div>
  {:else}
  <button class="user--class-generic-button-3 user--class-generic-button-3-h" on:click={submitSlide}><div class="inner-icon">{@html uploadIcon}</div><span class="inner-text">{(contents && contents != '' && contents.length >= 2) ? 'Обновить' : 'Загрузить'}</span></button>
  {/if}
</div>

<style>
  .constructor-wrapper {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .button-block {
    width: 90%;
    height: max-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0 auto;
    border-radius: 40px;
    border: 3px solid #0c5ca450;
    padding: 20px;
  }
  /* .component-choice-btn {
        margin-inline-end: 20px;
    } */
  .slide-builder {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;
    gap: 20px;
  }
  .slide-preview {
    width: 90%;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .component-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  .return-btn {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .inner-icon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0; 
    }
    .inner-text {
      overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; 
  display: inline-block;
  flex-grow: 1; 
    }
    @media (max-width: 750px) { 
      .button-block {
        width: 100%;
        border-radius: 20px;
      }
      .slide-preview {
        width: 100%;
      }
    }
</style>
