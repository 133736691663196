<script>
    import { writable, derived, get } from 'svelte/store';
    import { sendSocketEvent } from "../websocket.js";
    import {userStore, highlightAnswer} from '../store.js';
    import { onMount } from "svelte";

    const iconSubmitted = `<svg fill="#4CBB9A" style="width: 100%; height: 100%" viewBox="0 0 32 32"><path d="m16 0c8.836556 0 16 7.163444 16 16s-7.163444 16-16 16-16-7.163444-16-16 7.163444-16 16-16zm0 2c-7.7319865 0-14 6.2680135-14 14s6.2680135 14 14 14 14-6.2680135 14-14-6.2680135-14-14-14zm6.6208153 9.8786797c.3905243.3905242.3905243 1.0236892 0 1.4142135l-7.0710678 7.0710678c-.3626297.3626297-.9344751.3885319-1.3269928.0777064l-.0872208-.0777064-4.24264068-4.2426407c-.39052429-.3905242-.39052429-1.0236892 0-1.4142135.39052428-.3905243 1.02368928-.3905243 1.41421358 0l3.5348268 3.5348268 6.3646681-6.3632539c.3905243-.3905243 1.0236893-.3905243 1.4142136 0z"/></svg>`;
    // IMPORTANT: isAllCorrect IS NOT BEING UPDATED WITH A CORRECT VALUE

    export let slideData;
    export let exerciseID;
    export let location; // location can be 'revision', 'homework', or 'classroom'
    export let completedData;
  
    let hintExpanded = writable(false);

function expandHint() {
  hintExpanded.set(!$hintExpanded);
}

// Initialize sequenceAnswers and answerFeedback based on slideData structure
let sequenceAnswers = writable(
  slideData.answer.map(block =>
    Array.isArray(block)
      ? block.map(question => new Array(question.length).fill(''))
      : new Array(1).fill('')
  )
);

let answerFeedback = writable(
  slideData.answer.map(block =>
    Array.isArray(block)
      ? block.map(question => new Array(question.length).fill(''))
      : new Array(1).fill('')
  )
);

let submitted = writable(false);

// WebSocket event handler for dynamic highlighting
$: if ($highlightAnswer.id === exerciseID) {
 // console.log('[DEBUG] -- highlight answer', $highlightAnswer.answer, $highlightAnswer.blockIndex, $highlightAnswer.questionIndex, $highlightAnswer.inputIndex);
  const eventAnswer = $highlightAnswer.answer;
  const blockIndex = $highlightAnswer.blockIndex;
  const questionIndex = $highlightAnswer.questionIndex || 0;
  const inputIndex = $highlightAnswer.inputIndex || 0;

  // Update feedback based on the highlighted answer
  answerFeedback.update(fb => {
    fb[blockIndex][questionIndex][inputIndex] =
      eventAnswer === slideData.answer[blockIndex][questionIndex][inputIndex].toLocaleLowerCase('ru')
        ? 'correct'
        : 'incorrect';
    return fb;
  });

  // Update the user input with the highlighted answer
  handleInput(blockIndex, questionIndex, inputIndex, eventAnswer);
}

// Handle user input for each question in the sequence and send WebSocket event
function handleInput(blockIndex, questionIndex, inputIndex, value) {
  sequenceAnswers.update(answers => {
    if (Array.isArray(answers[blockIndex][questionIndex])) {
      answers[blockIndex][questionIndex][inputIndex] = value;
    } else {
      answers[blockIndex][inputIndex] = value;
    }

    // Recomposed event detail object
    const eventDetails = {
  actionName: {
    kind: "sequence", // This represents the type of the action
    blockIndex: blockIndex, // The block index in the sequence
    questionIndex: questionIndex, // The question index in the block
    inputIndex: inputIndex, // The specific input within the question
    answer: value, // The value entered by the student
    id: exerciseID, // The exercise ID
  },
  isAllCorrect: false, // This will be updated after feedback logic, set to false initially
  answers: get(sequenceAnswers).map(block =>
    Array.isArray(block)
      ? block.map(question => question.map(ans => ans ? ans.toLocaleLowerCase('ru') : ''))
      : block.map(ans => ans ? ans.toLocaleLowerCase('ru') : '')
  )
};
   // console.log('[DEBUG] -- SENDING THE SOCKET EVENT: HIGHLIGHTANSWER. Event details:', eventDetails);
    sendSocketEvent('studentAnswerDynamic', eventDetails);

    return answers;
  });
}

// Submit answers and send the socket event
function submitAnswers() {
  const correctAnswers = slideData.answer.map(block =>
    Array.isArray(block)
      ? block.map(question =>
          Array.isArray(question)
            ? question.map(ans => ans ? ans.toLocaleLowerCase('ru') : null)
            : [question ? question.toLocaleLowerCase('ru') : null] // Handle non-array question case
        )
      : [block ? block.toLocaleLowerCase('ru') : null] // Handle non-array block case
  );

  const userAnswers = get(sequenceAnswers).map(block =>
    Array.isArray(block)
      ? block.map(question =>
          Array.isArray(question)
            ? question.map(ans => ans ? ans.toLocaleLowerCase('ru') : null)
            : [question ? question.toLocaleLowerCase('ru') : null] // Handle non-array question case
        )
      : [block ? block.toLocaleLowerCase('ru') : null] // Handle non-array block case
  );

  const { totalQuestions, totalScore } = checkSequenceAnswers(correctAnswers, userAnswers);

  // Mark the sequence as submitted
  submitted.set(true);

  // Determine the correct endpoint
  let endpoint;
  if (location === "revision") {
    endpoint = "exerciseCompletedRevision";
  } else if (location === "homework") {
    endpoint = "exerciseCompletedRevision";
  } else if (location === "classroom") {
    endpoint = "exerciseCompleted";
  }

  const eventDetails = {
      actionName: {
        exerciseID: exerciseID,
        isCorrect: isAllCorrect ? 'true' : 'false', // Convert boolean to string
        isCreative: false,
        partialScore: totalScore
      },
      answers: userAnswers, // User's answers
      isAllCorrect: isAllCorrect ? 'true' : 'false' // Whether all answers are correct
    };

  // Send the event with the necessary data
  sendSocketEvent(endpoint, eventDetails);

  
}


// Derived store to check if all questions are answered
const allAnswered = derived(sequenceAnswers, $sequenceAnswers => {
  return $sequenceAnswers.flatMap(block => Array.isArray(block) ? block.flat() : block)
    .every(answer => answer === null || (typeof answer === 'string' && answer.trim() !== ''));
});

// Populate answers and feedback from completed data if available
onMount(() => {
  if (completedData && completedData.studentAnswer != null) {
    try {
      const parsedStudentAnswer = JSON.parse(completedData.studentAnswer);
      if (Array.isArray(parsedStudentAnswer)) {
        parsedStudentAnswer.forEach((answer, blockIndex) => {
          answer.forEach((subAnswer, questionIndex) => {
            handleInput(blockIndex, questionIndex, 0, subAnswer);
          });
        });

        // Update feedback based on pre-filled answers
        answerFeedback.update(fb => {
          const ua = get(sequenceAnswers);
          for (let blockIndex = 0; blockIndex < slideData.answer.length; blockIndex++) {
            for (let questionIndex = 0; questionIndex < slideData.answer[blockIndex].length; questionIndex++) {
              if (ua[blockIndex][questionIndex] && ua[blockIndex][questionIndex].toLocaleLowerCase('ru') === slideData.answer[blockIndex][questionIndex].toLocaleLowerCase('ru')) {
                fb[blockIndex][questionIndex] = 'correct';
              } else {
                fb[blockIndex][questionIndex] = 'incorrect';
              }
            }
          }
          return fb;
        });

        submitted.set(true);
      }
    } catch (error) {
      console.error("Error parsing completedData studentAnswer:", error);
    }
  }
});

// Check sequence answers and calculate the score
function checkSequenceAnswers(correctAnswers, userAnswers) {
  let totalQuestions = 0;
  let totalScore = 0;

  for (let blockIndex = 0; blockIndex < correctAnswers.length; blockIndex++) {
    const correctAnswerBlock = correctAnswers[blockIndex];
    const userAnswerBlock = userAnswers[blockIndex];
    if (Array.isArray(correctAnswerBlock)) {
      for (let questionIndex = 0; questionIndex < correctAnswerBlock.length; questionIndex++) {
        const correctQuestion = correctAnswerBlock[questionIndex];
        const userQuestion = userAnswerBlock[questionIndex];
        for (let answerIndex = 0; answerIndex < correctQuestion.length; answerIndex++) {
          const correctAnswer = correctQuestion[answerIndex] ? correctQuestion[answerIndex].trim().toLowerCase() : '';
          const userAnswer = userQuestion[answerIndex] ? userQuestion[answerIndex].trim().toLowerCase() : '';

          if (correctAnswer) {
            totalQuestions++;
            if (correctAnswer === userAnswer) {
              totalScore += 100;
              answerFeedback.update(fb => {
                fb[blockIndex][questionIndex][answerIndex] = 'correct';
                return fb;
              });
            } else {
              answerFeedback.update(fb => {
                fb[blockIndex][questionIndex][answerIndex] = 'incorrect';
                return fb;
              });
            }
          }
        }
      }
    } else {
      const correctAnswer = correctAnswerBlock ? correctAnswerBlock.trim().toLowerCase() : '';
      const userAnswer = userAnswerBlock[blockIndex] ? userAnswerBlock[blockIndex].trim().toLowerCase() : '';

      if (correctAnswer) {
        totalQuestions++;
        if (correctAnswer === userAnswer) {
          totalScore += 100;
          answerFeedback.update(fb => {
            fb[blockIndex] = 'correct';
            return fb;
          });
        } else {
          answerFeedback.update(fb => {
            fb[blockIndex] = 'incorrect';
            return fb;
          });
        }
      }
    }
  }

  // Return the total number of questions and the total score
  return { totalQuestions, totalScore };
}

function replacePlaceholder(text) {
  return text.replace(/\[Пропуск\]/g, '--');
}

function proceedNext() {
  sendSocketEvent('proceedSelf', '');
}
  </script>
  
  <div class="user--class-exercise-header">
    {@html slideData.textContent}
  </div>
  
  {#if slideData.displayTips && slideData.displayTips === "true"}
    <div class="user--exercise-multiple_sequence_sdisplay">
      {#each Array.from(new Set(slideData.answer.flatMap(block => Array.isArray(block) ? block.flat() : block))) as answer, index}
        <span class="user--exercise-multiple_sequence_hint {$answerFeedback[0][0][index] === 'correct' ? 'correct' : 'incorrect'}" data-repetitions={slideData.answer.flatMap(block => Array.isArray(block) ? block.flat() : block).filter(a => a === answer).length}>
          {answer}
        </span>
      {/each}
    </div>
  {/if}
  
  <div class="sequence-exercise">
    <div class="exercise-answer-block-multiple">
      {#each slideData.answer as block, blockIndex}
        <div class="user--exercise-block-wrapper">
          <div class="user--class-exercise-header exercise-builder-header exercise-header-underlined">
            {@html replacePlaceholder(slideData.descriptor[blockIndex])}
          </div>
          {#if slideData.hint[blockIndex] && $hintExpanded}
          <div class="user--exercise-hint-mini">
            {slideData.hint[blockIndex]}
          </div>
        {/if}
          <div class="flex-no-gap">
          {#each block as question, questionIndex}
            <div class="user--exercise-sequence-block-wrap">
              <div class="user--class-exercise-answer-block">
                {#each question.length ? question : new Array(1) as _, answerIndex}
                  <input
                    type="text"
                    class="user--class-exercise-input"
                    placeholder="Ответ"
                    bind:value={$sequenceAnswers[blockIndex][questionIndex][answerIndex]} 
                    on:input={(e) => handleInput(blockIndex, questionIndex, answerIndex, e.target.value)}
                    style="border: 3px solid {$answerFeedback[blockIndex][questionIndex][answerIndex] === 'correct' ? 'rgba(135, 194, 177, 1)' : $answerFeedback[blockIndex][questionIndex][answerIndex] === 'incorrect' ? 'rgba(255, 147, 147, 1)' : '#0C5CA450'}"
                  />
                {/each}
              </div>
            </div>
          {/each}
          </div>
        </div>
      {/each}
    </div>
  
    <div class="user--class-exercise-buttons-wrap">
      {#if $submitted}
      {#if location == "classroom"}
        <div class="submitted-icon">{@html iconSubmitted}</div>
      {:else if (location == "homework" || location == "revision")}
      <button class="user--class-generic-button-3"  on:click={(event) => {
        event.currentTarget.classList.add('inactive');
        event.currentTarget.textContent = 'Загружаю...';
        proceedNext();
      }}
      >Продолжить</button
    >
      {/if}
        {:else}
    {#if $userStore.student_status != 0}
        <button on:click={submitAnswers} class="user--class-generic-button-3 { $allAnswered ? '' : 'user--button-clicked' }">Ответить</button>
      {/if}  
      {/if}
  
      {#if slideData.hint && slideData.hint.some((hint) => hint !== "")}
      <button class="user--class-generic-button-3" on:click={expandHint}
        >Подсказка</button
      >
    {/if}
    </div>
  </div>
  



<style>
    .correct {
        border-color: rgba(135, 194, 177, 1);
    }
    .incorrect {
        border-color: rgba(255, 147, 147, 1);
    }
    .not-defined {
        border-color: rgba(192, 192, 192, 1);
    }
    .centered-div {
        width: 60px;
        height: 60px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .loading-huge {
        width: 60px;
        height: 60px;
        background: linear-gradient(45deg, #020344, #28b8d5);
        border-radius: 50%;
        position: relative;
    }
    .jello-horizontal {
        -webkit-animation: jello-horizontal 0.9s infinite both;
        animation: jello-horizontal 0.9s infinite both;
    }
    .submitted-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    }
    @-webkit-keyframes jello-horizontal {
        0% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
        30% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
        }
        40% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
        }
        50% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
        }
        65% {
            -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
        }
        75% {
            -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
        }
        100% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }
    @keyframes jello-horizontal {
        0% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
        30% {
            -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
        }
        40% {
            -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
        }
        50% {
            -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
        }
        65% {
            -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
        }
        75% {
            -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
        }
        100% {
            -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
        }
    }
    .user--exercise-item-wrapper {
        transition: border-color 0.3s;
    }
    .coloured {
       border: 2px solid #0C5CA4;
    }
    .flex-no-gap {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
</style>
