<script>
    import {fly, blur} from 'svelte/transition';
    import { onMount } from 'svelte';
    import Auth from './Auth.svelte';
    import Student from './Student.svelte';
    import Teacher from './Teacher.svelte';
    import Class from './Class.svelte';
    import { push } from 'svelte-spa-router';
    import { userStore, isAuthorized, currentClass, isCheckLoaded } from '../store.js';
    import Cookies from 'js-cookie';
  import axios from 'axios';
  import { initConnect, sendSocketEvent } from '../websocket';
  // let isCheckLoaded = false;
  function navigateTo(section) {
    push(`/${section}`);
  } 

</script>
<style>

</style>
{#if !$isAuthorized}
<Auth />
{:else}


{#if isCheckLoaded}
{#if $currentClass}
<Class />
{:else if $userStore['student_status'] == 1}
<Student />
{:else if $userStore['student_status'] == 0}
<Teacher />
{/if}
{/if}
{/if}