<script>
  import { fly, blur } from "svelte/transition";
  import { onMount } from "svelte";
  import { isAuthorized, userStore, trackContentUpload, API_BASE_URL } from "../store.js";
  import { translate } from "../translation";
  import axios from "axios";
  import Cookies from "js-cookie";
  import { writable } from "svelte/store";
  import PushNotification from "./PushNotification.svelte";
  import Slide from "./Slide.svelte";
  import Exercise from "./Exercise.svelte";
  import SlideInfo from "./SlideInfo.svelte";
  let notifications = [];
  function addNotification(header, message, type = "info", duration = 3000) {
    notifications = [...notifications, { header, message, type, duration }];
    setTimeout(() => {
      notifications = notifications.slice(1);
    }, duration);
  }

  let fetchingCoursesWithHomework = true;
  let homeworkData = writable([]);
  let fetchedCourses = writable([]);
  let selectedCourseName = "";
  let selectedCourseId = null;
  let allLoaded = writable(false);
  let currentPoint = writable(null);
  let selectedClassNumber = null;
  let particularBlockExpanded = writable(false);
  let particularBlockLoading = writable(true);
  let selectedBlockStudentsList = writable([]);
  let hwParticularData = writable([]);
  let hwParticularLoading = writable(true);
  let hwParticularOpened = writable(false);
  


  let evaluationData = writable([]);

   // Initialize evaluationData based on hwParticularData only if it hasn't been initialized yet
   $: if ($hwParticularData.length > 0 && $evaluationData.length === 0) {
    evaluationData.set($hwParticularData.map(hw => hw.student_answer.map(() => ({
      score: null,
      comment: '',
      loading: false,
      loaded: false
    }))));
    console.log('Initial evaluation data:', $evaluationData);
  }

  $: allChecked = $evaluationData.every(hw => hw.every(item => item.loaded === true));
  
   function setPoints(hwIndex, answerIndex, points) {
    evaluationData.update(data => {
       data[hwIndex][answerIndex].score = points;
       return data;
    });
   }

  function setComment(hwIndex, answerIndex, comment) {
    evaluationData.update(data => {
      data[hwIndex][answerIndex].comment = comment;
      return data;
    });
  }

  async function submitEvaluation(hwIndex, answerIndex, homeworkId) {
    evaluationData.update(data => {
      data[hwIndex][answerIndex].loading = true;
      return data;
    });
    const evaluation = $evaluationData[hwIndex][answerIndex];
    
    // Prepare the payload
    const payload = {
      token: $userStore.token, // Assuming you have user authentication handled
      homework_id: homeworkId,
      mark: evaluation.score,
      correctedAnswers: evaluation.comment ? [evaluation.comment] : []
    };

    try {
      const response = await axios.post(`${$API_BASE_URL}/updateHomework`, payload);
      if (response.data.status === 'success') {
        evaluationData.update(data => {
      data[hwIndex][answerIndex].loaded = true;
      return data;
    });
    addNotification("Готово", "Задание проверено", "info", 3000);
      } else {
        console.error('Error updating homework:', response.data.message);
      }
    } catch (error) {
      console.error('Error submitting evaluation:', error);
    }
  }
  function fetchHomework() {
    const token = $userStore.token;

    axios
      .post(`${$API_BASE_URL}/fetchCourseList`, {
        token: token,
      })
      .then((response) => {
        if (response.data.status === "success") {
          fetchingCoursesWithHomework = false;
         // console.log("Homework fetched:", response.data);

          homeworkData.set(response.data.courses); // Store the received homework data
          // set the fetched courses
          console.log(
            "the homework data has been sucessfully set up. Verifying:",
            $homeworkData
          );

          fetchedCourses.set(response.data.courses);
        } else {
          fetchingCoursesWithHomework = false;
          homeworkData.set('');
        }
      })
      .catch((error) => {
        addNotification(
            "Ошибка",
            "При загрузке заданий произошла ошибка, попробуйте ещё раз",
            "error",
            4000
          );
      });
  }

  // function setPoints(points) {
  //   currentPoint.set(points);
  //   console.log('current point updated. New:', $currentPoint);
  // }

  function expandUserHw(studentId) {
    hwParticularOpened.set(true);
    const payload = {
      token: $userStore.token,
      student_id: studentId,
      course_id: selectedCourseId,
      class: parseInt(selectedClassNumber),
    }; 


     // Make an Axios POST request
     axios.post(`${$API_BASE_URL}/fetchStudentHomework`, payload)
        .then(response => {
            if (response.data.status === 'success') {
                hwParticularData.set(response.data.homework);
                
               
                hwParticularData.set(
                    response.data.homework.map(item => ({
                        ...item, 
                        exercise_text: JSON.parse(item.exercise_text), 
                        student_answer: JSON.parse(item.student_answer)
                    }))
                );

                console.log($hwParticularData);

                hwParticularLoading.set(false);
            } else {
                console.error('Failed to fetch homework:', response.data.message);
                // Optionally, handle the error in the UI
            }
        })
        .catch(error => {
            console.error('Error fetching homework:', error);
            // Optionally, handle the error in the UI
        });
         
  }
  function expandClassHw(courseId, classNumber, courseName) {
    // compose payload with the received paramteres
    const payload = {
      token: $userStore.token,
      course_id: courseId,
      class: parseInt(classNumber),
    };

    selectedCourseName = courseName;
    selectedClassNumber = classNumber;
    selectedCourseId = courseId;

    console.log(
      "[DEBUG] inside the expandClassHw function. Checking the payload:",
      payload
    );
    particularBlockExpanded.set(true);
    // collect the s into array and save their credentials for further use

    axios
      .post(`${$API_BASE_URL}/fetchStudentHomeworkList`, payload)
      .then((response) => {
        if (response.data.status === "success") {
         // console.log("Homework list:", response.data.students);
          selectedBlockStudentsList.set(response.data.students);
          
          particularBlockLoading.set(false);
        } else {
          console.error(
            "Failed to fetch homework list:",
            response.data.message
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching homework list:", error);
      });
  }

  // fetching hw when the comp mounted
  onMount(() => {
    fetchHomework();
  });
</script>


{#each notifications as { header, message, type, duration }}
  <PushNotification {header} {message} {type} {duration} />
{/each}

<div class="user--block-expanded-wrapper" in:blur={{ duration: 500 }}>
  {#if fetchingCoursesWithHomework}
    <div class="loading-huge jello-horizontal"></div>
  {:else if ($homeworkData.length > 0) && (!$particularBlockExpanded)}
    {#each $homeworkData as course (course.course_id)}
      <div class="course-block">
        <h2>{course.course_name}</h2>
        <div style="class-selector">
          {#if course.class_arr && course.class_arr.length > 0}
            {#each course.class_arr as classItem}
              <div
                class="class-unchecked-count-block"
                on:click={() => {
                  expandClassHw(course.course_id, classItem.class, course.course_name);
                }}
              >
                <span class="count-block-inner-style"
                  >Занятие {classItem.class}</span
                ><span class="count-block-inner-style">|</span><span
                  class="count-block-inner-style"
                  >Непроверенных: {classItem.unchecked_homework_count}</span
                >
              </div>
            {/each}
          {:else}
            <p>Нет данных о классе.</p>
          {/if}
        </div>
      </div>
    {/each}
    {:else if ($particularBlockExpanded && !$hwParticularOpened) }
      
    {#if $particularBlockLoading}
    <div class="loading-huge jello-horizontal"></div>
    {:else}


    <div class="course-block">
      <h2>{selectedCourseName}</h2>
      <h3>Занятие {selectedClassNumber}</h3>
      <div style="class-selector">
        {#if $selectedBlockStudentsList && $selectedBlockStudentsList.length > 0}
          {#each $selectedBlockStudentsList as student}
            <div
              class="class-unchecked-count-block"
              on:click={() => {
                expandUserHw(student.student_id);
              }}
            >
              <span class="count-block-inner-style"
                >{student.student_name}</span>
            </div>
          {/each}
          {/if}
          </div>
        </div>
            {/if}
            


  {:else if $hwParticularOpened && $particularBlockExpanded}          
  {#if $hwParticularLoading}
  <div class="loading-huge jello-horizontal"></div>
  {:else}
  {#if $hwParticularData.length > 0}
  {#if $allLoaded}
  <span class="no-hw-found-desc"
  >Вы проверили все задания этого учащегося</span
> {:else}
  {#each $hwParticularData as hw, i}
    {#each hw.student_answer as answer_block, index}
    {#if !$evaluationData[i][index].loaded}
      <div class="hw-interface-ex">
        <div class="hw-interface-ex__header">
          <h2 class="hw-interface-ex__header__title">{hw.exercise_text.contents[index].textContent}</h2>
          <span class="hw-interface-ex__ans_desc"></span>
          <span class="hw-interface-ex__ans">{answer_block}</span>
          <div class="eval-block">
            <div 
              class="class-unchecked-count-block {($evaluationData[i][index].score === 100) ? 'mark-active' : ''}"  
              on:click={() => setPoints(i, index, 100)}
            >
              Отл.
            </div>
            <div 
              class="class-unchecked-count-block {($evaluationData[i][index].score === 80) ? 'mark-active' : ''}" 
              on:click={() => setPoints(i, index, 80)}
            >
              Хор.
            </div>
            <div 
              class="class-unchecked-count-block {($evaluationData[i][index].score === 55) ? 'mark-active' : ''}" 
              on:click={() => setPoints(i, index, 55)}
            >
              Удв.
            </div>
            <div 
              class="class-unchecked-count-block {($evaluationData[i][index].score === 0) ? 'mark-active' : ''}" 
              on:click={() => setPoints(i, index, 0)}
            >
              Неудв.
            </div>
          </div>
          <textarea 
            rows="6" 
            class="eval-input" 
            placeholder="Комментарий или исправление" 
            bind:value={$evaluationData[i][index].comment} 
            on:input={(e) => setComment(i, index, e.target.value)}
          ></textarea>
          {#if $evaluationData[i][index].loading}
          <div class="loading-huge jello-horizontal"></div>
          {:else}
          <div 
            class="user--class-generic-button-3-tiny" 
            on:click={() => submitEvaluation(i, index, hw.homework_id)}
          >
            Готово
          </div>
          {/if}
        </div>
      </div>
      {/if}
      
    {/each}
  {/each}
  {/if}
{/if}
  {/if}
  {:else}
    <span class="no-hw-found-desc"
      >Непроверенных домашних заданий Ваших учащихся не найдено</span
    >
  {/if}
</div>

<style>
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  .no-hw-found-desc {
    font-size: 23px;
    font-weight: 350;
    color: #5c5c5c;
  }
  .course-block {
    width: 100%;
    height: max-content;
    padding: 20px;
    border-radius: 40px;
    border: 3px solid #0c5ca450;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .class-selector {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 90%;
    margin: 0 auto;
    padding: 30px;
    border: 2px solid #d9d9d9;
    border-radius: 40px;
  }
  .course-block h2 {
    font-size: 35px;
    font-weight: 900;
    color: #5c5c5c;
  }
  .course-block h3 {
    font-size: 30px;
    font-weight: 350;
    color: #5c5c5c;
  }
  .class-unchecked-count-block {
    font-size: 23px;
    font-weight: 350;
    color: #ffffff;
    background-color: #0c5ca460;
    border-radius: 50px;
    padding-block: 15px;
    cursor: pointer;
    padding-inline: 30px;
    display: flex;
    gap: 20px;
    justify-content: space-evenly;
    align-items: center;
    transition: all 0.3s linear;
  }
  .class-unchecked-count-block:hover {
    background-color: #0c5ca4;
  }
  .mark-active {
    background-color: #0c5ca4 !important;
    pointer-events: none !important;
  }
  .count-block-inner-style {
    font-size: 20px;
    font-weight: 500;
    /* color: #ffffff; */
    font-family: inherit;
  }
  .eval-block {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: max-content;
    padding: 20px;
  }
  .hw-interface-ex {
    width: 100%;
    height: max-content;
    padding: 20px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border: 3px solid #0c5ca450;
  }
  .hw-interface-ex__header, .eval-input {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 40px;
  }
  .eval-input {
    font-size: 23px;
    border: 2px solid #D3D3D3;
    width: 90%;
  }
  .hw-interface-ex__header__title {
    font-size: 25px;
    font-weight: 900;
    color: #5c5c5c;
  }
  .hw-interface-ex__ans-desc {
    font-size: 20px;
    font-weight: 350;
    color: #5c5c5c;
  }
  .hw-interface-ex__ans {
    font-size: 20px;
    font-weight: 350;
    color: #5c5c5c;
  }
  @media (max-width: 750px) {
    .course-block {
      font-size: 25px;
    }
    .class-unchecked-count-block {
      flex-wrap: wrap;
      font-size: 20px;
      border-radius: 20px;
    }
    .course-block {
      font-size: 23px;
      width: 100%;
      text-align: center;
    }
    .eval-block {
      width: 100%;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .hw-interface-ex {
      padding: 10px;
      border-radius: 20px;
    }
    .hw-interface-ex__header, .eval-input, .hw-interface-ex__ans {
      width: 100%;
      text-align: center;
      padding: 10px;
    }
    .eval-input {
      border-radius: 20px;
      padding: 10px; 
      font-size: 18px;
    }
    .no-hw-found-desc {
      font-size: 22px;
      width: 90%;
      margin: 0 auto;
    }

  }
</style>
