<script>
    import { createEventDispatcher } from 'svelte';

    export let input;
    const dispatch = createEventDispatcher();
    let selectedValue = input.details.default || '';
    let optionsVisible = false;

    const handleSelect = (value, descriptor) => {
        selectedValue = descriptor;
        dispatch('change', value);
        optionsVisible = false;
    };
</script>

<div class="user--generic-form-main-custom-select" on:click={() => (optionsVisible = !optionsVisible)}>
    <p>{input.details.descriptor}</p>
    <span class="user--generic-form-main-custom-selected-value">{selectedValue}</span>
    <span class="user--generic-form-main-custom-select-icon">&#9662;</span>
    {#if optionsVisible}
        <div class="user--generic-form-main-custom-options-wrapper">
            {#each input.details.options as option}
                <div class="user--generic-form-main-custom-option" on:click={() => handleSelect(option.value, option.descriptor)}>
                    {option.descriptor}
                </div>
            {/each}
        </div>
    {/if}
</div>


