<script>
    import { userStore, isAuthorized, API_BASE_URL } from '../store.js';
    import { fly, blur } from 'svelte/transition';
    import { push } from 'svelte-spa-router';
    import axios from 'axios';
    import Pagination from './Pagination.svelte';
    import { onMount } from 'svelte';
    import { translate } from '../translation';
    import { writable, get, derived } from 'svelte/store';

    let loading = writable(true);
    let data = [];

    const characters = writable([]);
    let currentPage = writable(1);
    const expandedCharacter = writable(null); 
    const itemsPerPage = 6;

    const fetchUserCharacters = async () => {
        try {
            const response = await axios.post(`${$API_BASE_URL}/fetchUserCharacters`, {
                token: get(userStore).token
            });
            console.log('resp:', response.data);
            if (response.data.status === 'success') {
                data = response.data.characters;
                console.log('data:', data);
                initializeCharacters();
            } else {
                loading.set(false);
            }
        } catch (error) {
            loading.set(false);
        }
    };

    const handleShowMore = (characterName) => {
        expandedCharacter.set(characterName);
    };


    const initializeCharacters = () => {
        let delay = 200;
        if (data.length === 0) {
            characters.set([{
                empty: true,
                message: translate('В этом разделе пока пусто', 'There is nothing yet', '这里还没什么内容')
            }]);
        } else {
            const mappedCharacters = data.map((character, index) => ({
                ...character,
                delay: `${delay + index * 100}ms`
            }));
            characters.set(mappedCharacters);
        }
        loading.set(false);
    };

   
    const paginatedCharacters = derived(
        [characters, currentPage],
        ([$characters, $currentPage]) => {
            const start = ($currentPage - 1) * itemsPerPage;
            const end = $currentPage * itemsPerPage;
            return $characters.slice(start, end);
        }
    );

    onMount(() => {
        fetchUserCharacters();
    });

    const handlePageChange = (page) => {
        currentPage.set(page);
    };

</script>

<style>
.centered-div {
    width: 60px;
    height: 60px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
}
.jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
}
@-webkit-keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
@keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

</style>
<div class="user--block-expanded-wrapper" in:blur={{ duration: 200 }}>
    {#if $loading}
        <div class="loading-huge jello-horizontal"></div>
    {:else}
        <div class="user--popup-collection-wrap">
            <div class="user--popup-story-main-inner">
                {#if $characters.length === 0 || $characters[0]?.empty}
                    <div class="user--popup-indicator-empty-block">{$characters[0]?.message}</div>
                {:else}
                    {#each $paginatedCharacters as bigCollection}
                        <div class="user--popup-collection-block-wrap" style="animation-delay: {bigCollection.delay}">
                            <h2>{bigCollection.character.name}</h2>
                            <div class="user--block-collection-inside-wrap">
                                {#each bigCollection.characterStickers.slice(0, 2) as sticker, index}
                                    <div class="user--block-collection-inside-block slide-in-blurred-bottom" style="animation-delay: {index * 100 + 200}ms">
                                        <div class="user--block-collection-inside-block-image-wrap">
                                            <div class="user--block-collection-image-loader"></div>
                                            <img src={`https://alexanderkireev.org/contributor/static/stickers/${sticker.image}`} alt="Sticker Image" on:load={(event) => {
                                                const loader = event.target.previousSibling;
                                                if (loader) loader.remove();
                                            }} />
                                        </div>
                                        <div class="user--block-collection-inside-block-text-wrap">{sticker.comment}</div>
                                    </div>
                                {/each}
                                {#if bigCollection.characterStickers.length > 2 && $expandedCharacter !== bigCollection.character.name}
                                    <div class="user--class-generic-button-3" on:click={() => handleShowMore(bigCollection.character.name)}>
                                        {translate('Больше', 'Show more', '更多')}
                                    </div>
                                {/if}
                                {#if $expandedCharacter === bigCollection.character.name}
                                    
                                        {#each bigCollection.characterStickers.slice(2) as sticker, index}
                                            <div class="user--block-collection-inside-block slide-in-blurred-bottom" style="animation-delay: {index * 100 + 200}ms">
                                                <div class="user--block-collection-inside-block-image-wrap">
                                                    <div class="user--block-collection-image-loader"></div>
                                                    <img src={`https://alexanderkireev.org/contributor/static/stickers/${sticker.image}`} alt="Sticker Image" on:load={(event) => {
                                                        const loader = event.target.previousSibling;
                                                        if (loader) loader.remove();
                                                    }} />
                                                </div>
                                                <div class="user--block-collection-inside-block-text-wrap">{sticker.comment}</div>
                                            </div>
                                        {/each}
                                  
                                {/if}
                            </div>
                        </div>
                    {/each}
                    <Pagination
                        totalItems={$characters.length}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                {/if}
            </div>
        </div>
    {/if}
</div>