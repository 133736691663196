<script>
    import { onMount, onDestroy } from 'svelte';
    import { fly, blur } from "svelte/transition";
    import { writable, get } from 'svelte/store';
    import TrueFalseExercise from './TrueFalseExercise.svelte'; // REMOVED ANSWER FOR 0 
    import MultipleChoiceExercise from './MultipleChoiceExercise.svelte'; // REMOVED ANSWER FOR 0
    import ImageExercise from './ImageExercise.svelte'; // REMOVED ANSWER FOR 0
    import MatchExercise from './MatchExercise.svelte'; // REMOVED ANSWER FOR 0
    import SequenceExercise from './Sequence.svelte'; // REMOVED ANSWER FOR 0
    import ParticleExercise from './ParticleExercise.svelte'; // REMOVED ANSWER FOR 0
    import TableListExercise from './TableListExercise.svelte'; // REMOVED ANSWER FOR 0
    import videojs from 'video.js';
    import 'video.js/dist/video-js.css'; // CSS assets
    import audiojs from 'audio.js';
    import 'audio.js/dist/audio-js.css'; // CSS assets
    // import {Audio} from 'svelte-audio';
    //import {AudioPlayer} from 'svelte-mp3';
  //  import WaitingComponent from './WaitingComponent.svelte';

  
    export let slideData;
    export let exerciseID;
    export let location; // location -- out of 3 possible values: 'revision', 'homework', or 'classroom'
    export let completedData;
    let loading = writable(true);
    let player;
    let videoElement;
    let playerAudio;
    let audioElement;

    function initializePlayer() {
    if (videoElement) {
      console.log("Video element found and source is available.");

      if (player) {
        console.log("Destroying existing player instance.");
        player.dispose();
      }

      player = videojs(videoElement, {
        controls: true,
        autoplay: false,
        preload: 'auto',
        fluid: true,
      });

      console.log("Player initialized successfully.");
    } else {
      console.log("Video element or source not found.");
    }
  }

  function initializeAudioPlayer() {
  if (audioElement) {
    console.log("Audio element found and source is available.");

    if (playerAudio) {
      console.log("Destroying existing audio player instance.");
      playerAudio.dispose();
    }

    playerAudio = audiojs(audioElement, {
      controls: true,
      autoplay: false,
      preload: 'auto',
      fluid: true,
    });

    console.log("Audio player initialized successfully.");
  } else {
    console.log("Audio element or source not found.");
  }
}



      
    onMount(() => {
      loading.set(false);
      if (slideData.contents[0].hasVideo) {
        initializePlayer();
      }
      if (slideData.contents[0].isListening) {
        initializeAudioPlayer();
      }
    });

    onDestroy(() => {
    if (player) {
      player.dispose(); // Clean up the Video.js instance on component destroy
    }
    if (playerAudio) {
      playerAudio.dispose(); // Clean up the Audio.js instance on component destroy
    }
  });
  
    // data is already fetched? 
    function fetchExerciseData() {
      sendSocketEvent('loadExercise', { exerciseID });
      // Assume the socket event will fetch data and update slideData
      loading.set(false);
    }
  </script>
  
  {#if $loading}
  <div class="loading-huge jello-horizontal"></div>
{:else}
  <div class="user--class-exercise-wrap" in:blur={{duration: 500}}>
    <div class="user--class-exercise-main-header">
      {@html slideData.slideName}
    </div>
    {#if slideData.contents[0].hasImage}
      <img src={slideData.contents[0].imageLink} alt="Exercise Image" class="user--class-started-image">
    {/if}
    
    {#if (slideData.contents[0].isListening && slideData.contents[0].isListening === "true") || slideData.contents[0].isListening === true}
    {#if (slideData.contents[0].isListening && slideData.contents[0].isListening === "true") || slideData.contents[0].isListening === true}
    <audio 
      bind:this={audioElement} 
      id="audio-js"
      class="audio-js"
      controls
      preload="auto"
    >
      <source src={slideData.contents[0].audioSrc} type="audio/mp3" />
      Аудио не поддерживается
    </audio>
  {/if}
    {/if}
    
    {#if (slideData.contents[0].hasVideo && slideData.contents[0].hasVideo === true) || slideData.contents[0].hasVideo === "true"}
      <video 
      bind:this={videoElement} 
      id="video-js"
      class="video-js vjs-default-skin custom-modifications"
      controls
      preload="auto"
    >
      <source src={slideData.contents[0].videoSrc} type="video/mp4" />
    Видео не поддерживается
    </video>
    {/if}

    {#if slideData.contents[0].type === 'truefalse'}
    <TrueFalseExercise slideData={slideData.contents[0]} exerciseID={exerciseID} location={location} completedData={completedData}/>
  {:else if slideData.contents[0].type === 'multiplechoice_multiple' || slideData.contents[0].type === 'multiplechoice'}
    <MultipleChoiceExercise slideData={slideData.contents[0]} exerciseID={exerciseID} location={location} completedData={completedData} />
  {:else if slideData.contents[0].type === 'match'}
    <MatchExercise slideData={slideData.contents[0]} exerciseID={exerciseID} location={location} completedData={completedData} />
  {:else if slideData.contents[0].type === 'image'}
    <ImageExercise slideData={slideData.contents[0]} exerciseID={exerciseID} location={location} completedData={completedData} />
  {:else if slideData.contents[0].type === 'sequence'}
    <SequenceExercise slideData={slideData.contents[0]} exerciseID={exerciseID} location={location} completedData={completedData} />
  {:else if slideData.contents[0].type === 'particle'}
    <ParticleExercise slideData={slideData.contents[0]} exerciseID={exerciseID} location={location} completedData={completedData} />
  {:else if slideData.contents[0].type === 'tablelist'}
    <TableListExercise slideData={slideData.contents[0]} exerciseID={exerciseID} location={location} completedData={completedData} />
  {:else if slideData.contents[0].type === 'waiting'}
    <WaitingComponent slideData={slideData.contents[0]} />
  {/if}
  </div>
{/if}

  
  <style>
    .centered-div {
      width: 60px;
      height: 60px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .loading-huge {
      width: 60px;
      height: 60px;
      background: linear-gradient(45deg, #020344, #28b8d5);
      border-radius: 50%;
      position: relative;
    }
    .jello-horizontal {
      -webkit-animation: jello-horizontal 0.9s infinite both;
      animation: jello-horizontal 0.9s infinite both;
    }
    @-webkit-keyframes jello-horizontal {
      0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
      30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
      }
      40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
      }
      50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
      }
      65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
      }
      75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
      }
      100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    }
    @keyframes jello-horizontal {
      0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
      30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
      }
      40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
      }
      50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
      }
      65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
      }
      75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
      }
      100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    }
  </style>