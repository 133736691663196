<script>
  import { writable } from "svelte/store";
  import { fly, blur } from "svelte/transition";
  import {userStore, websocketData, notesReceived } from '../store.js';
  import {onMount } from 'svelte';
  import {sendSocketEvent} from '../websocket.js';

  let message = writable("");
  let messages = writable([]);
  let rows = writable(1);
  let notesLoading = true;  

  // local assets

  const sendInactive = `<svg style="width: 100%; height: 100%;" viewBox="0 0 80 80" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M68.8458 0.268391L6.8015 15.7795C-1.44482 17.8411 -2.47422 29.144 5.26398 32.6615L24.3801 41.3507C28.2993 43.1319 32.5419 43.7755 36.6647 43.3363C36.2255 47.4591 36.8687 51.7015 38.6503 55.6207L47.3395 74.7367C50.8571 82.4751 62.1599 81.4455 64.2215 73.1995L79.7322 11.155C81.3762 4.58019 75.4206 -1.37529 68.8458 0.268391ZM27.6906 34.0675C31.4389 35.7715 35.6343 35.9015 39.3911 34.5691C40.8111 34.0655 42.1683 33.3527 43.4151 32.4371C43.5887 32.3095 43.7603 32.1779 43.9295 32.0427L53.0847 24.7185C54.5419 23.5527 56.4483 25.4592 55.2823 26.9163L47.9583 36.0715C47.8227 36.2407 47.6915 36.4123 47.5639 36.5859C46.6483 37.8327 45.9355 39.1899 45.4319 40.6099C44.0991 44.3667 44.2295 48.5619 45.9331 52.3103L54.6223 71.4263C55.0055 72.2687 56.2359 72.1567 56.4603 71.2591L71.9715 9.21467C72.1503 8.49891 71.5018 7.85059 70.7862 8.02955L8.74182 23.5406C7.84406 23.765 7.73202 24.9955 8.57442 25.3784L27.6906 34.0675Z" fill="#85ADD1"/>
</svg>`;

  const sendActive = `<svg style="width: 100%; height: 100%" viewBox="0 0 80 80" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M68.8458 0.268391L6.8015 15.7795C-1.44482 17.8411 -2.47422 29.144 5.26398 32.6615L24.3801 41.3507C28.2993 43.1319 32.5419 43.7755 36.6647 43.3363C36.2255 47.4591 36.8687 51.7015 38.6503 55.6207L47.3395 74.7367C50.8571 82.4751 62.1599 81.4455 64.2215 73.1995L79.7322 11.155C81.3762 4.58019 75.4206 -1.37529 68.8458 0.268391ZM27.6906 34.0675C31.4389 35.7715 35.6343 35.9015 39.3911 34.5691C40.8111 34.0655 42.1683 33.3527 43.4151 32.4371C43.5887 32.3095 43.7603 32.1779 43.9295 32.0427L53.0847 24.7185C54.5419 23.5527 56.4483 25.4592 55.2823 26.9163L47.9583 36.0715C47.8227 36.2407 47.6915 36.4123 47.5639 36.5859C46.6483 37.8327 45.9355 39.1899 45.4319 40.6099C44.0991 44.3667 44.2295 48.5619 45.9331 52.3103L54.6223 71.4263C55.0055 72.2687 56.2359 72.1567 56.4603 71.2591L71.9715 9.21467C72.1503 8.49891 71.5018 7.85059 70.7862 8.02955L8.74182 23.5406C7.84406 23.765 7.73202 24.9955 8.57442 25.3784L27.6906 34.0675Z" fill="#0C5CA4"/>
</svg>`;

function sendMessage() {
    if ($message.trim() !== "") {
      notesReceived.update(notes => [{ message: $message, isTeacherNote: $websocketData.isTeacher }, ...notes]);
      // console.log('immediate update trace. notesReceived updated: ', $notesReceived);
      sendNote($message);
      message.set("");
    } 
  } 

  function fetchNotes() {
    sendSocketEvent('fetchNotes', 0);
    // $notesReceived 
  }

  function sendNote(value) {
    sendSocketEvent('addNotes', value);
    console.log('sending the note with the value ', value);
  }
 /*  function autoResize(event) {
    const textarea = event.target;
    textarea.style.height = 'auto';
    const scrollHeight = textarea.scrollHeight;
    const lineHeight = parseInt(getComputedStyle(textarea).lineHeight, 10);
    const currentRows = Math.ceil(scrollHeight / lineHeight);

    rows.set(Math.min(currentRows, 2)); 
    textarea.style.height = 'auto'; 

    // console.log('AUTO-RESIZE DEBUG. scrollheight:', scrollHeight, 'lineHeight:', lineHeight, 'currentRows:', currentRows);
  }
  */ 
  $: {
    if ($notesReceived) {
        notesLoading = false;
        console.log('Received notes: ', $notesReceived);
    }
  }
  onMount(() => {
    fetchNotes();
  });
</script>

<div class="chat-wrapper" in:fly={{y: 300}} out:fly={{y: 300}}>
  {#if notesLoading}
  <div class="loading-huge jello-horizontal"></div>
  {:else}
  {#if $notesReceived.length === 0}
  <div class="placeholder">
      <div class="placeholder-svg">{@html sendActive}</div>

      {#if $websocketData.isTeacher}
      Оставляйте заметки Вашему ученику
      {:else}
      Оставляйте заметки, задавайте вопросы преподавателю и получайте ответы
      {/if}
  </div>
{:else}

<div class="message-list">
  {#each $notesReceived as note, index (note)}
    <div in:blur={{duration: 500}} class="{note.isTeacherNote == true && !$websocketData.isTeacher ? 'message-received' : 'message-sent'}">{note.message}</div>
  {/each}
</div>
{/if}
  <div class="input-container">
    <textarea
    bind:value={$message}
    class="input"
    placeholder="Введите Вашу заметку"
    rows={$rows}
    on:keypress={(e) => e.key === "Enter" ? (sendMessage(), e.preventDefault()) : null}
></textarea>
    <div class="send-button" on:click={sendMessage}>
     {@html sendInactive}
    </div>
  </div>
  {/if}
</div>

<style>
  .chat-wrapper {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    height: 60%; 
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 40px;
    border: 3px solid #85ADD1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }

  .message-list {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .message-sent {
    background: rgba(12, 92, 164, 0.1);
    width: 90%;
    padding-inline: 15px;
    padding-block: 20px;
    border-radius: 20px;
    margin: 5px 0;
    align-self: end;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    color: #000000;
    font-size: 20px;
    font-weight: 350;
    display: flex;
    /* overflow-x: hidden; */
    white-space: normal;
  }
  .message-received {
    background: rgba(142, 142, 142, 0.1);
    width: 90%;
    padding-inline: 15px;
    padding-block: 20px;
    border-radius: 20px;
    margin: 5px 0;
    align-self: start;
    color: #5c5c5c;
    font-size: 20px;
    font-weight: 350;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    display: flex;
    /* overflow-x: hidden; */
    white-space: normal;
  }

  .input-container {
    width: 100%;
    display: flex;
    align-items: center;
    background: white;
    border-radius: 30px;
    padding: 15px;
   border: 2px solid #85ADD1;
  }

  .input {
    flex: 1;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 20px;
    resize: none;
  }

  .send-button {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
    margin-left: 10px;
  }

  .placeholder {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-align: center;
        color: #5c5c5c;
        font-weight: 350;
        gap: 10px;
        font-size: 20px;
    }
    .placeholder-svg {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
       
    }

    .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  @media (max-width: 750px) {
  .chat-wrapper {
    width: 95%;
    z-index: 1000;
    overflow-y: overflow;
  }
  .message-sent, .message-received {
    width: 100%;
  }
}

</style>
