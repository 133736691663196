<script>
  import { writable, get, derived } from "svelte/store";
  import { sendSocketEvent } from "../websocket.js";
  import {userStore, highlightAnswer} from '../store.js';
  import { onMount } from "svelte";


// TODO: 


  const iconSubmitted = `<svg fill="#4CBB9A" style="width: 100%; height: 100%" viewBox="0 0 32 32"><path d="m16 0c8.836556 0 16 7.163444 16 16s-7.163444 16-16 16-16-7.163444-16-16 7.163444-16 16-16zm0 2c-7.7319865 0-14 6.2680135-14 14s6.2680135 14 14 14 14-6.2680135 14-14-6.2680135-14-14-14zm6.6208153 9.8786797c.3905243.3905242.3905243 1.0236892 0 1.4142135l-7.0710678 7.0710678c-.3626297.3626297-.9344751.3885319-1.3269928.0777064l-.0872208-.0777064-4.24264068-4.2426407c-.39052429-.3905242-.39052429-1.0236892 0-1.4142135.39052428-.3905243 1.02368928-.3905243 1.41421358 0l3.5348268 3.5348268 6.3646681-6.3632539c.3905243-.3905243 1.0236893-.3905243 1.4142136 0z"/></svg>`;

  export let slideData;
  export let exerciseID;
  export let location; // location can be 'revision', 'homework', or 'classroom'
  export let completedData;
  let imageLoading = writable(slideData.pictureArray.map(() => true));
  let partScore = 0;
  let maxScore = 100;

  let userAnswers = writable(new Array(slideData.answer.length).fill(""));
  let correctAnswers = slideData.answer.map((answer) =>
    answer.toLocaleLowerCase("ru")
  );
  let feedback = writable(new Array(slideData.answer.length).fill(null));
  let answerFeedback = writable(new Array(slideData.answer.length).fill(null));
  let submitted = writable(false);



  // Precheck for existing data: 





  // WebSocket event handler
// WebSocket event handler for dynamic highlighting
$: if ($highlightAnswer.id === exerciseID) {
  const eventAnswer = $highlightAnswer.answer;
  const blockIndex = $highlightAnswer.blockIndex;
  // console.log('[DEBUG] - highlight answer function and the values that are received:', eventAnswer, blockIndex);
  
  // Update the feedback to highlight the answer based on blockIndex
  answerFeedback.update(af => {
    af[blockIndex] = eventAnswer === correctAnswers[blockIndex] ? 'correct' : 'incorrect';
    return af;
  });

  handleAnswer(blockIndex, eventAnswer);

  // !!!! IMPORTANT: CLEAR UP THE VALUE AS WE GO TO ANOTHER SLIDE
  // highlightAnswer.set({}); // Make sure this doesn't deselect the chosen answer
}



  function handleAnswer(index, value) {
  //  console.log(`[DEBUG -- INSIDE HANDLE ANSWER] Updating index: ${index} with value: ${value}`);
  userAnswers.update((answers) => {
    answers[index] = value;
  //  console.log(`[DEBUG -- USER ANSWERS AFTER UPDATE]`, answers);
    return answers;
  });

  feedback.update((feedbackArray) => {
    feedbackArray[index] = value;
    console.log(`[DEBUG -- FEEDBACK AFTER UPDATE]`, feedbackArray);
    return feedbackArray;
  });

  // Send the selected answer to the teacher's socket in real-time with the index as the block/button id
  const eventDetails = {
    actionName: {
      kind: "truefalse",
      answer: value,
      id: exerciseID, // id refers to the exercise
      blockIndex: index // use blockIndex to refer to specific button index or block number
    },
  };
  
  sendSocketEvent("studentAnswerDynamic", eventDetails);
}




  let hintExpanded = writable(false);
  function expandHint() {
    hintExpanded.set(!$hintExpanded);
  }

  $: checkAnswers();

  function checkAnswers() {
    feedback.update((fb) => {
      const ua = get(userAnswers);
      for (let i = 0; i < correctAnswers.length; i++) {
        if (ua[i] && ua[i].toLocaleLowerCase("ru") !== correctAnswers[i]) {
          fb[i] = false;
        } else if (ua[i]) {
          fb[i] = true;
        }
      }
      return fb;
    });
  }

  function submitAnswers() {
  if (get(submitted)) return; 
  let userAnswerArray = get(userAnswers).map((answer) =>
    answer.toLocaleLowerCase("ru")
  );
  

  function calculatePartScore() {
    const totalQuestions = correctAnswers.length;
    const correctCount = get(feedback).filter((fb) => fb === true).length;

    // Part score is proportional to the number of correct answers
    partScore = (correctCount / totalQuestions) * maxScore;
  }

  // Check if all answers are correct
  let isAllCorrect = get(answerFeedback).every((feedback) => feedback === "correct");

  // Update feedback for each answer
  answerFeedback.update((af) => {
    const ua = get(userAnswers);
    for (let i = 0; i < correctAnswers.length; i++) {
      if (ua[i] && ua[i].toLocaleLowerCase("ru") === correctAnswers[i]) {
        af[i] = "correct";
      } else {
        af[i] = "incorrect";
      }
    }
    return af;
  });

  calculatePartScore();

  // Determine the correct endpoint
  let endpoint;
  if (location === "revision") {
    endpoint = "exerciseCompletedRevision";
  } else if (location === "homework") {
    endpoint = "exerciseCompletedRevision";
  } else if (location === "classroom") {
    endpoint = "exerciseCompleted";
  }

  // Prepare the eventDetails object, mirroring the legacy function structure
  const eventDetails = {
    actionName: {
      exerciseID: exerciseID,
      isCorrect: isAllCorrect ? 'true' : 'false',
      isCreative: false,
      partialScore: partScore
    },
    answers: userAnswerArray,
    isAllCorrect: isAllCorrect,
  };

  if (isAllCorrect) {
   // document.querySelector('.user--class-exercise-answer-block').style.background = 'rgba(135,194,177, 0.1)';
   // document.querySelector('.user--class-exercise-button-answer').classList.add('user--button-clicked');

    if (buildData['assign_award'] && buildData['assign_award'] === 'true') {
      sendSocketEvent('assignAward', '');
    }
    
  } 
  submitted.set(true);
  sendSocketEvent(endpoint, eventDetails);
}


  const allAnswered = derived(userAnswers, ($userAnswers) => {
    return $userAnswers.every((answer) => answer !== "");
  });

function proceedNext() {
  sendSocketEvent('proceedSelf', '');
}  

onMount(() => {
  // If completedData exists, populate the answers and feedback automatically
if (completedData && completedData.studentAnswer != null) {
  try {
    // Ensure parsedStudentAnswer is an array and process its elements
    const parsedStudentAnswer = JSON.parse(completedData.studentAnswer);

    if (Array.isArray(parsedStudentAnswer)) {
      // Populate the answers from the completed data
      parsedStudentAnswer.forEach((answer, index) => {
        if (typeof answer === 'string') {
          handleAnswer(index, answer.toLocaleLowerCase("ru"));
        }
      });

      // Set the feedback based on whether the answer was correct or not
      answerFeedback.update((af) => {
        const ua = get(userAnswers);
        for (let i = 0; i < correctAnswers.length; i++) {
          if (ua[i] && ua[i].toLocaleLowerCase("ru") === correctAnswers[i]) {
            af[i] = "correct";
          } else {
            af[i] = "incorrect";
          }
        }
        return af;
      });

      // Mark as submitted if data exists
      submitted.set(true);
    }
  } catch (error) {
    console.error("Error parsing studentAnswer:", error);
  }
}
});
</script>

<div class="user--class-exercise-wrap">
  <div class="user--class-exercise-header">
    {@html slideData.textContent}
  </div>
  <div class="user--class-exercise-answer-block exercise-answer-block-row-wrap">
    {#each slideData.answer as answer, index}
      <div
        class="user--exercise-block-truefalse-wrapper"
        style="border: 3px solid {$answerFeedback[index] === 'correct'
          ? 'rgba(135, 194, 177, 1)'
          : $answerFeedback[index] === 'incorrect'
            ? 'rgba(255, 147, 147, 1)'
            : '#0C5CA4'}"
      >
        {#if slideData.textContentArray && slideData.textContentArray[index]}
          <p class="user--exercise-truefalse-text">
            {@html slideData.textContentArray[index]}
          </p>
        {/if}
        {#if slideData.pictureArray && slideData.pictureArray[index]}
          <div class="image-wrapper">
            {#if $imageLoading[index]}
              <div class="image-loader"></div>
            {/if}
            <img
              src={slideData.pictureArray[index]}
              alt="Exercise Image"
              class="user--exercise-match-image"
              style="display: {$imageLoading[index] ? 'none' : 'block'};"
              on:load={() =>
                imageLoading.update((loads) => {
                  loads[index] = false;
                  return loads;
                })}
              on:error={() =>
                imageLoading.update((loads) => {
                  loads[index] = false;
                  return loads;
                })}
            />
          </div>
        {/if}
        <button
          class="user--class-exercise-answer-truefalse-select"
          style="border: 3px solid rgba(135, 194, 177, 1); background-color: {$feedback[
            index
          ] == 'true'
            ? 'rgba(135, 194, 177, 0.5)'
            : 'transparent'}"
          on:click={() => handleAnswer(index, "true")}
        >
          Верно
        </button>
        <button
          class="user--class-exercise-answer-truefalse-select"
          style="border: 3px solid rgba(255, 147, 147, 1); background-color: {$feedback[
            index
          ] == 'false'
            ? 'rgba(255, 147, 147, 0.5)'
            : 'transparent'}"
          on:click={() => handleAnswer(index, "false")}
        >
          Неверно
        </button>
        {#if slideData.hint[index] && $hintExpanded}
          <div class="user--exercise-hint-mini">
            {slideData.hint[index]}
          </div>
        {/if}
      </div>
    {/each}
  </div>
  <div class="user--class-exercise-buttons-wrap">
    {#if $submitted}
    {#if location == "classroom"}
      <div class="submitted-icon">{@html iconSubmitted}</div>
    {:else if (location == "homework" || location == "revision")}
    <button class="user--class-generic-button-3"  on:click={(event) => {
      event.currentTarget.classList.add('inactive');
      event.currentTarget.textContent = 'Загружаю...';
      proceedNext();
    }}
    >Продолжить</button
  >
    {/if}
    {:else}
    {#if $userStore.student_status != 0}
    <button
    on:click={(event) => {
      event.currentTarget.classList.add('inactive');
      event.currentTarget.textContent = 'Загружаю...';
      submitAnswers();
    }}
    class="user--class-generic-button-3 {$allAnswered ? '' : 'user--button-clicked'}">
    Ответить
  </button>
  {/if}
      {#if slideData.hint && slideData.hint.some((hint) => hint !== "")}
        <button class="user--class-generic-button-3" on:click={expandHint}
          >Подсказка</button
        >
      {/if}
    {/if}
  </div>
</div>

<style>
  .centered-div {
    width: 60px;
    height: 60px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  .submitted-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
</style>
