// WebSocket.js
import { get } from 'svelte/store';
import { userStore, websocketData, notesReceived, isCheckLoaded, currentClass, pendingResponse, isGeneratingChat, generatedMessages, preloadingChat, isChatError, triggerNotification, highlightAnswer } from './store.js';

let wsConnect;
let attempts = 0;
let lastSlideData = null;

function reconnectDelay(attempts) {
  const baseDelay = 2000;
  const maxDelay = 60000;
  const delay = baseDelay * Math.pow(2, attempts);
  return Math.min(delay, maxDelay);
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  const stringValue = JSON.stringify(value);
  document.cookie = name + "=" + encodeURIComponent(stringValue) + expires + "; path=/";
}

function getCookieSecondary(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) {
      const stringValue = c.substring(nameEQ.length, c.length);
      try {
        return JSON.parse(decodeURIComponent(stringValue));
      } catch (error) {
        console.error(`Error parsing JSON from cookie: ${error}`);
        return null;
      }
    }
  }
  return null;
}

// function checkAndSetInstructionCookie(actionName) {
//   const cookieName = "instructions_shown";
//   let instructionsShown = getCookieSecondary(cookieName);

//   if (!instructionsShown) {
//     instructionsShown = {};
//   }

//   if (!instructionsShown[actionName]) {
//     instructionsShown[actionName] = true;
//     setCookie(cookieName, instructionsShown, 365);
//     return true;
//   }

//   return false;
// }

function initConnect() {
  wsConnect = new WebSocket('wss://api.clsrm.org/ws');
  // console.log('userStore:', get(userStore)); 
  const jwtToken = get(userStore).token;
  console.log('[ws debug] re/connect');
  if (jwtToken) {
    wsConnect.addEventListener('open', () => {
      wsConnect.send(JSON.stringify({ type: 'auth', token: jwtToken }));
      isCheckLoaded.set(true);
    });

    wsConnect.addEventListener('message', (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'note') {
            const currentNotes = get(notesReceived);
            const newNotes = data.notesData;
        
            const arraysEqual = (a, b) => {
                if (a.length !== b.length) return false;
                for (let i = 0; i < a.length; i++) {
                    if (a[i].message !== b[i].message || a[i].isTeacherNote !== b[i].isTeacherNote) {
                        return false;
                    }
                }
                return true;
            };
            if (!currentNotes) {
                notesReceived.set(newNotes);
                console.log('Set the received notes:', get(notesReceived));
            } else
            if (!arraysEqual(currentNotes, newNotes)) {
                notesReceived.set(newNotes);
                console.log('Set the received notes:', get(notesReceived));
            }
        }  
        if (data.type === 'ping') {
          wsConnect.send(JSON.stringify({ type: 'pong'}));
        } 
        if (data.type === 'revision') {
          console.log('[DEBUG] -- RECEIVED DATA FOR REVISION -- ', data)
         // currentClass.set(true);
          const currentSlideData = {
            slideData: data.slideData ?? null,
            // timePoint: data.timePoint,
            // slidesAmount: data.slidesAmount,
            // currentSlide: data.currentSlide,
          };
          websocketData.set(currentSlideData); 
        }      

        if (data.type === 'preload-chat') {
          const chatHistory = data.chatHistory;
          console.log('RECEIVED CHAT HISTORY', chatHistory);
        
          if (Array.isArray(chatHistory) && chatHistory.length > 0) {
           
            generatedMessages.update(messages => [...messages, ...chatHistory]);
          } 
            preloadingChat.set(false);
          
        
      
        }  
 
        if (data.type === 'chat') {
          if(!get(isGeneratingChat)) {
            isGeneratingChat.set(true);
          }
          console.log('WEBSOCKET DEBUG -- PARSED DATA CONTENT',data.content); 
          const content = data.content;
          if (content) {
            pendingResponse.update(response => response + content);
          }
         
         }      

         if (data.type === 'chat-stream-finished') {
          isGeneratingChat.set(false);
          pendingResponse.update(response => {
            generatedMessages.update((msgs) => [...msgs, { content: response, role: 'message-received' }]);
            return ''; 
          });
  
          // clearning the pending message object in 3 seconds
          setTimeout(() => {
            pendingResponse.set('');
          }, 3000);
        
         }   

         if (data.type === 'chat-stream-error') {
          isGeneratingChat.set(false);
          isChatError.set(true);
          pendingResponse.update(response => {
            generatedMessages.update((msgs) => [...msgs, { content: 'Извини, у меня заболела лапка. Давай попробуем чуть позже?', role: 'message-received' }]);
            return ''; 
          });
         } 

        if (data.type === 'homework') {
          // currentClass.set(true);
           const currentSlideData = {
             slideData: data.slideData,
             // timePoint: data.timePoint,
             // slidesAmount: data.slidesAmount,
             // currentSlide: data.currentSlide,
           };
           websocketData.set(currentSlideData);
         }      

         if (data.type === 'notification') {
            triggerNotification({
              header: data.header || 'Уведомление',
              message: data.message || ' ',
              type: data.exType || 'info',
              duration: data.timer || 5000
            });
         }      

         if (data.type === 'highlightAnswer') {
          const { kind, answer, id, blockIndex, inputIndex, itemIndex, particleIndex } = data;
          handleHighlightAnswer(data);
        }


        if (data.type === 'slide') {
          currentClass.set(true);
          const currentSlideData = {
            slideData: data.slideData,
            timePoint: data.timePoint,
            slidesAmount: data.slidesAmount,
            currentSlide: data.currentSlide,
            slidesSet: data.viewedSlidesSet,
            isTeacher: data.isTeacher,
            sReady: data.sReady,
            tReady: data.tReady,
            conferenceUrl: data.conferenceUrl,
            wbroom: data.wbroom,
            completedData: data.completedData
          };
        //  console.log('updated currentSlideData', currentSlideData);
        //  setCookie('teacher_mark', data.isTeacher, 30);
          if (window.lastSlideData === null || JSON.stringify(window.lastSlideData) !== JSON.stringify(currentSlideData)) {
            // Handle the class creation logic here
            // CreateClass(data.slideData, data.timePoint, data.slidesAmount, data.currentSlide, data.viewedSlidesSet, data.isTeacher);
            websocketData.set(currentSlideData);
            window.lastSlideData = currentSlideData;
          }
        }
        // Handle other data types...

      } catch (error) {
        console.error(`Error parsing JSON message: ${error}`);
      }
    });

    wsConnect.addEventListener('close', () => {
      setTimeout(() => {
        attempts++;
        if (attempts % 3 === 0) {
          // Create notification about poor connection
        }
        initConnect();
      }, reconnectDelay(attempts));
    });

    wsConnect.addEventListener('error', (error) => {
      console.error(`WebSocket error: ${error}`);
    });
  }
}

function sendSocketEvent(eventType, actionName) {
  if (wsConnect.readyState === WebSocket.OPEN) {
    wsConnect.send(JSON.stringify({ type: eventType, actionName: actionName }));
    attempts = 0;
  } else {
    setTimeout(() => {
      attempts++;
      sendSocketEvent(eventType, actionName);
      if (attempts % 3 === 0) {
        // Create notification about poor connection
      }
    }, reconnectDelay(attempts));
  }
}

function handleHighlightAnswer(data) {
  highlightAnswer.set(data);
}

export { initConnect, sendSocketEvent }; 
