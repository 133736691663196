<script>
  // Assets
  import { fly, blur } from "svelte/transition";
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { isAuthorized, userStore } from "../store.js";
  import { translate } from "../translation";
  import axios from "axios";
  import Cookies from "js-cookie";
  import { writable } from "svelte/store";
  import PushNotification from "./PushNotification.svelte";

  const infoIcon = `<svg style="width: 100%; height: 100%" viewBox="0 0 24 24" fill="none" >
<circle cx="12" cy="12" r="10" stroke="#0c5ca450" stroke-width="1.5"/>
<path d="M12 17V11" stroke="#0c5ca450" stroke-width="1.5" stroke-linecap="round"/>
<circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="#0c5ca450"/>
</svg>`;

  const foldButton = `<svg viewBox="0 0 40 40" fill="none"><circle cx="20" cy="20" r="19" stroke="#C8C8C8" stroke-width="2"></circle><path d="M14.3427 12.9289C13.9522 12.5384 13.319 12.5384 12.9285 12.9289C12.538 13.3195 12.538 13.9526 12.9285 14.3431L18.5853 20L12.9285 25.6569C12.538 26.0474 12.538 26.6805 12.9285 27.0711C13.319 27.4616 13.9522 27.4616 14.3427 27.0711L19.9996 21.4142L25.6564 27.0711C26.0469 27.4616 26.6801 27.4616 27.0706 27.0711C27.4612 26.6805 27.4612 26.0474 27.0706 25.6569L21.4138 20L27.0706 14.3431C27.4612 13.9526 27.4612 13.3195 27.0706 12.9289C26.6801 12.5384 26.0469 12.5384 25.6564 12.9289L19.9996 18.5858L14.3427 12.9289Z" fill="#1D5D8B"></path>`;

  let infoBlockExpanded = writable(false);

  function expandInfoBlock() {
    infoBlockExpanded.set(true);
  }
  function closeInfoBlock() {
    infoBlockExpanded.set(false);
  }
</script>

<div class="footer-block">
  <div class="footer-info-icon" on:click={() => expandInfoBlock()}>
    {@html infoIcon}
  </div>
</div>

<!-- INFO BLOCK -->
{#if $infoBlockExpanded}
  <div
    class="user--controls-settings-wrapper"
    style="gap: 20px"
    in:blur={{ duration: 500 }}
    out:blur={{ duration: 500 }}
  >
    <div class="user--popup-fold-button" on:click={closeInfoBlock}>
      {@html foldButton}
    </div>
    <div class="info-block">
      <div class="teachers--desc-image" style="margin: 0 auto">
        <img
          src="./assets/updates--main-descriptive.svg"
          alt="Изображение для раздела Обновления"
        />
      </div>
      <div class="info-block-main">
        <div class="info-block-header">Версия от 27 ноября</div>
        <div class="info-block-desc">
          Посмотрите историю обновлений платформы с подробным описанием
          нововведений
        </div>
        <div
          class="user--class-generic-button-3"
          on:click={() => {
            window.open(
              "./#/updates/",
              "_blank"
            );
          }}
        >
          Перейти
        </div>
      </div>
    </div>
    <div class="info-block">
      <div class="teachers--desc-image" style="margin: 0 auto">
        <img
          src="./assets/legal--main-descriptive.svg"
          alt="Изображение для раздела Юр. информация"
        />
      </div>
      <div class="info-block-main">
        <div class="info-block-header">Юридическая информация</div>
        <div class="info-block-desc">
          Посмотрите документацию и юридическую информацию платформы
        </div>
        <div
          class="user--class-generic-button-3"
          on:click={() => {
            window.open(
              "./#/legal/",
              "_blank"
            );
          }}
        >
          Перейти
        </div>
      </div>
    </div>
  </div>
{/if}

<!-- END OF THE INFO BLOCK -->
<style>
  .footer-block {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: max-content;
    padding: 20px;
  }

  .footer-info-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .footer-info-icon svg path {
    transition: all 0.3s linear;
  }
  .footer-info-icon:hover svg path {
    fill: #0c5ca4;
  }
  .info-block {
    width: 70%;
    background-color: #ffffff95;
    height: max-content;
    padding: 20px;
    border-radius: 40px;
    border: 3px solid #0c5ca450;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .info-block-icon img {
    width: 150px;
    height: 150px;
  }
  .info-block-main {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .info-block-header {
    font-size: 30px;
    font-weight: 900;
  }
  .info-block-desc {
    font-size: 23px;
    font-weight: 350;
    color: #5c5c5c;
  }
  @media (max-width: 750px) {
    .info-block {
      width: 100%;
      border-radius: 20px;
    }
    .info-block-desc {
      font-size: 20px;
    }
  }
</style>
