<script>
import { onMount, createEventDispatcher } from "svelte";
import { translate } from "../translation.js";
import axios from 'axios';
import {userStore, trackContentUpload, exerciseStore, setDefaultValues, API_BASE_URL} from '../../store.js'
// export let selectedExercise;
// export let existingData = null; 
// export let locationType;
// export let constructorObject;
// export let editObject;
// export let editExerciseID;
// console.log("[EXERCISE_EXTENSION - DEBUG] checking the imported stuff:", constructorObject, existingData); 
// idea: importing the ex object and complementing it with additional ext -- the idea
// is to make it more universal, so that it can be simply imported within each exercise
let showAudio = false;
let loadingAudio = false;
let audioLoaded = false;
let showVideo = false;
let loadingVideo = false;
let videoLoaded = false;
let showImage = false;
let imageLoaded = false;
let loadingImage = false;
let showCharacter = false;
let showPdf = false;
let loadingPdf = false;
let pdfLoaded = false;
// THE MEDIA DATA AS IN THE LEGACY IMPLEMENTATION
let chars = [];
let test = [];
let hasChar = false;
let hasPdf = false;
let hasVideo = false;
let videoLink = '';
let isListening = false;
let audioLink = '';
let pdfLink = '';
let hasImage = false;
let imageLink = '';


// assets
const addIcon2 = '<svg style="width: 100%; height: 100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z" fill="#1C274C"/><path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z" fill="#1C274C"/></svg>';
const doneIcon = `<svg style="width: 100%; height: 100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.00009 13L12.2278 16.3821C12.6557 16.7245 13.2794 16.6586 13.6264 16.2345L22.0001 6" stroke="#87d6b0" stroke-width="1.2" stroke-linecap="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5653 12.3677L15.4644 6.37999C15.6742 6.12352 15.6364 5.7455 15.3799 5.53567C15.1235 5.32583 14.7455 5.36363 14.5356 5.6201L9.6434 11.5995L10.5653 12.3677ZM8.03225 15.4637L7.11035 14.6954L6.14267 15.8782C6.00694 16.044 5.76456 16.0735 5.59309 15.9449L2.36 13.52C2.0949 13.3212 1.71882 13.3749 1.52 13.64C1.32118 13.9051 1.3749 14.2812 1.64 14.48L4.87309 16.9049C5.559 17.4193 6.52849 17.3016 7.07142 16.638L8.03225 15.4637Z" fill="#87d6b0" fill-opacity="0.24"/>
</svg>`;
//


let isMobile = false;

if (typeof window !== "undefined") {
  isMobile = window.innerWidth < 800;
  window.addEventListener("resize", () => {
    isMobile = window.innerWidth < 800;
  });
}

function handleAudioUpload(event, index) {
    loadingAudio = true;
    const file = event.target.files[0];
    const formcomp = new FormData();
    formcomp.append("attachment_file", file);
    formcomp.append("token", $userStore.token);
    axios
      .post(`${$API_BASE_URL}/uploadAttachment`, formcomp, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data)
      .then((resp) => {
       // loader.style.display = "none";
        if (resp.status === "success") {
        loadingAudio = false;
        // {#if (slideData.contents[0].isListening && slideData.contents[0].isListening === "true") || slideData.contents[0].isListening === true}
            // assing the resp.url to the exercise object 
        audioLoaded = true;
        exerciseStore.isListening.set('true');
        exerciseStore.audioLink.set(resp.url);
        } else {
          //console.error("Image upload failed:", resp.message);
        }
      })
      .catch((error) => {
        // loader.style.display = "none";
        console.error("Error uploading file:", error);
      });
  }

  function handleVideoUpload(event, index) {
    loadingVideo = true;
    const file = event.target.files[0];
    const formcomp = new FormData();
    formcomp.append("attachment_file", file);
    formcomp.append("token", $userStore.token);
    axios
      .post(`${$API_BASE_URL}/uploadAttachment`, formcomp, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data)
      .then((resp) => {
       // loader.style.display = "none";
        if (resp.status === "success") {
        loadingVideo = false;
        // {#if (slideData.contents[0].isListening && slideData.contents[0].isListening === "true") || slideData.contents[0].isListening === true}
            // assing the resp.url to the exercise object 
        videoLoaded = true;
        exerciseStore.hasVideo.set('true');
        exerciseStore.audioLink.set(resp.url);
        } else {
          //console.error("Image upload failed:", resp.message);
        }
      })
      .catch((error) => {
        // loader.style.display = "none";
        console.error("Error uploading file:", error);
      });
  }

  function handleImageUpload(event, index) {
    loadingImage = true;
    const file = event.target.files[0];
    const formcomp = new FormData();
    formcomp.append("attachment_file", file);
    formcomp.append("token", $userStore.token);
    axios
      .post(`${$API_BASE_URL}/uploadAttachment`, formcomp, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data)
      .then((resp) => {
       // loader.style.display = "none";
        if (resp.status === "success") {
        loadingImage = false;
        // {#if (slideData.contents[0].isListening && slideData.contents[0].isListening === "true") || slideData.contents[0].isListening === true}
            // assing the resp.url to the exercise object 
        imageLoaded = true;
        exerciseStore.hasImage.set('true');
        exerciseStore.imageLink.set(resp.url);
        } else {
          //console.error("Image upload failed:", resp.message);
        }
      })
      .catch((error) => {
        // loader.style.display = "none";
        console.error("Error uploading file:", error);
      });
  }

  function handlePdfUpload(event, index) {
    loadingPdf = true;
    const file = event.target.files[0];
    const formcomp = new FormData();
    formcomp.append("attachment_file", file);
    formcomp.append("token", $userStore.token);
    axios
      .post(`${$API_BASE_URL}/uploadAttachment`, formcomp, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data)
      .then((resp) => {
       // loader.style.display = "none";
        if (resp.status === "success") {
        loadingPdf = false;
        // {#if (slideData.contents[0].isListening && slideData.contents[0].isListening === "true") || slideData.contents[0].isListening === true}
            // assing the resp.url to the exercise object 
        pdfLoaded = true;
        exerciseStore.hasPdf.set('true');
        exerciseStore.pdfLink.set(resp.url);
        } else {
          //console.error("Image upload failed:", resp.message);
        }
      })
      .catch((error) => {
        // loader.style.display = "none";
        console.error("Error uploading file:", error);
      });
  }

function selectAttachment(type) {
  switch (type) {
    case "audio":
      showAudio = true;
      break;
    case "video":
      showVideo = true;
      break;
    case "image":
      showImage = true;
      break;
    case "character":
      showCharacter = true;
      break;
    case "pdf":
      showPdf = true;
      break;
  }
}

// character processing logic 

function updateHasChar() {
    hasChar = chars.length > 0;
  }

  function checkCharacter(index) {
    
    if (index >= 0 && index < test.length) {
        exerciseStore.test[index].set('true');
    }
  }

  function addCharacter() {
    exerciseStore.chars.set([...chars, '']); 
    exerciseStore.test.set([...test, false]);
    updateHasChar();
  }

  function handleCharCheck(index) {
    checkCharacter(index);
    // check the inp event 
    
  } 

  // char processing above


</script>

<div class="user--block-expanded-wrapper-modified">
<div class="user--class-exercise-header">Прикрепите вложение</div>
<div class="course-selector">
    <div
    class="user--class-generic-button-3-tiny"
    style="{isMobile ? 'width: 100%' : ''}" 
    on:click={() => selectAttachment("audio")}
  >
    Аудио
  </div>

  <div
    class="user--class-generic-button-3-tiny"
    style="{isMobile ? 'width: 100%' : ''}" 
    on:click={() => selectAttachment("video")}
  >
    Видео
  </div>

  <div
    class="user--class-generic-button-3-tiny"
    style="{isMobile ? 'width: 100%' : ''}" 
    on:click={() => selectAttachment("image")}
  >
    Изображение
  </div>

  <div
    class="user--class-generic-button-3-tiny"
    style="{isMobile ? 'width: 100%' : ''}" 
    on:click={() => selectAttachment("character")}
  >
    Иероглиф
  </div>

  <div
    class="user--class-generic-button-3-tiny"
    style="{isMobile ? 'width: 100%' : ''}" 
    on:click={() => selectAttachment("pdf")}
  >
    PDF слайд
  </div>

  <div class="attachment-wrapper">
    {#if showAudio}
        <!-- LOADER: <div class="loading-huge jello-horizontal"></div> -->
        <div class="attachment-wrap">
            {#if loadingAudio}
            <div style="width: 100%; height: max-content; display: flex; justify-content: center; align-items: center"><div class="loading-huge jello-horizontal"></div></div>
            {:else if audioLoaded}
            <div class="file-upload-done">{@html doneIcon}</div>
            {:else}
        <!-- let showAudio = false;
        let loadingAudio = false;
        let audioLoaded = false; -->
              <input
                type="file"
                class="user--exercise-builder-lottie-upload"
                style="display: none;"
                id="audioUploadAttachment"
                on:change={(e) => handleAudioUpload()}
              />
              <div class="image-loader-static" style="display: none;"></div>
              <div class="user--class-exercise-additional-butons-wrap">
                <label
                  class="exercise-builder-add-icon"
                  for={`audioUploadAttachment`}>{@html addIcon2}</label
                >
              </div>
              {/if}


        </div>
    {/if}
    {#if showVideo}
        <!-- LOADER: <div class="loading-huge jello-horizontal"></div> -->
        <div class="attachment-wrap">
            {#if loadingVideo}
            <div style="width: 100%; height: max-content; display: flex; justify-content: center; align-items: center"><div class="loading-huge jello-horizontal"></div></div>
            {:else if videoLoaded}
            <div class="file-upload-done">{@html doneIcon}</div>
            {:else}
        <!-- let showAudio = false;
        let loadingAudio = false;
        let audioLoaded = false; -->
              <input
                type="file"
                class="user--exercise-builder-lottie-upload"
                style="display: none;"
                id="videoUploadAttachment"
                on:change={(e) => handleVideoUpload()}
              />
              <div class="image-loader-static" style="display: none;"></div>
              <div class="user--class-exercise-additional-butons-wrap">
                <label
                  class="exercise-builder-add-icon"
                  for={`videoUploadAttachment`}>{@html addIcon2}</label
                >
              </div>
              {/if}


        </div>
    {/if}
    {#if showImage}
        <!-- LOADER: <div class="loading-huge jello-horizontal"></div> -->
        <div class="attachment-wrap">
            {#if loadingImage}
            <div style="width: 100%; height: max-content; display: flex; justify-content: center; align-items: center"><div class="loading-huge jello-horizontal"></div></div>
            {:else if imageLoaded}
            <div class="file-upload-done">{@html doneIcon}</div>
            {:else}
        <!-- let showAudio = false;
        let loadingAudio = false;
        let audioLoaded = false; -->
              <input
                type="file"
                class="user--exercise-builder-lottie-upload"
                style="display: none;"
                id="imageUploadAttachment"
                on:change={(e) => handleImageUpload()}
              />
              <div class="image-loader-static" style="display: none;"></div>
              <div class="user--class-exercise-additional-butons-wrap">
                <label
                  class="exercise-builder-add-icon"
                  for={`imageUploadAttachment`}>{@html addIcon2}</label
                >
              </div>
              {/if}


        </div>
    {/if}
    {#if showCharacter}
    <div class="">
        {#each $exerciseStore.chars as char, index}
          <div class="character-inner" key={index}>
            <input
              class="character-inner-input"
              bind:value={$exerciseStore.chars[index]}
              placeholder="Введите иероглиф"
            />
            <div
              class="user--class-generic-button-3-tiny"
              on:click={() => handleCharCheck(index)}
            >
              Проверка написания
            </div>
          </div>
        {/each}
        <div
          class="user--class-generic-button-3-tiny"
          on:click={addCharacter}
        >
          Добавить иероглиф
        </div>
      </div>
    {/if}
    {#if showPdf}
        <!-- LOADER: <div class="loading-huge jello-horizontal"></div> -->
        <div class="attachment-wrap">
            {#if loadingPdf}
            <div style="width: 100%; height: max-content; display: flex; justify-content: center; align-items: center"><div class="loading-huge jello-horizontal"></div></div>
            {:else if pdfLoaded}
            <div class="file-upload-done">{@html doneIcon}</div>
            {:else}
        <!-- let showAudio = false;
        let loadingAudio = false;
        let audioLoaded = false; -->
              <input
                type="file"
                class="user--exercise-builder-lottie-upload"
                style="display: none;"
                id="pdfUploadAttachment"
                on:change={(e) => handlePdfUpload()}
              />
              <div class="image-loader-static" style="display: none;"></div>
              <div class="user--class-exercise-additional-butons-wrap">
                <label
                  class="exercise-builder-add-icon"
                  for={`pdfUploadAttachment`}>{@html addIcon2}</label
                >
              </div>
              {/if}


        </div>
    {/if}
</div>
</div>
</div>

<style>

.course-selector {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 90%;
    margin: 0 auto;
    padding: 30px;
    border: 2px solid #d9d9d9;
    border-radius: 40px;
  }
  .course-selector-inner-btn {
    width: max-content;
    height: max-content;
    padding-block: 10px;
    padding-inline: 20px;
    font-size: 20px;
    font-weight: 400;
    background-color: #0c5ca450;
  }
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  .attachment-wrapper {
    width: 100%;
    grid-column: 1 / -1;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  .attachment-wrap, .character-block {
    width: 100%;
    height: max-content;
    padding: 20px;
    border: 2px solid #0c5ca420;
    border-radius: 30px;
  }
  .character-block {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
  }
  .file-upload-done {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .character-inner {
    width: 40%;
    height: max-content;
    padding: 10px;
    border-radius: 20px;
    border: 2px solid #0c5ca420;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .character-inner-input {
    width: 100%;
    height: max-content;
    min-height: 60px;
    padding: 5px;
    border-radius: 50px;
    border: 1px solid #0c5ca420;
    color: #5c5c5c;
    font-weight: 350;
    font-size: 20px;
    text-align: center;
  }

  .user--block-expanded-wrapper-modified {
        width: 100%;
    margin: 0 auto;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 20px;
    background-color: #ffffff;
    border-radius: 30px;
    border: 2px solid #7fa3cd;
    /* box-shadow: rgba(0, 0, 0,0.1) 0px 0px 8px 4px; */
    gap: 40px;
    flex-direction: column;
    border: aliceblue;
      }
      @media (max-width: 750px) { 
        .user--block-expanded-wrapper-modified {
          padding: 0px;
          margin-block: 0px;
          gap: 20px;
        }
        .course-selector {
      grid-template-columns: 1fr;
      width: 100%;
    }
      }
</style>

