<script>
  import { get } from "svelte/store";
  import { createEventDispatcher } from "svelte";
  import Nav from "./Nav.svelte";

  export let totalItems;
  export let itemsPerPage;
  export let currentPage;
  export let onPageChange;

  const dispatch = createEventDispatcher();

  const backIconInactive = `<svg style="width: 100%; height: 100%" viewBox="0 0 84 84" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M41.8486 79.871C62.8478 79.871 79.8709 62.8478 79.8709 41.8486C79.8709 20.8495 62.8478 3.82632 41.8486 3.82632C20.8495 3.82631 3.82631 20.8495 3.8263 41.8486C3.8263 62.8478 20.8495 79.8709 41.8486 79.871ZM41.8486 83.6973C64.961 83.6973 83.6972 64.961 83.6972 41.8486C83.6973 18.7363 64.961 1.13581e-05 41.8486 7.31705e-06C18.7363 3.27595e-06 -3.27595e-06 18.7363 -7.31705e-06 41.8486C-1.13581e-05 64.961 18.7363 83.6973 41.8486 83.6973Z" fill="#DFDFDF"/>
<path d="M25.2326 39.6895L61.3693 39.6895C61.8485 39.6895 62.3081 39.8799 62.647 40.2188C62.9857 40.5575 63.1762 41.0171 63.1762 41.4963C63.1762 41.9755 62.9857 42.4352 62.647 42.7739C62.3081 43.1128 61.8485 43.3032 61.3693 43.3032L25.2326 43.3032C24.7534 43.3032 24.2938 43.1128 23.955 42.7739C23.6162 42.4351 23.4258 41.9755 23.4258 41.4963C23.4258 41.0171 23.6162 40.5575 23.955 40.2188C24.2938 39.8799 24.7534 39.6895 25.2326 39.6895Z" fill="#DFDFDF"/>
<path d="M25.9821 41.4964L40.9681 56.4787C41.3073 56.8179 41.4978 57.2781 41.4978 57.7579C41.4978 58.2378 41.3073 58.6978 40.9681 59.0372C40.6287 59.3764 40.1686 59.567 39.6888 59.567C39.209 59.567 38.7488 59.3764 38.4096 59.0372L22.148 42.7756C21.9797 42.6077 21.8463 42.4084 21.7552 42.189C21.6641 41.9694 21.6172 41.7341 21.6172 41.4964C21.6172 41.2588 21.6641 41.0234 21.7552 40.8039C21.8463 40.5844 21.9797 40.385 22.148 40.2171L38.4096 23.9557C38.7488 23.6164 39.209 23.4258 39.6888 23.4258C40.1686 23.4258 40.6287 23.6164 40.9681 23.9557C41.3073 24.2949 41.4979 24.7551 41.4979 25.2349C41.4979 25.7147 41.3073 26.1749 40.9681 26.5141L25.9821 41.4964Z" fill="#DFDFDF"/>
</svg>`;

  const backIconActive = `<svg style="width: 100%; height: 100%" viewBox="0 0 84 84" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M41.8486 79.871C62.8478 79.871 79.8709 62.8478 79.8709 41.8486C79.8709 20.8495 62.8478 3.82632 41.8486 3.82632C20.8495 3.82631 3.82631 20.8495 3.8263 41.8486C3.8263 62.8478 20.8495 79.8709 41.8486 79.871ZM41.8486 83.6973C64.961 83.6973 83.6972 64.961 83.6972 41.8486C83.6973 18.7363 64.961 1.13581e-05 41.8486 7.31705e-06C18.7363 3.27595e-06 -3.27595e-06 18.7363 -7.31705e-06 41.8486C-1.13581e-05 64.961 18.7363 83.6973 41.8486 83.6973Z" fill="#85ADD1"/>
<path d="M25.2326 39.6895L61.3693 39.6895C61.8485 39.6895 62.3081 39.8799 62.647 40.2188C62.9857 40.5575 63.1762 41.0171 63.1762 41.4963C63.1762 41.9755 62.9857 42.4352 62.647 42.7739C62.3081 43.1128 61.8485 43.3032 61.3693 43.3032L25.2326 43.3032C24.7534 43.3032 24.2938 43.1128 23.955 42.7739C23.6162 42.4351 23.4258 41.9755 23.4258 41.4963C23.4258 41.0171 23.6162 40.5575 23.955 40.2188C24.2938 39.8799 24.7534 39.6895 25.2326 39.6895Z" fill="#85ADD1"/>
<path d="M25.9821 41.4964L40.9681 56.4787C41.3073 56.8179 41.4978 57.2781 41.4978 57.7579C41.4978 58.2378 41.3073 58.6978 40.9681 59.0372C40.6287 59.3764 40.1686 59.567 39.6888 59.567C39.209 59.567 38.7488 59.3764 38.4096 59.0372L22.148 42.7756C21.9797 42.6077 21.8463 42.4084 21.7552 42.189C21.6641 41.9694 21.6172 41.7341 21.6172 41.4964C21.6172 41.2588 21.6641 41.0234 21.7552 40.8039C21.8463 40.5844 21.9797 40.385 22.148 40.2171L38.4096 23.9557C38.7488 23.6164 39.209 23.4258 39.6888 23.4258C40.1686 23.4258 40.6287 23.6164 40.9681 23.9557C41.3073 24.2949 41.4979 24.7551 41.4979 25.2349C41.4979 25.7147 41.3073 26.1749 40.9681 26.5141L25.9821 41.4964Z" fill="#85ADD1"/>
</svg>`;

  const nextIconInactive = `<svg style="width: 100%; height: 100%" viewBox="0 0 84 84" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M41.8467 3.82632C20.8475 3.82632 3.82437 20.8495 3.82437 41.8486C3.82437 62.8478 20.8475 79.8709 41.8467 79.871C62.8458 79.871 79.869 62.8478 79.869 41.8486C79.869 20.8495 62.8458 3.82632 41.8467 3.82632ZM41.8467 6.97965e-06C18.7343 4.95911e-06 -0.00193681 18.7363 -0.00193883 41.8486C-0.00194085 64.961 18.7343 83.6973 41.8467 83.6973C64.959 83.6973 83.6953 64.961 83.6953 41.8486C83.6953 18.7363 64.9591 9.0002e-06 41.8467 6.97965e-06Z" fill="#DFDFDF"/>
<path d="M58.4627 44.0078L22.326 44.0078C21.8468 44.0078 21.3872 43.8174 21.0483 43.4785C20.7096 43.1398 20.5192 42.6801 20.5192 42.2009C20.5192 41.7217 20.7096 41.2621 21.0483 40.9234C21.3872 40.5845 21.8468 40.3941 22.326 40.3941L58.4627 40.3941C58.9419 40.3941 59.4015 40.5845 59.7403 40.9234C60.0792 41.2621 60.2695 41.7217 60.2695 42.2009C60.2695 42.6801 60.0792 43.1398 59.7403 43.4785C59.4015 43.8174 58.9419 44.0078 58.4627 44.0078Z" fill="#DFDFDF"/>
<path d="M57.7132 42.2009L42.7272 27.2186C42.388 26.8794 42.1975 26.4191 42.1975 25.9393C42.1975 25.4595 42.388 24.9994 42.7272 24.6601C43.0666 24.3209 43.5267 24.1303 44.0065 24.1303C44.4863 24.1303 44.9465 24.3209 45.2858 24.6601L61.5473 40.9216C61.7156 41.0895 61.8491 41.2889 61.9401 41.5083C62.0312 41.7278 62.0781 41.9631 62.0781 42.2009C62.0781 42.4385 62.0312 42.6739 61.9401 42.8933C61.8491 43.1129 61.7156 43.3122 61.5473 43.4801L45.2857 59.7416C44.9465 60.0809 44.4863 60.2715 44.0065 60.2715C43.5267 60.2715 43.0666 60.0809 42.7272 59.7416C42.388 59.4023 42.1975 58.9422 42.1975 58.4624C42.1975 57.9826 42.388 57.5224 42.7272 57.1831L57.7132 42.2009Z" fill="#DFDFDF"/>
</svg>`;

  const nextIconActive = `<svg style="width: 100%; height: 100%" viewBox="0 0 84 84" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M41.8467 3.82632C20.8475 3.82632 3.82437 20.8495 3.82437 41.8486C3.82437 62.8478 20.8475 79.8709 41.8467 79.871C62.8458 79.871 79.869 62.8478 79.869 41.8486C79.869 20.8495 62.8458 3.82632 41.8467 3.82632ZM41.8467 6.97965e-06C18.7343 4.95911e-06 -0.00193681 18.7363 -0.00193883 41.8486C-0.00194085 64.961 18.7343 83.6973 41.8467 83.6973C64.959 83.6973 83.6953 64.961 83.6953 41.8486C83.6953 18.7363 64.9591 9.0002e-06 41.8467 6.97965e-06Z" fill="#85ADD1"/>
<path d="M58.4627 44.0078L22.326 44.0078C21.8468 44.0078 21.3872 43.8174 21.0483 43.4785C20.7096 43.1398 20.5192 42.6801 20.5192 42.2009C20.5192 41.7217 20.7096 41.2621 21.0483 40.9234C21.3872 40.5845 21.8468 40.3941 22.326 40.3941L58.4627 40.3941C58.9419 40.3941 59.4015 40.5845 59.7403 40.9234C60.0792 41.2621 60.2695 41.7217 60.2695 42.2009C60.2695 42.6801 60.0792 43.1398 59.7403 43.4785C59.4015 43.8174 58.9419 44.0078 58.4627 44.0078Z" fill="#85ADD1"/>
<path d="M57.7132 42.2009L42.7272 27.2186C42.388 26.8794 42.1975 26.4191 42.1975 25.9393C42.1975 25.4595 42.388 24.9994 42.7272 24.6601C43.0666 24.3209 43.5267 24.1303 44.0065 24.1303C44.4863 24.1303 44.9465 24.3209 45.2858 24.6601L61.5473 40.9216C61.7156 41.0895 61.8491 41.2889 61.9401 41.5083C62.0312 41.7278 62.0781 41.9631 62.0781 42.2009C62.0781 42.4385 62.0312 42.6739 61.9401 42.8933C61.8491 43.1129 61.7156 43.3122 61.5473 43.4801L45.2857 59.7416C44.9465 60.0809 44.4863 60.2715 44.0065 60.2715C43.5267 60.2715 43.0666 60.0809 42.7272 59.7416C42.388 59.4023 42.1975 58.9422 42.1975 58.4624C42.1975 57.9826 42.388 57.5224 42.7272 57.1831L57.7132 42.2009Z" fill="#85ADD1"/>
</svg>`;

  let totalPages;
  $: totalPages = Math.ceil(totalItems / itemsPerPage);
  $: pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    currentPage.set(page);
    onPageChange(page);
  };
</script>

{#if totalPages > 1}
  <div class="pagination">
    <div
      class="pagination-icon-interactive {$currentPage === 1
        ? 'disabled-icon'
        : ''}"
      on:click={() => handlePageChange($currentPage - 1)}
    >
      {#if $currentPage === 1}
        {@html backIconInactive}
      {:else}
        {@html backIconActive}
      {/if}
    </div>
    <div class="pagination-wrapper">
      {#each pages as page}
        <button
          class={$currentPage === page ? "active-page" : ""}
          on:click={() => handlePageChange(page)}
        >
          {page}
        </button>
      {/each}
    </div>
    <div
      class="pagination-icon-interactive {$currentPage === totalPages
        ? 'disabled-icon'
        : ''}"
      on:click={() => handlePageChange($currentPage + 1)}
    >
      {#if $currentPage === totalPages}
        {@html nextIconInactive}
      {:else}
        {@html nextIconActive}
      {/if}
    </div>
  </div>
{/if}

<style>
  .pagination {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  .pagination button {
    border: none;
    background: #ffffff;
    font-size: 30px;
    font-weight: 800;
    cursor: pointer;
    color: #85add1;
  }
  .disabled-icon {
    pointer-events: none !important;
  }
  .pagination button.active {
    color: #85add1;
  }
  .pagination button:disabled {
    /* cursor: not-allowed; */
    pointer-events: none;
    color: #dfdfdf;
  }
  .pagination-wrapper {
    width: max-content;
    height: max-content;
    max-width: 80%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 15px;
    border-radius: 50px;
    border: 3px solid #dfdfdf;
    background-color: transparent;
  }
  .active-page {
    color: #dfdfdf !important;
    pointer-events: none !important;
  }
  .pagination-icon-interactive {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
</style>
