<script>
  import { fly, blur } from "svelte/transition";
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { isAuthorized, userStore, API_BASE_URL } from "../store.js";
  import { translate } from "../translation";
  import { currentSection } from "../store.js";
  import axios from "axios";
  import Cookies from "js-cookie";
  import { writable } from "svelte/store";
  import PushNotification from "./PushNotification.svelte";
  let flashcardsObject = writable({});
  let sectionNumber = 1;
  let section = writable(1);
  let pinyinVisible = writable(false);

  // additionalFunctionalityState
  let showTranslation = writable(false);
  let showSentence = writable(false);
  let showImage = writable(false);

  function cleanup() {
    showTranslation.set(false);
    showSentence.set(false);
    showImage.set(false);
  }
  function selectFunction(func) {
    switch (func) {
      case "translation":
        showTranslation.update((value) => !value);
        break;
      case "sentence":
        showSentence.update((value) => !value);
        break;
      case "image":
        showImage.update((value) => !value);
        break;
    }
  }
  function selectSection(sec) {
    section.set(sec);
    cleanup();
    fetchCards();
  }

  let notifications = [];
  function addNotification(header, message, type = "info", duration = 3000) {
    notifications = [...notifications, { header, message, type, duration }];
    setTimeout(() => {
      notifications = notifications.slice(1);
    }, duration);
  }

  function navigateTo(section) {
    push(`/${section}`);
  }

  // Assets

  const leftIcon = `<svg style="width: 100%; height: 100%" viewBox="0 0 58 58" fill="none" >
<path d="M1.10789 51.5429C-0.369297 53.0202 -0.369297 55.4148 1.10789 56.8921C2.58508 58.3693 4.98013 58.3693 6.45732 56.8921L1.10789 51.5429ZM31.6746 31.6746C33.1519 30.1974 33.1519 27.8027 31.6746 26.3255C30.1974 24.8483 27.8027 24.8483 26.3255 26.3255L31.6746 31.6746ZM26.3255 26.3255C24.8483 27.8027 24.8483 30.1974 26.3255 31.6746C27.8027 33.1519 30.1974 33.1519 31.6746 31.6746L26.3255 26.3255ZM56.8921 6.45732C58.3693 4.98013 58.3693 2.58508 56.8921 1.10789C55.4148 -0.369297 53.0202 -0.369297 51.5429 1.10789L56.8921 6.45732ZM31.6746 26.3255C30.1974 24.8483 27.8027 24.8483 26.3255 26.3255C24.8483 27.8027 24.8483 30.1974 26.3255 31.6746L31.6746 26.3255ZM51.5429 56.8921C53.0202 58.3693 55.4148 58.3693 56.8921 56.8921C58.3693 55.4148 58.3693 53.0202 56.8921 51.5429L51.5429 56.8921ZM26.3255 31.6746C27.8027 33.1519 30.1974 33.1519 31.6746 31.6746C33.1519 30.1974 33.1519 27.8027 31.6746 26.3255L26.3255 31.6746ZM6.45732 1.10789C4.98013 -0.369297 2.58508 -0.369297 1.10789 1.10789C-0.369297 2.58508 -0.369297 4.98013 1.10789 6.45732L6.45732 1.10789ZM6.45732 56.8921L31.6746 31.6746L26.3255 26.3255L1.10789 51.5429L6.45732 56.8921ZM31.6746 31.6746L56.8921 6.45732L51.5429 1.10789L26.3255 26.3255L31.6746 31.6746ZM26.3255 31.6746L51.5429 56.8921L56.8921 51.5429L31.6746 26.3255L26.3255 31.6746ZM31.6746 26.3255L6.45732 1.10789L1.10789 6.45732L26.3255 31.6746L31.6746 26.3255Z" fill="#E08893"/>
</svg>`;

  const rightIcon = `<svg style="width: 100%; height: 100%" viewBox="0 0 86 58" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M84.8341 1.2671C86.4294 2.91411 86.3816 5.53678 84.7273 7.12502L32.9442 56.8393C31.3322 58.3868 28.7792 58.3869 27.1672 56.8395L1.2729 31.9823C-0.381513 30.3942 -0.429505 27.7715 1.16571 26.1244C2.76093 24.4773 5.39527 24.4295 7.04969 26.0177L30.0555 48.102L78.9502 1.1607C80.6045 -0.427536 83.2388 -0.379898 84.8341 1.2671Z" fill="#4CBB9A"/>
</svg>
`;
  const togglerIcon = `<svg style="width: 100%; height: 100%" viewBox="0 0 89 62" fill="none">
<path d="M53.3492 30.9814C48.5027 30.9814 44.497 26.9758 44.497 22.1292C44.497 20.5624 44.9529 19.1062 45.6832 17.8226C45.2849 17.7916 44.9042 17.7031 44.497 17.7031C40.9826 17.7263 37.6187 19.1327 35.1335 21.6179C32.6484 24.103 31.2419 27.467 31.2188 30.9814C31.2188 38.2491 37.2294 44.2597 44.497 44.2597C51.7603 44.2597 57.7753 38.2491 57.7753 30.9814C57.7753 30.5742 57.6868 30.1936 57.6558 29.7952C56.3723 30.5255 54.9161 30.9814 53.3492 30.9814Z" fill="#85ADD1"/>
<path d="M44.5 0C10.7156 0 0.562115 29.2875 0.469167 29.584L0 30.9827L0.464741 32.3813C0.562115 32.6779 10.7156 61.9654 44.5 61.9654C78.2844 61.9654 88.4379 32.6779 88.5308 32.3813L89 30.9827L88.5353 29.584C88.4379 29.2875 78.2844 0 44.5 0ZM44.5 53.1132C20.8159 53.1132 11.6406 36.0904 9.41874 30.9827C11.6495 25.8573 20.8292 8.8522 44.5 8.8522C68.1841 8.8522 77.3594 25.875 79.5813 30.9827C77.3505 36.1081 68.1708 53.1132 44.5 53.1132Z" fill="#85ADD1"/>
</svg>`;
  const togglerToggledIcon = `<svg style="width: 100%; height: 100%" viewBox="0 0 89 70" fill="none">
<path d="M53.3492 33.9814C48.5027 33.9814 44.497 29.9758 44.497 25.1292C44.497 23.5624 44.9529 22.1062 45.6832 20.8226C45.2849 20.7916 44.9042 20.7031 44.497 20.7031C40.9826 20.7263 37.6187 22.1327 35.1335 24.6179C32.6484 27.103 31.2419 30.467 31.2188 33.9814C31.2188 41.2491 37.2294 47.2597 44.497 47.2597C51.7603 47.2597 57.7753 41.2491 57.7753 33.9814C57.7753 33.5742 57.6868 33.1936 57.6558 32.7952C56.3723 33.5255 54.9161 33.9814 53.3492 33.9814Z" fill="#85ADD1"/>
<path d="M44.5 3C10.7156 3 0.562115 32.2875 0.469167 32.584L0 33.9827L0.464741 35.3813C0.562115 35.6779 10.7156 64.9654 44.5 64.9654C78.2844 64.9654 88.4379 35.6779 88.5308 35.3813L89 33.9827L88.5353 32.584C88.4379 32.2875 78.2844 3 44.5 3ZM44.5 56.1132C20.8159 56.1132 11.6406 39.0904 9.41874 33.9827C11.6495 28.8573 20.8292 11.8522 44.5 11.8522C68.1841 11.8522 77.3594 28.875 79.5813 33.9827C77.3505 39.1081 68.1708 56.1132 44.5 56.1132Z" fill="#85ADD1"/>
<path d="M12.0625 3.9375L74.0625 65.9375" stroke="#6095C5" stroke-width="7" stroke-linecap="round"/>
</svg>
`;

  function togglePinyinVisibility() {
    pinyinVisible.update((value) => !value);
  }

  async function flashcardProceed(status) {
    flashcardsLoading.set(true);

    try {
      // Prepare the payload for the POST request
      const payload = {
        token: $userStore.token,
        wordId: $flashcardsObject.wordId,
        status: status,
      };

      // Send the POST request to the server
      const response = await axios.post(
        `${$API_BASE_URL}/processWord`,
        payload
      );

      // checking the response first
      // console.log(response.data);
      // Check if the response is successful
      if (response.data.status === 1) {
        // console.log("Flashcard processed successfully.");

        cleanup();
        fetchCards();
      } else {
        console.error(
          "Failed to process flashcard:",
          response.data.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error processing flashcard:", error);
    }
  }
  let flashcardsLoading = writable(true);

  async function fetchCards() {
    try {
      // Prepare the payload for the POST request
      const payload = {
        section: $section,
        token: $userStore.token,
      };

      // Send the POST request to the server
      const response = await axios.post(
        `${$API_BASE_URL}/fetchWord`,
        payload
      );
      // Check if the response status is success
      if (response.data.status === 1) {
        // Update the Svelte store with the fetched card contents
        flashcardsObject.set(response.data.cardContents);
        // console.log("Fetched cards:", response.data.cardContents);
      } else {
        // console.error(
        //   "Failed to fetch cards:",
        //   response.data.message || "Unknown error"
        // );
      }
    } catch (error) {
      console.error("Error fetching cards:", error);
    } finally {
      flashcardsLoading.set(false);
    }
  }

  onMount(() => {
    fetchCards();
  });
</script>

{#each notifications as { header, message, type, duration }}
  <PushNotification {header} {message} {type} {duration} />
{/each}

<!-- Step 4: Section selection -->
<div class="section-selector" style="margin: 0 auto;">
  <div
    class="section-select-button {$section === 1 ? 'active' : ''}"
    on:click={() => selectSection(1)}
  >
    Новые
  </div>
  <div
    class="section-select-button {$section === 2 ? 'active' : ''}"
    on:click={() => selectSection(2)}
  >
    Повторение
  </div>
</div>

<div class="user--block-expanded-wrapper">
  {#if $flashcardsLoading}
    <div class="loading-huge jello-horizontal"></div>
  {:else if $flashcardsObject && Object.keys($flashcardsObject).length > 0}
    <div class="card-main-wrapper">
      <div class="card-leftright-buttons">
        <div class="card-left-button" on:click={() => flashcardProceed(0)}>
          <div class="card-button-icon">{@html leftIcon}</div>
          Не помню
        </div>
        <div class="card-right-button" on:click={() => flashcardProceed(1)}>
          Помню
          <div class="card-button-icon card-button-icon-right">
            {@html rightIcon}
          </div>
        </div>
      </div>
      <div class="word-wrapper">
        <div class="word-main-wrapper">
          {$flashcardsObject.word}
        </div>
        {#if $flashcardsObject.pinyin && !$pinyinVisible}
          <div class="visibility-toggler" on:click={togglePinyinVisibility}>
            {@html togglerIcon}
          </div>
        {/if}
        {#if $flashcardsObject.pinyin && $pinyinVisible}
          <div class="pinyin">{$flashcardsObject.pinyin}</div>
          <div class="visibility-toggler" on:click={togglePinyinVisibility}>
            {@html togglerToggledIcon}
          </div>
        {/if}
      </div>
      <div class="additional-functionality-buttons">
        {#if $flashcardsObject.translation}
          <div
            class="user--class-generic-button-3 {$showTranslation
              ? 'button-selected'
              : ''}"
            on:click={() => selectFunction("translation")}
          >
            Перевод
          </div>
        {/if}
        {#if $flashcardsObject.sentence}
          <div
            class="user--class-generic-button-3 {$showSentence
              ? 'button-selected'
              : ''}"
            on:click={() => selectFunction("sentence")}
          >
            Пример
          </div>
        {/if}
        <!-- {#if $flashcardsObject.association}
          <div
            class="user--class-generic-button-3 {$showImage
              ? 'button-selected'
              : ''}"
            on:click={() => selectFunction("image")}
          >
            Изображение
          </div>
        {/if} -->
      </div>
      <div class="functionality-block">
        {#if $showTranslation}
          <div class="translation-block">{$flashcardsObject.translation}</div>
        {/if}
        {#if $showSentence}
          <div class="sentence-block">{$flashcardsObject.sentence}</div>
        {/if}
        {#if $showImage}
          <img
            src={$flashcardsObject.association}
            alt="Изображение к слову"
            class="image-block"
          />
        {/if}
      </div>
    </div>
  {:else}
    <span class="null-contents-display"
      >Доступных для повторения слов не найдено</span
    >
  {/if}
</div>

<style>
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  .null-contents-display {
    font-size: 23px;
    font-weight: 350;
    color: #5c5c5c;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
  .button-selected {
    background-color: #0c5ca4;
    color: #ffffff;
  }
  .section-selector {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: max-content;
    height: max-content;
    padding: 10px;
    border-radius: 50px;
    background-color: #0c5ca420;
  }
  .slide-selected {
    border: 3px solid #0c5ca4;
  }
  .section-select-button {
    width: max-content;
    height: max-content;
    padding-block: 15px;
    padding-inline: 25px;
    background-color: #ffffff;
    color: rgba(12, 85, 152, 0.8);
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    border-radius: 100px;
    margin: 0 auto;
    transition: all 0.2s linear;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .active {
    background-color: #0c5ca460;
    color: #ffffff;
  }
  .section-select-button:hover {
    background-color: #0c5ca460;
    color: #ffffff;
  }
  .card-main-wrapper {
    width: 100%;
    height: max-content;
    gap: 20px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
  }
  .card-leftright-buttons {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .card-left-button {
    background-color: #e0889350;
    color: #ffffff;
  }
  .card-left-button:hover {
    background-color: #e08893;
  }
  .card-right-button {
    background-color: #4cbb9a50;
    color: #ffffff;
  }
  .card-right-button:hover {
    background-color: #4cbb9a;
  }
  .card-button-icon {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-button-icon-right {
    width: 25px;
    height: 25px;
  }
  .card-left-button,
  .card-right-button {
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    align-items: center;
    padding-block: 15px;
    padding-inline: 25px;
    cursor: pointer;
    font-size: 23px;
    font-weight: 500;
    border-radius: 50px;
    transition: all 0.2s linear;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .word-main-wrapper {
    width: max-content;
    height: max-content;
    margin: 0 auto;
    font-size: 100px;
    font-weight: 900;
    color: #000000;
  }
  .visibility-toggler {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .additional-functionality-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 60%;
    margin: 0 auto;
    padding: 30px;
    border: 2px solid #0c5ca450;
    border-radius: 40px;
  }
  .functionality-block {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    width: 60%;
    margin: 0 auto;
    padding: 30px;
    /* border: 2px solid #0c5ca450; */
    border-radius: 40px;
  }
  .word-wrapper {
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }
  .translation-block,
  .sentence-block {
    width: 100%;
    height: max-content;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 23px;
    font-weight: 350;
    color: #5c5c5c;
    border-radius: 30px;
    border: 3px solid #0c5ca450;
  }
  .image-block {
    width: 300px;
    height: auto;
    border-radius: 40px;
    border: 3px solid #0c5ca450;
  }
  .pinyin {
    font-size: 20px;
    font-weight: 350;
    color: #5c5c5c;
  }
  @media (max-width: 750px) {
    .card-left-button,
    .card-right-button {
      font-size: 20px;
      gap: 10px;
    }
    .card-button-icon {
      width: 18px;
      height: 18px;
    }
    .additional-functionality-buttons {
      grid-template-columns: 1fr;
      width: 100%;
    }
    .translation-block,
    .sentence-block {
      width: 100%;
    }
  }
</style>
