<script>
  import { fly, blur } from "svelte/transition";
  import FeedbackButton from "./FeedbackButton.svelte";
  import { onMount } from "svelte";
  export let role;
  export let data;

  console.log("exported role and data: ", role, data);
  let tableWrap;
  let pdfContainer;
  let loader;

  // another style decision

  function setCustomBackground(element, color) {
    element.style.backgroundColor = color;
  }

  $: if (role === "tableBlock" && data) {
    const {
      design,
      cellContent: cellData,
      customBackground,
      tableName,
      outlineTableHide,
      doNotBold
    } = data;
    if (tableWrap) {
      // Apply custom background
      if (customBackground) {
        setCustomBackground(tableWrap, customBackground);
      }

      // Apply outline table hide
      if (outlineTableHide === "false") {
        tableWrap.classList.add("user--class-table-table-outline");
      }
      if (doNotBold === "true") {
        const table = tableWrap.querySelector("table.user--class-table-table");

    if (table) {
       
        const firstRow = table.querySelector("tr");

        if (firstRow) {
            firstRow.querySelectorAll("td, th").forEach((cell) => {
                cell.style.fontWeight = "normal";
            });
        }
    }
      }
    }
  }

  function pdfSlide() {
    console.log("inside the pdfSlide function");
    console.log(data);
    if (!data.pdfLink) {
      console.error("PDF link is not provided.");
      loader.remove();
      pdfContainer.remove();
      return;
    }
    // Uncomment if you have loader icon
    // loader.innerHTML = loadIcon2;
    // loader.classList.add('spin');
    pdfContainer.appendChild(loader);

    const loadingTask = pdfjsLib.getDocument(data.pdfLink);

    loadingTask.promise
      .then((pdfDocument) => {
        console.log("pdfContainer? ", pdfContainer);
        pdfContainer.innerHTML = "";
        pdfContainer.style.height = "auto";

        let pdfHeight = 0;
        const scale = 1;
        const devicePixelRatio = window.devicePixelRatio || 1;

        for (
          let pageNumber = 1;
          pageNumber <= pdfDocument.numPages;
          pageNumber++
        ) {
          pdfDocument.getPage(pageNumber).then((page) => {
            const viewport = page.getViewport({ scale });
            const containerWidth = pdfContainer.offsetWidth;
            const pageWidthScale = containerWidth / viewport.width;
            const scaledViewport = page.getViewport({
              scale: pageWidthScale * devicePixelRatio,
            });

            const canvas = document.createElement("canvas");
            canvas.width = scaledViewport.width;
            canvas.height = scaledViewport.height;

            const canvasContext = canvas.getContext("2d");
            canvasContext.imageSmoothingEnabled = false;
            const renderContext = {
              canvasContext,
              viewport: scaledViewport,
            };
            page.render(renderContext).promise.then(() => {
              canvas.style.width = `${scaledViewport.width / devicePixelRatio}px`;
              canvas.style.height = `${scaledViewport.height / devicePixelRatio}px`;
              canvas.style.borderRadius = "25px";

              pdfContainer.appendChild(canvas);
              pdfHeight += scaledViewport.height / devicePixelRatio;

              if (pdfContainer.childElementCount === pdfDocument.numPages) {
                pdfContainer.style.height = `${pdfHeight}px`;
                loader.remove();
              }
            });
          });
        }
      })
      .catch((error) => {
        // Uncomment if you have loader text
        // loader.innerText = 'Error loading PDF.';
        console.error("PDF loading error:", error);
      });
  }

  onMount(() => {
    if (role === "pdfSlide") {
      pdfSlide();
    }
  });
</script>

{#if role === "header"}
  <div
    class="user-class-header-wrap"
    style="background: {data.backgroundColor}"
  >
    {@html data.textContent}
  </div>
{:else if role === "lottie"}
  <dotlottie-player
    src={data.lottieLink}
    background="transparent"
    style="width: 400px; height: 400px; margin: 0 auto;"
    loop
    autoplay
    mode="normal"
  ></dotlottie-player>
{:else if role === "contentImageBlock"}
  <div
    class="user--class-content-image-wrap"
    style="background: {data.customBackground}"
  >
    {#if data.textContent}
      <span>{@html data.textContent}</span>
    {/if}
    {#if data.image}
      <img src={data.image} alt="Content Image" />
    {/if}
  </div>
{:else if role === "audioBlock"}
  <div id="listening-player-{data.id}">
    <!-- Audio player logic can go here -->
  </div>
{:else if role === "createTable"}
  <div
    class="user--class-content-image-wrap"
    style="background: {data.customBackground}"
  >
    {#if data.tableName}
      <div class="user--class-table-table-caption">{data.tableName}</div>
    {/if}
    <table
      class="user--class-table-table {data.outlineTableHide
        ? ''
        : 'user--class-table-table-outline'}"
    >
      <tbody>
        {#each Array(data.design.rows).fill(0) as _, rowIndex}
          <tr>
            {#each Array(data.design.columns).fill(0) as _, colIndex}
              <td style="font-weight: {rowIndex === 0 ? 'bold' : 'normal'}">
                {@html data.cellData[rowIndex] &&
                  data.cellData[rowIndex][colIndex]}
              </td>
            {/each}
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
{:else if role === "itemsGrid"}
  <div class="user--class-items-grid-wrap-main">
    {#each data.items as item}
      <div class="user--exercise-item-wrapper-additional">
        {#if item.image}
          <img src={item.image} class="user--gridblock-image-wrapper" />
        {/if}
        <p class="user--class-items-grid-additional-description">
          {item.description}
        </p>
        <p
          class="user--class-items-grid-additional-description"
          style="font-weight: 350; color: #D9D9D9;"
        >
          {item.additionalDescription}
        </p>
      </div>
    {/each}
  </div>
{:else if role === "pdfSlide"}
  <div
    class="slide-iframe-pdf"
    bind:this={pdfContainer}
    id="pdfContainer"
  ></div>
  <div class="loading-huge jello-horizontal" bind:this={loader}></div>
{:else if role === "delimeter"}
  <div class="user--class-delimeter"></div>
{:else if role === "contentComplexBlock"}
  <div class="user--class-content-complex-block">
    {#each data.elements as elementType}
      {#if elementType === "text"}
        <span>{@html data.textContent}</span>
      {:else if elementType === "audio"}
        <div id="listening-player-{data.audioId}">
          <!-- Audio player logic -->
        </div>
      {:else if elementType === "video"}
        <div id="video-player">
          <!-- Video player logic -->
        </div>
      {:else if elementType === "image"}
        <div
          class="user--class-content-complex-block-image"
          style="background-image: url({data.imageSrc})"
        ></div>
      {:else if elementType === "scribble"}
        <span class="user--class-scribble-hero">Попробуй сам</span>
        <div class="user--class-scribble-body"></div>
        <div class="user--class-scribble-buttons-wrap">
          <div class="user--class-scrribble-button">Сохранить</div>
          <div class="user--class-scrribble-button">Очистить</div>
        </div>
      {:else if elementType === "table"}
        <table
          class="user--class-table-table {data.tableBlockData.outlineTableHide
            ? ''
            : 'user--class-table-table-outline'}"
        >
          <tbody>
            {#each Array(data.tableBlockData.design.rows).fill(0) as _, rowIndex}
              <tr>
                {#each Array(data.tableBlockData.design.columns).fill(0) as _, colIndex}
                  <td style="font-weight: {rowIndex === 0 ? 'bold' : 'normal'}">
                    {@html data.tableBlockData.cellContent[rowIndex] &&
                      data.tableBlockData.cellContent[rowIndex][colIndex]}
                  </td>
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      {/if}
    {/each}
  </div>
{:else if role === "dataExample"}
  <div class="user--class-content-complex-block">
    {#each data.textContent as blockContent, i}
      <span>{@html blockContent}</span>
      {#if data.example[i]}
        <div class="user--class-example-block">
          {#each data.example[i] as example, j}
            <div class="user--class-example-char-wrap">
              <span class="user--class-example-block-character">{example}</span>
              <span class="user--class-example-block-pinyin"
                >{data.pinyin[i][j]}</span
              >
            </div>
          {/each}
        </div>
      {/if}
    {/each}
  </div>
{:else if role === "blockDialogue"}
  <div class="user--class-content-complex-block-dialogue">
    {#each data.dialogue as dialogueBlock, i}
      <div class="user--class-dialogue-line">
        <span class="user--class-dialogue-line-title"
          >{data.textContent[i]}</span
        >
        <div class="user--class-dialogue-line-wrap-big">
          {#if data.imageArray[i] && data.imageArray[i].length > 0}
            <img
              src={data.imageArray[i]}
              class="user--slidebuilder-content-image"
            />
          {/if}

          <div
            class="user--class-dialogue-line-wrap"
            style="position: relative;"
          >
            {#if Array.isArray(dialogueBlock)}
              {#each dialogueBlock as dialogueLine, j}
                <div class="dialogue-line-outer">
                  <div class="user--class-dialogue-line-separator">
                    <svg viewBox="0 0 24 24"><path d="M23 13H2v-2h21v2z" /></svg
                    >
                  </div>
                  <div class="dialogue-line-inner">
                    <span class="user--class-dialogue-block-character"
                      >{dialogueLine}</span
                    >
                    <span class="user--class-dialogue-block-pinyin"
                      >{data.pinyin[i][j]}</span
                    >
                  </div>
                </div>
              {/each}
            {:else}
              <span class="user--class-dialogue-block-character"
                >{dialogueBlock}</span
              >
              <span class="user--class-dialogue-block-pinyin"
                >{data.pinyin[i]}</span
              >
            {/if}
          </div>
        </div>
      </div>
    {/each}
  </div>
{:else if role === "imageBlock"}
  <div class="user--class-full-image">
    <span>{@html data.textContent}</span>
    {#if data.imgSrc && data.imgSrc != ""}
      <img src={data.imgSrc} alt="Block Image" />
    {/if}
  </div>
{:else if role === "achievementPreview"}
  <div class="user--class-achievements-preview-wrap">
    {#if data.backgroundImage}
      <img src={data.backgroundImage} alt="Achievement Preview" />
    {/if}
    <div class="user--class-achievements-preview-descriptive">
      {@html data.textContent}
    </div>
  </div>
{:else if role === "tableBlock"}
  <div bind:this={tableWrap} class="user--class-content-image-wrap">
    {#if data.textContent}
      <div class="user--class-table-table-caption">{data.textContent}</div>
    {/if}
    {#if data.design && data.design.rows && data.design.columns && data.cellContent && data.cellContent.length}
      <table class="user--class-table-table">
        {#each Array(data.design.rows) as _, rowIndex}
          {#if data.cellContent[rowIndex]}
            <tr>
              {#each Array(data.design.columns) as _, colIndex}
                <td style="font-weight: {rowIndex === 0 ? 'bold' : 'normal'}">
                  {data.cellContent[rowIndex][colIndex]}
                </td>
              {/each}
            </tr>
          {/if}
        {/each}
      </table>
    {:else}
      <div>No data available for the table</div>
    {/if}
  </div>
{:else if role === "questionsBlock"}
  <div
    class="user--class-questions-wrap"
    style="border: solid 3px {data.customBackground}"
  >
    {#each data.questions as question, questionNumber}
      <div class="user--class-questions-question">
        <span
          class="user--class-questions-question-num"
          style="color: {data.customBackground}">{questionNumber + 1}</span
        >
        {@html question}
      </div>
    {/each}
  </div>
{:else}
  <FeedbackButton
    role="component"
    type="error"
    feedback="CompNotFound/Loc:SlideComponents"
  />
{/if}

<style>
  .char-container {
    margin-bottom: 1rem;
  }
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  .dialogue-line-outer {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
  }
  .dialogue-line-inner {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
</style>
