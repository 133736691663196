// src/stores.js
import { writable } from 'svelte/store';

function detectUserLanguage() {
    const userLanguageCookie = document.cookie.split('; ').find(row => row.startsWith('user_language='));
    if (userLanguageCookie) {
        return userLanguageCookie.split('=')[1];
    }
    const defaultLanguage = 'en';
    const browserLang = navigator.language || navigator.userLanguage; 
    const preferredLang = browserLang.split('-')[0].toLowerCase();
    if (['en', 'ru', 'zh'].includes(preferredLang)) {
        return preferredLang;
    }
    return defaultLanguage;
}

function setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

const userLanguage = detectUserLanguage();
setCookie('user_language', userLanguage, 180);

export const language = writable(userLanguage);
export const userStore = writable({});
export const isAuthorized = writable(false);
export const isUserPanelVisible = writable(false);
export const isTPanelVisible = writable(false);
export const submitAllowed = writable(false);
export const currentSection = writable('');
export const trackContentUpload = writable(false);
export const currentClass = writable(false);
export const websocketData = writable(null);
export const notesReceived = writable(null);
export const isCheckLoaded = writable(false);
export const tokenValidated = writable(false);
export const isChatInterfaceVisible = writable(false);  
export const isWhiteboardInterfaceVisible = writable(false);
export const pendingResponse = writable('');
export const isGeneratingChat = writable(false);
export const preloadingChat = writable(false);
export const isChatError = writable(false);
export const generatedMessages = writable([]);
export const API_BASE_URL = writable("https://api.clsrm.org")
export const exerciseStore = writable({
    chars: [],
    test: [],
    hasChar: false,
    hasPdf: false,
    hasVideo: false,
    videoLink: '',
    isListening: false,
    audioLink: '',
    pdfLink: '',
    hasImage: false,
    imageLink: '',
    assignAward: 'false',
    showExample: 'false',
    badgeDetails: {} 
});

// Function to set default values
export const setDefaultValues = () => {
    exerciseStore.set({
        chars: [],
        test: [],
        hasChar: 'false',
        hasPdf: 'false',
        hasVideo: 'false',
        videoLink: '',
        isListening: 'false',
        audioLink: '',
        pdfLink: '',
        hasImage: 'false',
        imageLink: '', 
        assignAward: 'false',
        showExample: 'false',
        badgeDetails: {}
    });
};

export const notification = writable({
    visible: false,
    message: '',
    header: '',
    type: 'info',
    duration: 5000
  });
  
export const highlightAnswer = writable({}); 

export function triggerNotification(newNotification) {
    notification.set({
      message: newNotification.message || '',
      header: newNotification.header || '',
      type: newNotification.type || 'info',
      duration: newNotification.duration || 5000
    });
}