<script>
    import { fly, blur } from "svelte/transition";
    import { writable } from "svelte/store";
    import { onMount, afterUpdate, onDestroy } from "svelte";
    import { DotLottieSvelte } from '@lottiefiles/dotlottie-svelte';
    import { userStore, isAuthorized, isWhiteboardInterfaceVisible } from "../store.js";
    import { translate } from "../translation";
    import Cookies from "js-cookie";
    import axios from "axios";
    import {sendSocketEvent} from '../websocket.js';
    import PushNotification from "./PushNotification.svelte";
    let notifications = [];
    let waitingForResponse = writable(false);
    export let wbIdentifier = null;

    function closeWhiteboardInterface() {
        isWhiteboardInterfaceVisible.set(false);
    }



    
  function addNotification(header, message, type = "info", duration = 3000) {
    notifications = [...notifications, { header, message, type, duration }];
    setTimeout(() => {
      notifications = notifications.slice(1);
    }, duration);
  }
  let isMobile = false;

if (typeof window !== "undefined") {
  isMobile = window.innerWidth < 800;
  window.addEventListener("resize", () => {
    isMobile = window.innerWidth < 800;
  });
}
    

  </script>
  {#each notifications as { header, message, type, duration }}
  <PushNotification {header} {message} {type} {duration} />
{/each}

  {#if $isWhiteboardInterfaceVisible}
  <div
  class="user--chat-interface-wrapper"
  in:blur={{ duration: 500 }}
  out:blur={{ duration: 500 }}
>



     <div class="chat-main-wrapper">
      <div class="upper-elements-wrapper">
         
   <div class="chat-interface-close-wrapper">
   <div class="user--class-generic-button-3-tiny-close-relative" on:click={closeWhiteboardInterface
}>
      Закрыть
     </div>
    </div>
</div>

<iframe
  src={`https://wb.alexanderkireev.org/#room=${wbIdentifier}`}
  width={"100%"}
  height={"100%"}
  style="border: none;"
  allowfullscreen
  loading="lazy"
/>



  </div>

  
</div>
{/if}


  <style>
     .centered-div {
      width: 100%;
      height: max-content;
      padding-block: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .loading-huge {
    width: 60px;
    min-width: 60px;
    height: 60px;
    min-height: 60px; 
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
    flex-shrink: 0; 
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
    .chat-main-wrapper {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: start;
        align-items: center;
        min-height: 100%;
        max-width: 100%;
    }
    .chat-interface-close-wrapper {
        flex: 1;
        height: max-content;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    .chat-model-block {
        width: max-content;
        height: max-content;
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;
        gap: 10px;
        font-size: 25px;
        font-weight: 400;
        color: #8A8A8A;
        flex: 1;
    }
    .response-loader {
        width: 100px; height: 100px; display: flex; justify-contnet: center; align-items: center
    }
    .chat-wrapper {
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
    }
    .chat-no-messages {
        width: 70%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        color: #8A8A8A;
        font-size: 23px;
        font-weight: 300;
    }
    .upper-elements-wrapper {
        width: 100%;
        height: max-content;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    .chat-model-block-switcher, .chat-model-close {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .chat-model-pic {
        width: 80px;
        height: 80px;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .preloading {
        margin: 0 auto;
        margin-block: 10px;
       
    }
    .chat-model-pic img {
        width: 100%;
        height: 100%;
    }
    .jello-horizontal {
      -webkit-animation: jello-horizontal 0.9s infinite both;
      animation: jello-horizontal 0.9s infinite both;
    }
    .message-list {
    flex: 1;
    width: 100%;
    overflow-y: auto;
     padding: 10px;
   border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
  }

  .message-sent {
    background: rgba(12, 92, 164, 0.1);
    width: 90%;
    padding-inline: 15px;
    padding-block: 20px;
    border-radius: 20px;
    margin: 5px 0;
    align-self: end;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    color: #000000;
    font-size: 20px;
    font-weight: 350;
    display: flex;
    /* overflow-x: hidden; */
    white-space: normal;
  }
  .message-received {
    background: rgba(142, 142, 142, 0.1);
    width: 90%;
    padding-inline: 15px;
    padding-block: 20px;
    border-radius: 20px;
    margin: 5px 0;
    align-self: start;
    color: #5c5c5c;
    font-size: 20px;
    font-weight: 350;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    display: flex;
    /* overflow-x: hidden; */
    white-space: normal;
  }

  .input-container {
    width: 100%;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 30px;
    padding: 15px;
   border: 2px solid #85ADD1;
  }

  .input {
    flex: 1;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 20px;
    resize: none;
    background: transparent;
  }

  .disabled {
    pointer-events: none;
  }
  .send-button {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
    margin-left: 10px;
  }

  .placeholder {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-align: center;
        color: #5c5c5c;
        font-weight: 350;
        gap: 10px;
        font-size: 20px;
    }
    .placeholder-svg {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
       
    }

    @-webkit-keyframes jello-horizontal {
      0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
      30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
      }
      40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
      }
      50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
      }
      65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
      }
      75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
      }
      100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    }
    @keyframes jello-horizontal {
      0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
      30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
      }
      40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
      }
      50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
      }
      65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
      }
      75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
      }
      100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    }
    .intel_chat_logo_wrapper {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .intel_chat_logo {
        width: 60px;
        height: 60px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .user-main-wrapper-block {
      width: 100%;
      height: max-content;
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }
    .user-name-header {
      width: 100%;
      height: max-content;
      text-align: center;
      font-weight: 800;
      color: #000000;
      font-size: 40px;
    }
    .user-desc-header {
      width: 100%;
      height: max-content;
      text-align: center;
      font-weight: 800;
      color: #000000;
      font-size: 25px;
    }
    .user-info-block {
      width: 80%;
      height: max-content;
      gap: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 3px solid #0c5ca450;
      border-radius: 40px;
      padding: 30px;
    }
    .user-info-block-desc {
      width: 100%;
      height: max-content;
      text-align: center;
      font-size: 23px;
      font-weight: 350;
      color: #5c5c5c;
    }
    .user-info-block-val {
      width: 80%;
      margin: 0 auto;
      height: max-content;
      padding: 10px;
      display: flex;
      justify-content: center;
      border: 3px solid #0c5ca450;
      border-radius: 100px;
    }
    @media (max-width: 750px) {
        .message-list {
            border-radius: 20px;
        }
        .chat-model-block {
            justify-content: flex-start;
            font-size: 20px;
            width: max-content;
        }
        .chat-model-block-switcher, .chat-model-close {
            width: 35px;
            height: 35px;
        }
        .input-container {
            border-radius: 20px;
        }
        .message-sent {
            font-size: 18px;
            font-weight: 400;
        }
        .message-received {
            font-size: 18px;
            font-weight: 400;
        }
    }
  </style>
  