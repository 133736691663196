<script>
    import { writable, get, derived } from 'svelte/store';
    import { sendSocketEvent } from "../websocket.js";
    import {userStore, highlightAnswer} from '../store.js';
    import { onMount } from "svelte";
    
  
    const iconSubmitted = `<svg fill="#4CBB9A" style="width: 100%; height: 100%" viewBox="0 0 32 32"><path d="m16 0c8.836556 0 16 7.163444 16 16s-7.163444 16-16 16-16-7.163444-16-16 7.163444-16 16-16zm0 2c-7.7319865 0-14 6.2680135-14 14s6.2680135 14 14 14 14-6.2680135 14-14-6.2680135-14-14-14zm6.6208153 9.8786797c.3905243.3905242.3905243 1.0236892 0 1.4142135l-7.0710678 7.0710678c-.3626297.3626297-.9344751.3885319-1.3269928.0777064l-.0872208-.0777064-4.24264068-4.2426407c-.39052429-.3905242-.39052429-1.0236892 0-1.4142135.39052428-.3905243 1.02368928-.3905243 1.41421358 0l3.5348268 3.5348268 6.3646681-6.3632539c.3905243-.3905243 1.0236893-.3905243 1.4142136 0z"/></svg>`;

    export let slideData;
    export let exerciseID;
    export let location; // location can be 'revision', 'homework', or 'classroom'
    export let completedData;
    let partScore = 0;
  let maxScore = 100; // Max score for the exercise
  let hintExpanded = writable(false);
  
  // Store for user answers and feedback
  let userAnswers = writable(new Array(slideData.questions.length).fill(''));
  let correctAnswers = slideData.answer.map(answer => answer.toLocaleLowerCase('ru'));
  let feedback = writable(new Array(slideData.questions.length).fill(null));
  let answerFeedback = writable(new Array(slideData.questions.length).fill(null));
  let submitted = writable(false);

  // WebSocket event handler for dynamic highlighting
  $: if ($highlightAnswer.id === exerciseID) {
    const eventAnswer = $highlightAnswer.answer;
    const blockIndex = $highlightAnswer.blockIndex;
    
    // Update the feedback to highlight the answer based on blockIndex
    answerFeedback.update(af => {
      af[blockIndex] = eventAnswer === correctAnswers[blockIndex] ? 'correct' : 'incorrect';
      return af;
    });

    handleAnswer(blockIndex, eventAnswer);
  }

  function expandHint() {
    hintExpanded.update(expanded => !expanded);
  }
  
  // Handle user answer selection
  function handleAnswer(questionIndex, option) {
    userAnswers.update(answers => {
      answers[questionIndex] = option;
      return answers;
    });

    feedback.update((feedbackArray) => {
      feedbackArray[questionIndex] = option;
      return feedbackArray;
    });

    // Send the selected answer to the teacher's socket in real-time with the index as the block/button id
    const eventDetails = {
      actionName: {
        kind: "truefalse", // Adjust for different question types if needed
        answer: option,
        id: exerciseID, // Refers to the exercise
        blockIndex: questionIndex // Refers to the specific question
      },
    };
    sendSocketEvent("studentAnswerDynamic", eventDetails);
  }

  // Check answers and update feedback
  function checkAnswers() {
    answerFeedback.update(af => {
      const ua = get(userAnswers);
      for (let i = 0; i < correctAnswers.length; i++) {
        if (ua[i].toLocaleLowerCase('ru') === correctAnswers[i]) {
          af[i] = 'correct';
        } else {
          af[i] = 'incorrect';
        }
      }
      return af;
    });
  }

  // Calculate the partScore based on correct answers
  function calculatePartScore() {
    const totalQuestions = correctAnswers.length;
    const correctCount = get(answerFeedback).filter(feedback => feedback === 'correct').length;
    partScore = (correctCount / totalQuestions) * maxScore; // Proportional to the number of correct answers
  }

  // Submit answers and send the socket event
  function submitAnswers(event) {
    if (get(submitted)) return; // Prevent multiple submissions

    // Check answers first
    checkAnswers();
    
    // Calculate if all answers are correct
    let isAllCorrect = get(answerFeedback).every(feedback => feedback === 'correct');

    // Calculate the total score (partScore)
    calculatePartScore();

    // Gather user answers
    let userAnswerArray = get(userAnswers).map(answer => answer.toLocaleLowerCase('ru'));

    // Determine the correct endpoint
    let endpoint;
    if (location === "revision") {
      endpoint = "exerciseCompletedRevision";
    } else if (location === "homework") {
      endpoint = "exerciseCompletedRevision";
    } else if (location === "classroom") {
      endpoint = "exerciseCompleted";
    }

    // Prepare the event details payload
    const eventDetails = {
      actionName: {
        exerciseID: exerciseID,
        isCorrect: isAllCorrect ? 'true' : 'false', // Convert boolean to string
        isCreative: false,
        partialScore: partScore
      },
      answers: userAnswerArray, // User's answers
      isAllCorrect: isAllCorrect // Whether all answers are correct
    };

    // Mark the exercise as submitted to prevent multiple submissions
    submitted.set(true);

    // Send the socket event with the payload
    sendSocketEvent(endpoint, eventDetails);
  }

  // Proceed to the next step in the exercise
  function proceedNext() {
    sendSocketEvent('proceedSelf', '');
  }

  // Derived store to track if all questions are answered
  const allAnswered = derived(userAnswers, $userAnswers => {
    return $userAnswers.every(answer => answer !== '');
  });

  // If completedData exists, populate the answers and feedback automatically
  onMount(() => {
    if (completedData && completedData.studentAnswer != null) {
      try {
        // Ensure parsedStudentAnswer is an array and process its elements
        const parsedStudentAnswer = JSON.parse(completedData.studentAnswer);
        if (Array.isArray(parsedStudentAnswer)) {
          parsedStudentAnswer.forEach((answer, index) => {
            if (typeof answer === 'string') {
              handleAnswer(index, answer.toLocaleLowerCase("ru"));
            }
          });

          // Set the feedback based on whether the answer was correct or not
          answerFeedback.update((af) => {
            const ua = get(userAnswers);
            for (let i = 0; i < correctAnswers.length; i++) {
              if (ua[i] && ua[i].toLocaleLowerCase("ru") === correctAnswers[i]) {
                af[i] = "correct";
              } else {
                af[i] = "incorrect";
              }
            }
            return af;
          });

          // Mark as submitted if data exists
          submitted.set(true);
        }
      } catch (error) {
        console.error("Error parsing studentAnswer:", error);
      }
    }
  });

  </script>
  
  <div class="user--class-exercise-header">
    {@html slideData.textContent}
  </div>
  <div class="multiple-choice-exercise">

    
    <div class="exercise-answer-block-multiple">
      {#each slideData.questions as question, questionIndex}
        <div class="user--class-exercise-question"
             class:is-correct={$answerFeedback[questionIndex] === 'correct'}
             class:is-partially-correct={$answerFeedback[questionIndex] === 'partially-correct'}
             class:is-incorrect={$answerFeedback[questionIndex] === 'incorrect'}
             data-question-id={questionIndex}>
          <p>{@html question.textContent}</p>
          {#if slideData.hint[questionIndex] && $hintExpanded}
          <div class="user--exercise-hint-mini">
            {slideData.hint[questionIndex]}
          </div>
        {/if}
          <div class={`flex-direction-${question.options.some(opt => opt.length > 30) ? 'column' : 'row'}`}>
            {#each question.options as option}
            <div 
            class="user--class-exercise-answer-select-wrap"
            on:click={() => handleAnswer(questionIndex, option)}
            style="background-color: {$userAnswers[questionIndex] === option ? '#0C5CA450' : 'transparent'}">
            {option}
          </div>
          

            {/each}
          </div>
        </div>
      {/each}
    </div>
    <div class="user--class-exercise-buttons-wrap">
      {#if $submitted}
      {#if location == "classroom"}
        <div class="submitted-icon">{@html iconSubmitted}</div>
      {:else if (location == "homework" || location == "revision")}
      <button class="user--class-generic-button-3"  on:click={(event) => {
        event.currentTarget.classList.add('inactive');
        event.currentTarget.textContent = 'Загружаю...';
        proceedNext();
      }}
      >Продолжить</button
    >
      {/if}
        {:else}
        {#if $userStore.student_status != 0}
        <button
        on:click={submitAnswers}
        class="user--class-generic-button-3 {$allAnswered
          ? ''
          : 'user--button-clicked'}"
        disabled={!$allAnswered}>
        Ответить
      </button>
      {/if}
      
      {#if slideData.hint && slideData.hint.some((hint) => hint !== "")}
      <button class="user--class-generic-button-3" on:click={expandHint}
        >Подсказка</button
      >
    {/if}
      {/if}
    </div>
  </div>
  
  <style>
    .is-correct {
      border: 3px solid rgba(135, 194, 177, 1);
    }
    .is-partially-correct {
      border: 3px solid rgba(255, 204, 108, 1);
    }
    .is-incorrect {
      border: 3px solid rgba(255, 147, 147, 1);
    }
    .centered-div {
      width: 60px;
      height: 60px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .loading-huge {
      width: 60px;
      height: 60px;
      background: linear-gradient(45deg, #020344, #28b8d5);
      border-radius: 50%;
      position: relative;
    }
    .jello-horizontal {
      -webkit-animation: jello-horizontal 0.9s infinite both;
      animation: jello-horizontal 0.9s infinite both;
    }
    .submitted-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
    @-webkit-keyframes jello-horizontal {
      0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
      30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
      }
      40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
      }
      50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
      }
      65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
      }
      75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
      }
      100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    }
    @keyframes jello-horizontal {
      0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
      30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
      }
      40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
      }
      50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
      }
      65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
      }
      75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
      }
      100% { 
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    }
  </style>
  