<script>
      import { userStore, isAuthorized, API_BASE_URL } from '../store.js';
      import { fly, blur } from 'svelte/transition';
  import { push } from 'svelte-spa-router';
  import axios from 'axios';
  import Pagination from './Pagination.svelte';
  import { onMount } from 'svelte';
  import { translate } from '../translation';
  import {writable, get, derived} from 'svelte/store';
 
  // rerun the j
  let loading = writable(true);
    let data = [];

    const achievements = writable([]);
    let currentPage = writable(1);
    const itemsPerPage = 6;

    const fetchUserAwards = async () => {
        console.log('Executing the snippet related to fetching user awards')
        try {
            const response = await axios.post(`${$API_BASE_URL}/fetchUserAwards`, {
                token: get(userStore).token
            });
            if (response.data.status === 'success') {
                data = response.data.awards;
                initializeAchievements();
            } else {
                loading.set(false);
            }
        } catch (error) {
            loading.set(false);
        }
    };

    const initializeAchievements = () => {
        let delay = 200;
        if (data.length === 0) {
            achievements.set([{
                empty: true,
                message: translate('В этом разделе пока пусто', 'There is nothing yet', '这里还没什么内容')
            }]);
        } else {
            const mappedAchievements = data.map((block, index) => ({
                ...block,
                delay: `${delay + index * 100}ms`
            }));
            achievements.set(mappedAchievements);
        }
        loading.set(false);
    };

    const paginatedAchievements = derived(
        [achievements, currentPage],
        ([$achievements, $currentPage]) => {
            const start = ($currentPage - 1) * itemsPerPage;
            const end = $currentPage * itemsPerPage;
            return $achievements.slice(start, end);
        }
    );

    onMount(() => {
        fetchUserAwards();
    });

    const handlePageChange = (page) => {
        currentPage.set(page);
    };
</script>

<style>
.centered-div {
    width: 60px;
    height: 60px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
}
.jello-horizontal {
-webkit-animation: jello-horizontal 0.9s infinite both;
        animation: jello-horizontal 0.9s infinite both;
}
@-webkit-keyframes jello-horizontal {
0% {
-webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
}
30% {
-webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
}
40% {
-webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
}
50% {
-webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
}
65% {
-webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
}
75% {
-webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
}
100% {
-webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
}
}
@keyframes jello-horizontal {
0% {
-webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
}
30% {
-webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
}
40% {
-webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
}
50% {
-webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
}
65% {
-webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
}
75% {
-webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
}
100% {
-webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
}
}
</style>

<div class="user--block-expanded-wrapper" in:blur={{ duration: 200 }}>
    {#if $loading}
        <div class="loading-huge jello-horizontal"></div>
    {:else}
        <div class="user--popup-achievement-wrap">
            <div class="user--popup-achievement-block-inner">
                {#if $achievements.length === 0 || $achievements[0]?.empty}
                    <div class="user--popup-indicator-empty-block">{$achievements[0]?.message}</div>
                {:else}
                    <div class="user--popup-achievement-block-inner-date">
                        {translate('Последние', 'Last', '最后')}
                    </div>
                    <div class="user--popup-achievement-block-inner-block">
                        {#each $paginatedAchievements as block}
                            <div class="user--popup-achievement-block" style="animation-delay: {block.delay}">
                                <div class="user--popup-achievement-block-inner-block-wrap">
                                    <div class="user--achievement-block-circle">
                                        {#if block.achievementIcon}
                                            <img src={block.achievementIcon} alt="Achievement Icon" />
                                        {/if}
                                    </div>
                                    <span class="user--achievement-block-text-h1">{block.name}</span>
                                </div>
                                <div class="user--popup-achievement-block-inner-block-wrap">
                                    <span class="user--achievement-block-text-h2">{block.description}</span>
                                </div>
                            </div>
                        {/each}
                    </div>
                    <Pagination
                        totalItems={$achievements.length}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                {/if}
            </div>
        </div>
    {/if}
</div>