<script>
  import { wrap } from "svelte-spa-router/wrap";
  import { onMount } from "svelte";
  import { location } from "svelte-spa-router";
  import Router from "svelte-spa-router";
  import Nav from "./components/Nav.svelte";
  import Classroom from "./components/Classroom.svelte";
  import Awards from "./components/Awards.svelte";
  import Goals from "./components/Goals.svelte";
  import Characters from "./components/Characters.svelte";
  import Stories from "./components/Stories.svelte";
  import MaterialsPro from "./components/MaterialsPro.svelte";
  import Clips from "./components/Clips.svelte";
  import NotFound from "./components/NotFound.svelte";
  import Revision from "./components/Revision.svelte";
  import Homework from "./components/Homework.svelte";
  import Library from "./components/Library.svelte";
  import Materials from "./components/Materials.svelte";
  import Manage from "./components/Manage.svelte";
  import Schedule from "./components/Schedule.svelte";
  import Payment from "./components/Payment.svelte";
  import Flashcards from "./components/Flashcards.svelte";
  import AssignmentCheck from "./components/AssignmentCheck.svelte";
  import Updates from "./components/static/Updates.svelte";
  import Legal from "./components/static/Legal.svelte";
  import PaymentSuccess from "./components/static/PaymentSuccess.svelte";
  import PaymentFailure from "./components/static/PaymentFailure.svelte";
  import Demo from "./components/static/Demo.svelte";
  import Signup from "./components/static/Signup.svelte";
  import SignupTeacher from "./components/static/SignupTeacher.svelte";
  import Footer from "./components/Footer.svelte";
  import { userStore, isAuthorized, API_BASE_URL } from "./store.js";
  import { initConnect, sendSocketEvent } from "./websocket";
  import Cookies from "js-cookie";
  import axios from "axios";
  let loading = true;
  const excludedRoutes = ["/updates/", "/signup/", "/partners/", "/legal/", "/updates", "/signup", "/signupteacher", "/signupteacher/", "/partners", "/legal", "/paysuccess", "/payfailed", "/paysuccess/", "/payfailed/", "/signup/", "/signup", "/demo", "/demo/"];

  const verifyToken = async () => {
    return new Promise(async (resolve, reject) => {
      const token = Cookies.get("token_clsrm");
      if (token) {
        try {
          const response = await axios.post(
            `${$API_BASE_URL}/validateJWT`,
            { token: token }
          );
          const result = response.data;
          console.log(response.data);

          if (result.status === 1) {
            userStore.set({
              email: result.data.email,
              name: result.data.name,
              student_id: result.data.student_id,
              student_status: result.data.student_status,
              profile_picture: result.data.profile_picture,
              course: result.data.student_course,
              registration_complete: result.data.registration_complete,
              token: result.data.token,
            });
            isAuthorized.set(true);
            resolve();
          } else {
            isAuthorized.set(false);
            reject();
          }
        } catch (error) {
          isAuthorized.set(false);
          reject();
        }
      } else {
        isAuthorized.set(false);
        reject();
      }
      loading = false;
    });
  };

  onMount(async () => {
    const token = Cookies.get("token_clsrm");
    if (token) {
      try {
        await verifyToken();
        initConnect();
      } catch (error) {
        console.error("Token verification failed", error);
      }
    } else {
      isAuthorized.set(false);
      loading = false;
    }
  });
  const routes = {
    "/": Classroom,
    "/legal": Legal,
    "/characters": Characters,
    "/awards": Awards,
    "/goals": Goals,
    "/stories": Stories,
    "/clips": Clips,
    "/revision": Revision,
    "/homework": Homework,
    "/materials": Materials,
    "/materialspro": MaterialsPro,
    "/manage": Manage,
    "/schedule": Schedule,
    "/payment": Payment,
    "/library": Library,
    "/flashcards": Flashcards,
    "/assignmentcheck": AssignmentCheck,
    "/updates": Updates,
    "/paysuccess": PaymentSuccess,
    "/payfailed": PaymentFailure,
    "/demo": Demo,
    "/signup": Signup,
    "/signupteacher": SignupTeacher,
    "*": NotFound,
  };
</script>
 
<main>
  {#if loading}
    <div class="centered-div">
      <div class="loading-huge jello-horizontal"></div>
    </div>
  {:else}
    {#if !excludedRoutes.includes($location)}
      <Nav />
    {/if}
    <Router {routes} />
  {/if}
</main>

<style>
  .centered-div {
    width: 60px;
    height: 60px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
</style>
