<script>
    import { createEventDispatcher } from 'svelte';
    import { submitAllowed } from '../store.js';
    import {fly, blur} from 'svelte/transition';
    import { onMount } from 'svelte';

    export let input;
    const dispatch = createEventDispatcher();

    let value = input.default || '';
    let isValid = false;
    let errorMessage = '';
    const tickIcon = `<svg style="width: 100%; height: 100%;" viewBox="0 0 545 402" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M532.026 12.5378C548.298 28.8097 548.298 55.1915 532.026 71.4634L213.936 389.553C197.665 405.825 171.283 405.825 155.012 389.554L12.5385 247.087C-3.73368 230.816 -3.73427 204.434 12.5372 188.162C28.8087 171.89 55.1906 171.889 71.4628 188.161L184.473 301.166L473.1 12.5379C489.372 -3.73396 515.754 -3.73399 532.026 12.5378Z" fill="#17B9A6"/>
</svg>`;

    const handleChange = (event) => {
        value = event.target.value;
        validateInput();
        dispatch('change', { identifier: input.identifier, value });
        dispatch('validityChange', { identifier: input.identifier, isValid });
    };

    const validateInput = () => {
        switch (input.type) {
            case 'password':
            if (input.identifier === 'pass_new') {
                    const currentPassword = document.querySelector('#pass_current').value;
                    isValid = value.length >= 8 && value !== currentPassword;
                    errorMessage = value === currentPassword ? 'New password must be different from the current password' : (isValid ? '' : 'Password must be at least 8 characters long');
                } else if (input.identifier === 'pass_new_double') {
                    const newPassword = document.querySelector('#pass_new').value;
                    isValid = value.length >= 8 && value === newPassword;
                    errorMessage = isValid ? '' : 'Passwords do not match';
                } else {
                    isValid = value.length >= 8;
                    errorMessage = isValid ? '' : 'Password must be at least 8 characters long';
                }
                break;
            case 'email':
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                isValid = emailRegex.test(value);
                errorMessage = isValid ? '' : 'Invalid email format';
                break;
            case 'text':
                isValid = value.trim() !== '';
                errorMessage = isValid ? '' : 'This field is required';
                break;
            case 'datetime-local':
                isValid = value.trim() !== '';
                errorMessage = isValid ? '' : 'This field is required';
                break;
            default:
                isValid = true;
                errorMessage = '';
        }
       // updateSubmitAllowed();
    };

    // const updateSubmitAllowed = () => {
    //     const inputs = Array.from(document.querySelectorAll('.input-wrapper input'));
    //     console.log('INPUTS ARR: ', inputs);
    //     const allInputsValid = inputs.every(input => input.dataset.valid == 'true');
    //     console.log('updateSubmitAllowed - allInputsValid:', allInputsValid);
    //     submitAllowed.set(allInputsValid);
    //     dispatch('validityChange', allInputsValid);
    // };

    onMount(() => {
        validateInput();
        dispatch('validityChange', { identifier: input.identifier, isValid });
    });
</script>

<div class="input-wrapper">
    <p>{input.details.descriptor}</p>
    {#if input.type === 'text'}
        <input type="text" placeholder={input.details.placeholder} bind:value={value} on:input={handleChange} data-valid={isValid} />
    {:else if input.type === 'password'}
        <input type="password" placeholder={input.details.placeholder} bind:value={value} on:input={handleChange} id={input.identifier} data-valid={isValid} />
    {:else if input.type === 'datetime-local'}
        <input type="datetime-local" bind:value={value} on:input={handleChange} data-valid={isValid} />
    {:else if input.type === 'email'}
        <input type="email" placeholder={input.details.placeholder} bind:value={value} on:input={handleChange} data-valid={isValid} />
    {/if}
    {#if isValid && input.identifier != 'pass_current'}
        <div class="tick-success" in:blur={{ duration: 500 }}  out:blur={{ duration: 500 }}>{@html tickIcon}</div>
    {/if}
</div>

<style>
    .tick-success {
        width: 10px;
        height: 10px;
        margin: 0 auto;
        margin-block: 10px;
    }
</style>