<script>
    import { onMount } from "svelte";
    import { fly, blur } from "svelte/transition";
    import { push } from "svelte-spa-router";
    import { writable, get } from "svelte/store";
    import { userStore, isAuthorized, API_BASE_URL } from "../store.js";
    import axios from "axios";
    import Slide from './Slide.svelte';
    import PushNotification from "./PushNotification.svelte";
  import SlideComponents from "./SlideComponents.svelte";
    
    let notifications = [];
    const changeIcon = `<svg style="width: 100%; height: 100%" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.7071 4.29289C12.3166 3.90237 11.6834 3.90237 11.2929 4.29289L7.29289 8.29289C6.90237 8.68342 6.90237 9.31658 7.29289 9.70711C7.68342 10.0976 8.31658 10.0976 8.70711 9.70711L12 6.41421L15.2929 9.70711C15.6834 10.0976 16.3166 10.0976 16.7071 9.70711C17.0976 9.31658 17.0976 8.68342 16.7071 8.29289L12.7071 4.29289ZM7.29289 15.7071L11.2929 19.7071C11.6834 20.0976 12.3166 20.0976 12.7071 19.7071L16.7071 15.7071C17.0976 15.3166 17.0976 14.6834 16.7071 14.2929C16.3166 13.9024 15.6834 13.9024 15.2929 14.2929L12 17.5858L8.70711 14.2929C8.31658 13.9024 7.68342 13.9024 7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071Z" fill="#0C5CA460"/></svg>`;
  

    // PREDEFINED VARIABLES

    let preliminaryLoaded = false;
  let showConstructor = false;
  let courses = [];
  let courseId = null;
  let slideInfoParams = {};
  let showNewCourseInt = false;
  let selectedCourseId = null;
  let selectedClass = null;
  let classCount = 0;
  let constructorObject = {};
  let renderKey = 0;
  let section = 1;
  let slides = [];
  let loading = false;
  let showCourses = true;
  let showClasses = true;
  let showEmpty = false;
  let showSlides = false;
  let loadingClasses = true;
  let selectedSlideIndex = 0;
  let currentSlide = null;
  let currentSlideId = null;
  let isCurrentExercise = false;
  let editMode = false;
  let moveMode = false;
  let reloadSlides = false;
  let showSlideInfo = false;
  let addingNewClass = false;
  let miniatureExpanded = true;
  let newPosition = 0;

  function cleanup() {
    preliminaryLoaded = false;
    showConstructor = false;
    courses = [];
    slideInfoParams = {};
    showNewCourseInt = false;
    selectedCourseId = null;
    selectedClass = null;
    classCount = 0;
    section = 1;
    slides = [];
    loading = false;
    showCourses = true;
    showClasses = false;
    showSlides = false;
    loadingClasses = true;
    selectedSlideIndex = 0;
    currentSlide = null;
    currentSlideId = null;
    isCurrentExercise = false;
    editMode = false;
    moveMode = false;
    reloadSlides = false;
    showSlideInfo = false;
  }



    
    function addNotification(header, message, type = "info", duration = 3000) {
      notifications = [...notifications, { header, message, type, duration }];
      setTimeout(() => {
        notifications = notifications.slice(1);
      }, duration);
    }
    
    const handleNotification = (event) => {
      const { header, message, type, duration } = event.detail;
      addNotification(header, message, type, duration);
    };

   

  function fetchClassCount() {
    axios
      .post(`${$API_BASE_URL}/GetClassCountStudent`, {
        token: $userStore.token,
        userStatus: $userStore.student_status
      })
      .then((response) => {
        
       //console.log('FETCH CLASS COUNT FUNCTION. SERVER RESPONSE:', response.data);
       // Reg. incomplete processing 
       if (response.data.error) {
        showClasses = false;
        showEmpty = true;
        
       }
        preliminaryLoaded = true;
        classCount = response.data.class_count;
        courseId = response.data.course_id;
        loadingClasses = false;
        reloadSlides = false;
        // showCourses = false;
      })
      .catch((error) => console.error("Error fetching class count:", error));
    // loadingClasses = false;
  }

  function selectClass(classNumber) {
    selectedClass = classNumber;
    showClasses = false;
    showSlides = true;
    fetchSlides(classNumber, 1, courseId);
  }

  function fetchSlides(classNumber, section, courseId) {
    loading = true;

    // original script: fetch-slides.php -- POST request with the payload {class_number: classNumber, section_number: section, slide_course: courseId}
    axios
      .post(`${$API_BASE_URL}/fetchSlides`, {
        token: $userStore.token,
        class_number: classNumber,
        section_number: section,
        courseId: courseId,
      })
      .then((response) => {
         console.log(
           "API call to fetchSlides. Server response: ",
           response.data
         );
        slides = response.data;
      //  console.log('FETCH SLIDES FUNCTION. SERVER RESPONSE:', slides);
        loading = false;
        reloadSlides = false;
        showClasses = false;
        miniatureExpanded = true;
        selectSlide(selectedSlideIndex ? selectedSlideIndex : 0);
      })
      .catch((error) => {
        console.error("Error fetching slides:", error);
        loading = false;
      });
  }
    
  function selectSlide(index) {
    showConstructor = false;
    selectedSlideIndex = index;
    miniatureExpanded = true;
    
    currentSlide = slides[index].content;
    currentSlideId = slides[index].id;
    isCurrentExercise = slides[index].isExercise;
    // console.log(
    //   "[DEBUG] Selected another slide. currentSlide object: ",
    //   currentSlide
    // );
  }
    
    
    function fetchCourses() {
    axios
      .post(`${$API_BASE_URL}/getCourses`, {
        token: $userStore.token,
      })
      .then((response) => {
        courses = response.data.courses;
        preliminaryLoaded = true;
      })
      .catch((error) => console.error("Error fetching courses:", error));
  }
    onMount(() => {
      fetchClassCount();
    });
    </script>
    
    {#each notifications as { header, message, type, duration }}
      <PushNotification {header} {message} {type} {duration} />
    {/each}
    
    <div class="user--block-expanded-wrapper">
  {#if preliminaryLoaded}
   
      {#if showEmpty}
      <span class="null-contents-display"
      >Тут пока нет доступных тебе материалов</span
    >
      {/if}
    {#if showClasses}
    {#if classCount > 0}
      <div class="user--class-exercise-header">Выберите номер занятия</div>
      {/if}
      {#if loadingClasses || reloadSlides}
        <div class="loading-huge jello-horizontal"></div>
      {:else}
     
      <div class="course-selector" in:blur={{ duration: 500 }}>
        {#if classCount > 0}  
          {#each Array(classCount)
            .fill()
            .map((_, i) => i + 1) as classNumber}
            <div
              class="user--class-generic-button-3-tiny"
              on:click={() => selectClass(classNumber)}
            >
              Занятие {classNumber}
            </div>
          {/each}
           {:else} 
           <div class="null-contents-display">Материалов не найдено</div>
           {/if}
        </div>
      {/if}
    {/if}

    {#if selectedCourseId && selectedClass && showSlides}
      <!-- Step 3: Selected course and class -->
      <div class="user--class-exercise-header">
        <div class="header-inner-row">
          Занятие {selectedClass}
          <div class="header-inner-icon" on:click={() => changeClass()}>
            {@html changeIcon}
          </div>
        </div>
      </div>

      <!-- Step 4: Section selection -->
     
    {/if}

    <!-- Step 5: Load the traverse for each slide -->
    {#if showSlides}
      {#if loading}
        <div class="loading-huge jello-horizontal"></div>
      {:else if slides.length > 0}
        <div class="slides-container" in:blur={{ duration: 500 }}>
          {#each slides as slide, index}
           
            <div
              class="slide {selectedSlideIndex === index
                ? 'slide-selected'
                : ''}"
              on:click={() => selectSlide(index)}
            >
              <div class="slide-number">{index + 1}</div>
              <div class="slide-content">{slide.content.slideName}</div>
              </div>
          {/each}
          <!-- <div class="add-slide" on:click={addNewSlide}>
            {@html addSlideButton}
          </div> -->
        </div>

      {/if}
    {/if}

 

    <!-- Start of the dynamic part -->
    {#if miniatureExpanded}
      {#if currentSlide}
        
        {#key currentSlide}
          <div class="slide-miniature">
            {#if currentSlide["isExercise"] === "false" || currentSlide["isExercise"] == undefined} 
             <!--  <Exercise
                slideData={currentSlide}
                exerciseID={1}
                location={"preview"}
              />
            {:else} -->
              <Slide contents={currentSlide} />
            {/if}
          </div>
          {/key}
        {/if}
    {/if}

    <!-- End of the dynamic part -->

    {#if showConstructor}
      <!-- <div class="user--class-exercise-header">Создайте новый слайд или упражнения с помощью конструктора</div> -->
      <Constructor locationType="builder" {constructorObject} key={renderKey} />
    {/if}
  {:else}
    <div class="loading-huge jello-horizontal"></div>
  {/if}
</div>
    
<style>
  .course-selector {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 90%;
    margin: 0 auto;
    padding: 30px;
    border: 2px solid #d9d9d9;
    border-radius: 40px;
  }
  .course-selector-inner-btn {
    width: max-content;
    height: max-content;
    padding-block: 10px;
    padding-inline: 20px;
    font-size: 20px;
    font-weight: 400;
    background-color: #0c5ca450;
  }
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  .slides-container {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    gap: 20px;
    width: 100%;
    height: max-content;
    padding-block: 20px;
  }
  .null-contents-display {
    font-size: 23px;
    font-weight: 350;
    color: #5c5c5c;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .slide {
    width: 30%;
    height: 250px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    border: 3px solid #e8e8e8;
    flex: 0 0 30%;
    transition: all 0.15s linear;
    cursor: pointer;
  }
  .slide:hover {
    border: 3px solid #0c5ca4;
  }
  .icon-button-inbetween {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    align-self: center;
    cursor: pointer;
  }
  .section-selector {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: max-content;
    height: max-content;
    padding: 10px;
    border-radius: 50px;
    background-color: #0c5ca420;
  }
  .slide-selected {
    border: 3px solid #0c5ca4;
  }
  .section-select-button {
    width: max-content;
    height: max-content;
    padding-block: 15px;
    padding-inline: 25px;
    background-color: #ffffff;
    color: rgba(12, 85, 152, 0.8);
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    border-radius: 100px;
    margin: 0 auto;
    transition: all 0.2s linear;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .plus-icon {
    width: 30px;
    height: 30px;
  }
  .active {
    background-color: #0c5ca460;
    color: #ffffff;
  }
  .section-select-button:hover {
    background-color: #0c5ca460;
    color: #ffffff;
  }
  .no-slides {
    font-size: 22px;
    font-weight: 350;
    color: #5c5c5c;
  }
  .slide-edit-interface {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  .header-inner-row {
    width: max-content;
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 22px;
    font-weight: 450;
    color: #5c5c5c;
    justify-content: center;
    align-items: center;
  }
  .header-inner-icon {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .buttons-row-wrap {
    width: max-content;
    height: max-content;
    padding-block: 5px;
    padding-inline: 7px;
    border-radius: 50px;
    border: 2px solid #0c5ca450;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: #ffffff;
  }
  .buttons-row-wrap-icon {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .slide-number {
    font-size: 30px;
    font-weight: 800;
    color: #0c5ca460;
  }
  .slide-content {
    font-size: 20px;
    font-weight: 350;
    color: #5c5c5c;
  }
  .null-contents-display {
    font-size: 23px;
    font-weight: 350;
    color: #5c5c5c;
    width: max-content;
    margin: 0 auto;
  } 
  .slide-miniature {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 40px;
    border: 2px solid #0c5ca450;
  }
  .move-interface,
  .info-interface {
    width: 100%;
    height: max-content;
    padding: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    flex-direction: column;
    border: 3px solid #0c5ca450;
    border-radius: 40px;
  } 
  @media (max-width: 750px) {
    .course-selector {
      grid-template-columns: 1fr;
      width: 100%;
    }
    .slide {
      width: 100%;
      flex: 0 0 90%;
    }
    .slide-miniature {
      border-radius: 30px;
    }
  }
</style>


      
    