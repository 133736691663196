<script>
  import { translate } from "../translation";
  import { onMount } from "svelte";
  import { userStore, isAuthorized, API_BASE_URL } from "../store.js";
  import { writable } from "svelte/store";
  import Cookies from "js-cookie";
  import axios from "axios";
  import { get } from "svelte/store";
  import PushNotification from "./PushNotification.svelte";
  import HCaptcha from "./hCaptcha.svelte";
  import Footer from "./Footer.svelte";
  import { initConnect, sendSocketEvent } from "../websocket";
  let notifications = [];
  let loading = false;
  let profPic = writable("");
  let preliminaryAuth = writable(false);

  // logo assets

  const API_BASE_URLL = "https://api.clsrm.org"
  import { setCookie, getCookie, eraseCookie } from "../utils";
  function addNotification(header, message, type = "info", duration = 3000) {
    notifications = [...notifications, { header, message, type, duration }];
    setTimeout(() => {
      notifications = notifications.slice(1);
    }, duration);
  }

  let email = "";
  let password = "";
  let emailInput;
  let passwordInput;
  let loginButton;
  let formSubmitted = false;
  let failedAttempts = 0; 
  let showCaptcha = writable(false);
  let captchaVerified = false;
  let captchaToken = ""; 
  
  $: emailError = formSubmitted && email && !validateEmail(email);
  $: passwordError = formSubmitted && !validatePassword(password);
  $: buttonActive =
    email && password && validateEmail(email) && validatePassword(password);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 8;
  };

  const handleCaptchaVerification = (token) => {
    captchaToken = token;
    captchaVerified = true;
  };

  const handleLogin = async () => {
    formSubmitted = true;
    loading = true;
    if (!validateEmail(email)) {
      addNotification("Ошибка", "Некорректный адрес эл. почты", "error", 4000);
      return;
    }

    if (!validatePassword(password)) {
      addNotification(
        "Ошибка",
        "Пароль должен быть не менее 8 символов",
        "error",
        4000
      );
      return;
    }

    if ($showCaptcha && !captchaVerified) {
      addNotification("Ошибка", "Подтвердите, что вы человек", "error", 4000);
      isAuthorized.set(false);
      loading = false;
      return;
    }

    try {
      const response = await axios.post(
        `${$API_BASE_URL}/authorise`, 
        {
          login: email,
          pass: password,
          captchaToken: $showCaptcha ? captchaToken : null,
        }
      );

      const result = response.data;
      if (result.status == 1) {
        profPic.set(result.ppic);
        preliminaryAuth.set(true);
        if (profPic != "") {
        } else {
        }
        addNotification("С возвращением, зайчик", "", "info", 3000);
        loading = false;
        setTimeout(async () => {
          userStore.set({
            token: result.jwt,
          });

          setCookie("token_clsrm", result.jwt, 180);
          await validateToken(result.jwt);

          isAuthorized.set(true);
          initConnect();
          // readjust the timeout prope
        }, 1000);
      } else {
        addNotification(
          "Ошибка",
          "При авторизации произошла ошибка. Убедитесь, что данные введены верно",
          "error",
          6000
        );
        handleFailedLogin();
        isAuthorized.set(false);
        loading = false;
      }
    } catch (error) {
      addNotification(
        "Ошибка",
        "При авторизации произошла ошибка. Убедитесь, что данные введены верно",
        "error",
        6000
      );
      isAuthorized.set(false);
      loading = false;
      handleFailedLogin();
    }
  };

  const handleFailedLogin = () => {
    failedAttempts += 1;
    if (failedAttempts >= 1) {
      showCaptcha.set(true);
    }
  };
  const validateToken = async (token) => {
    try {
      const response = await axios.post(`${$API_BASE_URL}/validateJWT`, { token });

      const result = response.data;

      if (result.status === 1) {
        userStore.set({
          email: result.data.email,
          name: result.data.name,
          student_id: result.data.student_id,
          student_status: result.data.student_status,
          profile_picture: result.data.profile_picture,
          registration_complete: result.data.registration_complete,
          course: result.data.student_course,
          token: result.data.token,
        });
        isAuthorized.set(true);
      } else {
        isAuthorized.set(false);
      }
    } catch (error) {
      console.error(
        "Error verifying token",
        error.response ? error.response.data.message : error.message
      );
      isAuthorized.set(false);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };

  // Check if captcha is required on page load
  onMount(async () => {
    try {
     // const { data } = await axios.get("/api/v2/general.php/checkCaptcha");
     // if (data.requiresCaptcha) {
     //   showCaptcha.set(true);
     // }
     console.log("C check skipped");
    } catch (error) {
      console.error("Failed to check captcha status", error);
    }
  });

</script>

{#each notifications as { header, message, type, duration }}
  <PushNotification {header} {message} {type} {duration} />
{/each}
<div class="login--auth-wrap">
  <div class="login--auth-form-wrap">
    <div class="login-auth-frame {$preliminaryAuth ? 'success-border' : ''}">
      <div
        class="profile-pic-wrapper {loading && $profPic == ''
          ? 'gradient-animation'
          : ''} {$preliminaryAuth ? 'success-border' : ''}"
        style={$profPic ? "background-image: url(" + $profPic + ")" : ""}
      ></div>
      <form class="login--auth-form" on:submit|preventDefault={handleSubmit}>
        <input 
          name="login"
          class="login--auth-input {emailError
            ? 'login--auth-input-error'
            : ''}"
          type="text"
          bind:value={email}
          bind:this={emailInput}
          placeholder={translate(
            "Адрес эл. почты",
            "Your e-mail address",
            "输入一下电邮地址"
          )}
        />
        <input
          name="pass"
          class="login--auth-input {passwordError
            ? 'login--auth-input-error'
            : ''}"
          type="password"
          bind:value={password}
          bind:this={passwordInput}
          placeholder={translate("Ваш пароль", "Your password", "输入一下密码")}
        />
        <div class="classroom--form-frame-button-wrap">
          <button
            class="user--class-generic-button-3 {!buttonActive || loading
              ? 'user--class-generic-button-3-inactive'
              : ''}"
            bind:this={loginButton}
            disabled={!buttonActive}
          >
            {#if loading}
              {translate("Входим...", "Signing in...", "登录中...")}
            {:else}
              {translate("Войти", "Sign in", "登录")}
            {/if}</button
          >
        </div>

        {#if $showCaptcha}
  <HCaptcha onVerifyCallback={handleCaptchaVerification} />
{/if}

      </form>
    </div>
  </div>
  <div class="buttons-block-auth">
    <div class="user--class-generic-button-3-tiny"  on:click={() => {
      window.open(
        "https://clsrm.org/#/signup/"
      );
    }}>Хочу заниматься</div>
    <div class="user--class-generic-button-3-tiny" on:click={() => {
      window.open(
        "https://clsrm.org/#/signupteacher/"
      );
    }}>Хочу преподавать</div>
  </div>
</div>

<Footer />

<style>
  .login--auth-form-wrap {
    width: 50%;
    margin: 0 auto;
  }
  .buttons-block-auth {
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .success-border {
    border: 3px solid rgba(61, 204, 171, 0.51);
  }
  .profile-pic-wrapper {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #ffffff;
    margin: 0 auto;
    background-size: cover;
    background-position: center;
    border: 2px solid #0c5ca450;
  }
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    25% {
      background-position: 50% 100%;
    }
    50% {
      background-position: 100% 50%;
    }
    75% {
      background-position: 50% 0%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .gradient-animation {
    background: linear-gradient(45deg, #020344, #28b8d5);
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
    border: 0px !important;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @media (max-width: 750px) {
    .login--auth-form-wrap {
      width: 100%;
    }
    .buttons-block-auth {
      width: 95%;
      flex-wrap: wrap;
    }
  }

</style>
