<script>
    import { fly, blur } from "svelte/transition";
    import { writable, get } from "svelte/store";
    import { onMount, onDestroy } from 'svelte';
    import SlideComponent from "./constructor/SlideComponent.svelte";  
    import { translate } from '../translation.js';
    import axios from 'axios';
    import {userStore, websocketData, isChatInterfaceVisible, isWhiteboardInterfaceVisible } from '../store.js';
  import Slide from "./Slide.svelte";
  import Exercise from "./Exercise.svelte";
  import Conference from '../components/Conference.svelte';
  import Note from './Note.svelte';
  import SlideControl from './SlideControl.svelte';
  import ChatInterface from "./ChatInterface.svelte";
  import Whiteboard from "./Whiteboard.svelte";
  import { sendSocketEvent } from "../websocket.js";

  let allReady = writable(false);
  if ($websocketData.sReady && $websocketData.tReady) {
  allReady.set($websocketData.sReady == 1 && $websocketData.tReady == 1);
  }
  let readinessConfirmationSent = false;
  function confirmReadiness() {
    sendSocketEvent('studentReady');
    readinessConfirmationSent = true;
  }

  // local assets 
  const readinessIcon = `<svg style="width: 100%; height: 100%" viewBox="0 0 421 215" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.19753 109.291C9.79426 103.695 18.8684 103.695 24.4652 109.291L100.32 185.145L147.512 137.954C153.109 132.357 162.183 132.357 167.779 137.954C173.376 143.551 173.376 152.625 167.779 158.222L118.563 207.438C118.562 207.438 118.561 207.439 118.561 207.44C108.487 217.516 92.1531 217.516 82.0791 207.44C82.0784 207.439 82.0778 207.438 82.0771 207.438L4.19762 129.559C-1.39917 123.962 -1.39921 114.888 4.19753 109.291Z" fill="#85ADD1"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M301.54 4.19757C307.137 9.79434 307.137 18.8685 301.54 24.4653L225.106 100.899C219.509 106.496 210.435 106.496 204.838 100.899C199.241 95.3027 199.241 86.2285 204.838 80.6318L281.272 4.19757C286.869 -1.39919 295.943 -1.39919 301.54 4.19757Z" fill="#85ADD1"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M416.191 4.19757C421.788 9.79434 421.788 18.8685 416.191 24.4653L233.213 207.443C223.138 217.519 206.806 217.519 196.73 207.443L109.295 120.008C103.698 114.411 103.698 105.337 109.295 99.7403C114.892 94.1436 123.966 94.1436 129.563 99.7403L214.972 185.149L395.924 4.19757C401.52 -1.39919 410.594 -1.39919 416.191 4.19757Z" fill="#85ADD1"/>
</svg>`;
const readinessIconGreen = `<svg style="width: 100%; height: 100%" viewBox="0 0 421 215" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.19753 109.291C9.79426 103.695 18.8684 103.695 24.4652 109.291L100.32 185.145L147.512 137.954C153.109 132.357 162.183 132.357 167.779 137.954C173.376 143.551 173.376 152.625 167.779 158.222L118.563 207.438C118.562 207.438 118.561 207.439 118.561 207.44C108.487 217.516 92.1531 217.516 82.0791 207.44C82.0784 207.439 82.0778 207.438 82.0771 207.438L4.19762 129.559C-1.39917 123.962 -1.39921 114.888 4.19753 109.291Z" fill="#2fc397"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M301.54 4.19757C307.137 9.79434 307.137 18.8685 301.54 24.4653L225.106 100.899C219.509 106.496 210.435 106.496 204.838 100.899C199.241 95.3027 199.241 86.2285 204.838 80.6318L281.272 4.19757C286.869 -1.39919 295.943 -1.39919 301.54 4.19757Z" fill="#2fc397"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M416.191 4.19757C421.788 9.79434 421.788 18.8685 416.191 24.4653L233.213 207.443C223.138 217.519 206.806 217.519 196.73 207.443L109.295 120.008C103.698 114.411 103.698 105.337 109.295 99.7403C114.892 94.1436 123.966 94.1436 129.563 99.7403L214.972 185.149L395.924 4.19757C401.52 -1.39919 410.594 -1.39919 416.191 4.19757Z" fill="#2fc397"/>
</svg>`;
 // import { initConnect, sendSocketEvent } from '../websocket.js';
    let dataLoading = true; 
  let functionIdentifier = null;
    function selectFunctionIdentifier(id) {
      if (id == functionIdentifier) {
        functionIdentifier = null;
      }
       else {
        functionIdentifier = id;
        if (id == 4) {
          isChatInterfaceVisible.set(true);
        }
        if (id == 3) {
          isWhiteboardInterfaceVisible.set(true);
        }
       } 
       
    }

    $: console.log($websocketData);
    $: {
    if ($websocketData && typeof $websocketData === 'object' && Object.keys($websocketData).length > 0) {
        dataLoading = false;
        allReady.set($websocketData.sReady == 1 && $websocketData.tReady == 1);
        if (!$websocketData.isTeacher && $websocketData.sReady == 1) {
            readinessConfirmationSent = true;
        } else if ($websocketData.isTeacher && $websocketData.tReady == 1) {
            readinessConfirmationSent = true;
    }

    if(!$isChatInterfaceVisible && functionIdentifier == 4) { functionIdentifier = null;}
    if(!$isWhiteboardInterfaceVisible && functionIdentifier == 3) { functionIdentifier = null;}

  //   if ($websocketData.conferenceUrl && !callInitialized) {
  //       roomUrl = $websocketData.conferenceUrl;
  //       startVideoCall(roomUrl); 
  //       callInitialized = true;   
  //       console.log('roomUrl', roomUrl);
  //       console.log('call initialised');
  //     }
  // }
    }

   
}
function openBoard() {
      window.open(`https://wb.alexanderkireev.org/#room=${$websocketData.wbroom}`, "_blank");
    }

</script>

<div class="user--block-expanded-wrapper" in:blur={{ duration: 500 }} out:blur={{ duration: 500}}>
    {#if dataLoading}
    <div class="loading-huge jello-horizontal"></div>
    {:else}

    {#if !$allReady}
    <div class="verify-readiness-wrap">
    <div class="verify-readiness-header">Подготовка к занятию</div>
    {#if !readinessConfirmationSent}
    <div class="verify-readiness-icon">{@html readinessIcon}</div>
    <div class="verify-readiness-descriptor">Подтвердите готовность к этому занятию, нажав на кнопку “Подтведить”:</div>
    <div class="user--class-generic-button-3" on:click={confirmReadiness}>Подтвердить</div>
    {:else}
    <div class="verify-readiness-icon">{@html readinessIconGreen}</div>
    <div class="verify-readiness-descriptor" style="color: #2fc397;">Вы подтвердили готовность к занятию</div>
    {/if}
  </div>
    {:else}
    
    <div class="section-selector">
      {#if $userStore.student_status == 0}
        <div
          class="section-select-button {functionIdentifier === 1 ? 'active' : ''}"
          on:click={() => selectFunctionIdentifier(1) }
        >
         Слайды
        </div>
        {/if}
        <div
          class="section-select-button {functionIdentifier === 2 ? 'active' : ''}"
          on:click={() => selectFunctionIdentifier(2)}
        >
          Заметка
        </div>
         <div
        class="section-select-button {functionIdentifier === 3 ? 'active' : ''}"
        on:click={() => openBoard()}
      >
        Доска
      </div>
      <div
        class="section-select-button {functionIdentifier === 4 ? 'active' : ''}"
        on:click={() => selectFunctionIdentifier(4)}
      >
        Интеллект
      </div>
      </div>



      <!-- SLIDE RENDER -->

      {#if $websocketData && $websocketData.slideData.isExercise && $websocketData.slideData.isExercise == 'true'} 
      <Exercise slideData={$websocketData.slideData} exerciseID={1} location="classroom" completedData={$websocketData.completedData} />
      {:else if $websocketData && $websocketData.slideData.type && $websocketData.type == 'slide'}
      <Slide slideData={$websocketData.slideData} />
      {/if}
      

      {#if functionIdentifier === 1 && $userStore.student_status == 0}
      <SlideControl />
        {:else if functionIdentifier === 2}
        <Note />
         {:else if functionIdentifier === 3}
         <Whiteboard wbIdentifier="{$websocketData.wbroom}" /> 
        {:else if functionIdentifier === 4}
        <ChatInterface />
    {/if}
    <Conference classId="{$websocketData.conferenceUrl}" />
    {/if}
    {/if}
</div>



<style>
    .section-selector {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    width: max-content;
    height: max-content;
    padding: 10px;
    border-radius: 50px;
    background-color: #0c5ca420;
  }

  .section-select-button {
    width: max-content;
    height: max-content;
    padding-block: 15px;
    padding-inline: 25px;
    background-color: #ffffff;
    color: rgba(12, 85, 152, 0.8);
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    border-radius: 100px;
    margin: 0 auto;
    transition: all 0.2s linear;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .active {
    background-color: #0c5ca460;
    color: #ffffff;
  }
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  .verify-readiness-wrap {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
  .verify-readiness-header {
    font-size: 35px;
    font-weight: 700;
    color: #000000;
  }
  .verify-readiness-icon {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .verify-readiness-descriptor {
    font-size: 22px;
    font-weight: 350;
    color: #5c5c5c;
    width: max-content;
  }
  @media (max-width: 750px) {
  .section-selector {
      width: 100%;
      border-radius: 20px;
    }
  }
</style>