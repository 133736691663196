// src/translations.js
import { get } from 'svelte/store';
import { language } from './store';

export function translate(...args) {
    const userLanguage = get(language);
    const languages = ['ru', 'en', 'zh']; 
    const languageIndex = languages.indexOf(userLanguage);
    return (languageIndex !== -1 && args[languageIndex]) ? args[languageIndex] : args[0];
}
