<script>
    import { onMount } from "svelte";
    import { writable } from "svelte/store";
    import { userStore, API_BASE_URL } from '../store.js';
    import { translate } from '../translation.js';
    import axios from "axios";
  
    // export let slideIndex;
    // export let miniatureWrapper;
    export let courseId;
    export let classNumber;
  
    let contentArea = writable([]);
    let description = writable('');
    let loadingWordData = false;
    let loadingContentUnitData = false;
    let selectedSection = 1;
    let slidePoints = {};
    let uploadingData = false;
    let words = {};
    const addSlideButton = `<svg style="width: 100%; height: 100%" viewBox="0 0 48 48" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.2413 10.2413C2.44841 18.0342 2.44841 30.669 10.2413 38.4619C18.0343 46.2548 30.669 46.2548 38.4619 38.4619C46.2549 30.6689 46.2549 18.0342 38.4619 10.2413C30.669 2.44837 18.0343 2.44831 10.2413 10.2413ZM7.6758 41.0274C-1.53399 31.8176 -1.53399 16.8855 7.6758 7.67576C16.8857 -1.53409 31.8176 -1.53402 41.0274 7.67576C50.2372 16.8856 50.2372 31.8175 41.0274 41.0274C31.8176 50.2371 16.8857 50.2372 7.6758 41.0274ZM8.8548 24.3516C8.8548 23.3497 9.667 22.5375 10.6689 22.5375L17.5102 22.5375C18.5121 22.5375 19.3243 23.3497 19.3243 24.3516C19.3243 25.3535 18.5121 26.1657 17.5102 26.1657L10.6689 26.1657C9.667 26.1657 8.8548 25.3535 8.8548 24.3516ZM24.3516 8.85477C25.3535 8.85477 26.1657 9.66696 26.1657 10.6689L26.1657 22.5375H38.0343C39.0362 22.5375 39.8484 23.3497 39.8484 24.3516C39.8484 25.3535 39.0362 26.1657 38.0343 26.1657H26.1657L26.1657 38.0343C26.1657 39.0362 25.3535 39.8484 24.3516 39.8484C23.3497 39.8484 22.5375 39.0362 22.5375 38.0343V10.6689C22.5375 9.66696 23.3497 8.85477 24.3516 8.85477Z" fill="#0C5CA4"/></svg>`;

  
    function toggleSection(secNum) {
      selectedSection = secNum;
      if (secNum === 1) {
        description.set(translate('Содержание занятий помогает учащимся и ученикам лучше ориентироваться в проходимом материале', 'The content of the lessons helps students to better navigate the material covered', '课程内容帮助学生更好地了解所学的材料'));
        renderBlockOne();
      } else {
        description.set(translate('Определите список слов для текущего занятия, который будет доступен ученику для изучения и повторения', 'Define a list of words for the current lesson that will be available to the student for study and repetition', '为当前课程定义一个单词列表，学生可以学习和重复'));
        renderWordContents();
      }
    }
  
    function renderBlockOne() {
      loadingContentUnitData = true;
      axios.post(`${$API_BASE_URL}/fetchSlideInfo`, {
        course_id: courseId,
        class_number: classNumber,
        token: $userStore.token
      }).then(response => {
        if (response.data.result === 'success') {
          contentArea.set(response.data.message);
          loadingContentUnitData = false;
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      }).catch(error => console.error('Fetch error:', error));
    }
  
    function renderWordContents() {
      loadingWordData = true;
      axios.post(`${$API_BASE_URL}/fetchSlideInfoWords`, {
        course_id: courseId,
        class_number: classNumber,
        token: $userStore.token
      }).then(response => {
        if (response.data.result === 'success') {
          contentArea.set(response.data.data);
          loadingWordData = false;
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      }).catch(error => console.error('Fetch error:', error));
    }
  
    function updateInfo() {
      uploadingData = true;
      const payload = {
        slidePoints,
        words,
        course_id: courseId,
        class_number: classNumber,
        token: $userStore.token
      };
      axios.post(`${$API_BASE_URL}/updateSlideInfo`, payload)
        .then(response => {
          if (response.data.result === 'success') {
            toggleSection(selectedSection);
            uploadingData = false;
          } else {
            console.error('Error updating info:', response.data.message);
          }
        }).catch(error => console.error('Update error:', error));
    }
  
    function addNewContentOption() {
  if (typeof slidePoints !== 'object' || slidePoints === null) {
    slidePoints = {};
  }

  const newContent = { point_order: Object.keys(slidePoints).length + 1, point_value: '' };
  slidePoints[newContent.point_order] = newContent.point_value;
  contentArea.update(n => [...n, newContent]);
}

  
    function addNewWordOption() {
      const newWordId = Object.keys(words).length + 1;
      const newWord = { word: '', pinyin: '', translation: '', sentence: '', assocStory: '', id: newWordId };
      words[newWordId] = newWord;
      contentArea.update(n => [...n, newWord]);
    }
  
    function handleContentInput(event, id) {
      slidePoints[id] = event.target.value;
    }
  
    function handleWordInput(event, id, key) {
      words[id][key] = event.target.value;
    }
  
    onMount(() => {
      toggleSection(1);
    });
  </script>
  
  <div class="user--traverse-info-selection">
    <div class="section-selector">
      <div class="section-select-button {selectedSection === 1 ? "active" : ''}" on:click={() => toggleSection(1)}>
        {translate('Содержание', 'Contents', '内容')}
      </div>
      <div class="section-select-button {selectedSection === 2 ? "active" : ''}" on:click={() => toggleSection(2)}>
        {translate('Слова', 'Words', '单词')}
      </div>
    </div>
    <span class="section-descriptor">{$description}</span>
    <div class="user--traverse-info-selection-content">
      {#if selectedSection === 1}
      {#if loadingContentUnitData}
          <div class="loading-huge jello-horizontal"></div>
          {:else}
        {#each $contentArea as content}
          <div class="user--traverse-info-inside-block-option">
            <h6>{content.point_order}</h6>
            <textarea rows="1" placeholder={translate('Единица содержимого занятия', 'Lesson content unit', '课程内容单元')} bind:value={content.point_value} on:input={(event) => handleContentInput(event, content.point_order)}></textarea>
          </div>
        {/each}
        {/if}
        <div class="user--traverse-info-inside-block-newopt" on:click={addNewContentOption}>
          {@html addSlideButton}
        </div>
      {:else if selectedSection === 2}
      {#if loadingWordData}
          <div class="loading-huge jello-horizontal"></div>
          {:else}
        {#each $contentArea as word}
          <div class="user--traverse-info-word-wrapper">
            <input placeholder={translate('Слово', 'Word', '单词')} value={word.word} on:input={(event) => handleWordInput(event, word.id, 'word')} style="font-weight: 550;">
            <input placeholder="Pinyin" value={word.pinyin} on:input={(event) => handleWordInput(event, word.id, 'pinyin')}>
            <input placeholder={translate('Перевод', 'Translation', '翻译')} value={word.translation} on:input={(event) => handleWordInput(event, word.id, 'translation')}>
            <textarea placeholder={translate('Пример в предложении', 'Usage in a sentence', '句子示例')} value={word.sentence} on:input={(event) => handleWordInput(event, word.id, 'sentence')}></textarea>
            <textarea placeholder={translate('Текстовая ассоциация', 'Textual association', '文本联想')} value={word.assocStory} on:input={(event) => handleWordInput(event, word.id, 'assocStory')}></textarea>
          </div>
        {/each}
        <div class="user--traverse-info-inside-block-newopt" on:click={addNewWordOption}>
          {@html addSlideButton}
        </div>
      {/if}
      {/if}
    </div>
    {#if uploadingData}
      <div class="loading-huge jello-horizontal"></div>
    {:else}
    <div class="user--class-generic-button-3-tiny" on:click={updateInfo}>
      {translate('Обновить', 'Update', '更新')}
    </div>
    {/if}
  </div>
  
  <style>
  .active {
    background-color: #0c5ca460 !important;
    color: #ffffff !important;
  }
    .section-selector {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: max-content;
    height: max-content;
    padding: 10px;
    border-radius: 50px;
    background-color: #0c5ca420;
  }
  .section-select-button {
    width: max-content;
    height: max-content;
    padding-block: 15px;
    padding-inline: 25px;
    background-color: #ffffff;
    color: rgba(12, 85, 152, 0.8);
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    border-radius: 100px;
    margin: 0 auto;
    transition: all 0.2s linear;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .section-descriptor {
    font-size: 22px;
    font-weight: 350;
    color: #5c5c5c;
  }
  .user--traverse-info-inside-block-newopt {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  .user--traverse-info-inside-block-option textarea {
    text-align: center;
  }
  </style>
  