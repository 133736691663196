<script>
    import { writable, derived, get } from 'svelte/store';
    import { sendSocketEvent } from "../websocket.js";
    import {userStore, highlightAnswer} from '../store.js';
    import { onMount } from "svelte";
  

    const iconSubmitted = `<svg fill="#4CBB9A" style="width: 100%; height: 100%" viewBox="0 0 32 32"><path d="m16 0c8.836556 0 16 7.163444 16 16s-7.163444 16-16 16-16-7.163444-16-16 7.163444-16 16-16zm0 2c-7.7319865 0-14 6.2680135-14 14s6.2680135 14 14 14 14-6.2680135 14-14-6.2680135-14-14-14zm6.6208153 9.8786797c.3905243.3905242.3905243 1.0236892 0 1.4142135l-7.0710678 7.0710678c-.3626297.3626297-.9344751.3885319-1.3269928.0777064l-.0872208-.0777064-4.24264068-4.2426407c-.39052429-.3905242-.39052429-1.0236892 0-1.4142135.39052428-.3905243 1.02368928-.3905243 1.41421358 0l3.5348268 3.5348268 6.3646681-6.3632539c.3905243-.3905243 1.0236893-.3905243 1.4142136 0z"/></svg>`;

  export let slideData;
  export let exerciseID;
  export let location; // 'revision', 'homework', or 'classroom'
  export let completedData; // Data from previous attempts
  let partScore = 0;
  let maxScore = 100; // Max score for the exercise
  let hintExpanded = writable(false);

  // Initialize slideData properties if they don't exist
  slideData.hint = slideData.hint || new Array(slideData.answer.length).fill('');
  slideData.pictureArray = slideData.pictureArray || [];
  slideData.textArray = slideData.textArray || [];

  let selectedMatchSequence = writable(new Array(slideData.answer.length).fill(''));
  let filledInputs = writable(0);
  let answerFeedback = writable(new Array(slideData.answer.length).fill(null));
  let submitted = writable(false);
  let imageLoading = writable(slideData.pictureArray.map(() => true));

  function expandHint() {
    hintExpanded.set(!$hintExpanded);
  }

// Handle user input for matching sequence and send WebSocket event
function handleInput(index, value) {
  selectedMatchSequence.update(answers => {
    if (answers[index] === '' && value !== '') {
      filledInputs.update(n => n + 1);
    } else if (answers[index] !== '' && value === '') {
      filledInputs.update(n => n - 1);
    }
    answers[index] = value;

    // WebSocket event details for dynamic highlighting
    const eventDetails = {
  actionName: {
    kind: "match",              // The type of the action is 'match'
    blockIndex: index,          // The index in the matching sequence
    answer: value,              // The value entered by the student
    id: exerciseID,             // The exercise ID
  },
  isAllCorrect: false,           // Set to false initially, will update after feedback logic
  answers: get(selectedMatchSequence).map(ans => ans ? ans.toLocaleLowerCase('ru') : '')
};


    // Send the WebSocket event to the teacher panel
    sendSocketEvent('studentAnswerDynamic', eventDetails);

    return answers;
  });
}


$: if ($highlightAnswer.id === exerciseID) {
  console.log('[DEBUG] -- highlight answer', $highlightAnswer.answer, $highlightAnswer.blockIndex);
  
  const eventAnswer = $highlightAnswer.answer;
  const blockIndex = $highlightAnswer.blockIndex;
  
  // Ensure that the blockIndex is valid and slideData.answer[blockIndex] exists
  if (slideData.answer[blockIndex] && typeof slideData.answer[blockIndex] === 'string') {
    
    // Safely update the feedback based on blockIndex and eventAnswer
    answerFeedback.update(af => {
      af[blockIndex] = eventAnswer === slideData.answer[blockIndex].toLocaleLowerCase('ru') ? 'correct' : 'incorrect';
      return af;
    });
    
    // Handle user input with the highlighted answer
    handleInput(blockIndex, eventAnswer);
  } else {
    console.error(`Invalid blockIndex or answer: blockIndex = ${blockIndex}, answer = ${slideData.answer[blockIndex]}`);
  }
}

  // Check if all fields are filled
  function checkIfAllFieldsFilledForMatch() {
    return get(selectedMatchSequence).every(field => field && field.trim() !== '');
  }

  // Check matching answers and calculate score
  function checkMatchAnswers(correctAnswers, userAnswers) {
    let totalQuestions = 0;
    let totalScore = 0;

    for (let i = 0; i < correctAnswers.length; i++) {
      const correctAnswer = correctAnswers[i] ? correctAnswers[i].trim().toLowerCase() : '';
      const userAnswer = userAnswers[i] ? userAnswers[i].trim().toLowerCase() : '';

      if (correctAnswer) {
        totalQuestions++;
        let matchingWords = 0;

        const correctSplit = correctAnswer.split('<br>');
        const userSplit = userAnswer.split(/<br>|[\s,;]+/);

        if (correctSplit.length > 1) {
          if (userSplit.some(part => part === correctSplit[0]) || correctAnswer === userAnswer) {
            matchingWords = correctSplit.length;
          }
        } else {
          if (userAnswer === correctAnswer) {
            matchingWords = 1;
          }
        }

        // Proportional score for each question
        const scoreForThisQuestion = (matchingWords / correctSplit.length) * maxScore / correctAnswers.length;
        totalScore += scoreForThisQuestion;
      }
    }

    return { totalQuestions, totalScore };
  }

  // Submit answers and send the socket event
  function submitAnswers() {
    const correctAnswers = slideData.answer.map(answer => answer.toLocaleLowerCase('ru'));
    const userAnswers = get(selectedMatchSequence).map(answer => answer.toLocaleLowerCase('ru'));
    const { totalQuestions, totalScore } = checkMatchAnswers(correctAnswers, userAnswers);

    // Update the answer feedback
    answerFeedback.update(af => {
      for (let i = 0; i < correctAnswers.length; i++) {
        af[i] = userAnswers[i] === correctAnswers[i] ? 'correct' : 'incorrect';
      }
      return af;
    });

    // Set the part score based on totalScore
    partScore = totalScore;

    // Mark the exercise as submitted
    submitted.set(true);

    // Determine the correct endpoint
    let endpoint;
    if (location === "revision") {
      endpoint = "exerciseCompletedRevision";
    } else if (location === "homework") {
      endpoint = "exerciseCompletedRevision";
    } else if (location === "classroom") {
      endpoint = "exerciseCompleted";
    }

    // Prepare the event details payload
    const eventDetails = {
      actionName: {
        exerciseID: exerciseID,
        isCorrect: totalScore === maxScore ? 'true' : 'false', // Check if all answers are correct
        isCreative: false,
        partialScore: partScore // Proportional partScore for the exercise
      },
      answers: userAnswers, // User's answers
      totalQuestions: totalQuestions, // Total number of questions
      totalScore: totalScore // Total score across questions
    };

    // Send the socket event with the payload
    sendSocketEvent(endpoint, eventDetails);
  }

  // Derived store to track if all fields are filled
  const allAnswered = derived(selectedMatchSequence, $selectedMatchSequence => {
    return $selectedMatchSequence.every(answer => answer !== '');
  });

  let tipsWithClass = derived(selectedMatchSequence, $selectedMatchSequence => {
    const lowercasedAnswers = $selectedMatchSequence.map(answer => answer.toLowerCase());
    return slideData.answer.map(answer => {
      return lowercasedAnswers.includes(answer.toLowerCase('ru')) ? 'coloured' : '';
    });
  });

  // Populate answers and feedback from completedData if available
  onMount(() => {
    if (completedData && completedData.studentAnswer != null) {
      try {
        const parsedStudentAnswer = JSON.parse(completedData.studentAnswer);
        if (Array.isArray(parsedStudentAnswer)) {
          parsedStudentAnswer.forEach((answer, index) => {
            handleInput(index, answer.toLocaleLowerCase("ru"));
          });

          // Update feedback based on pre-filled answers
          answerFeedback.update(af => {
            const ua = get(selectedMatchSequence);
            for (let i = 0; i < slideData.answer.length; i++) {
              if (ua[i] && ua[i].toLocaleLowerCase("ru") === slideData.answer[i].toLocaleLowerCase("ru")) {
                af[i] = "correct";
              } else {
                af[i] = "incorrect";
              }
            }
            return af;
          });

          submitted.set(true);
        }
      } catch (error) {
        console.error("Error parsing completedData studentAnswer:", error);
      }
    }
  });

   // Proceed to the next step in the exercise
   function proceedNext() {
    sendSocketEvent('proceedSelf', '');
  }

  </script>
  
  <div class="user--class-exercise-header">
    {@html slideData.textContent}
  </div>
  {#if slideData.hint && slideData.hint[0] && $hintExpanded}
          <div class="user--exercise-hint-mini">
            {slideData.hint[0]}
          </div>
        {/if}
  <div class="user--match-exercise">
    {#if slideData.displayTips && slideData.displayTips === "true"}
    <div class="user--exercise-multiple_sequence_sdisplay">
      {#each Array.from(new Set(slideData.answer)) as answer, index}
        <span class="user--exercise-multiple_sequence_hint {$tipsWithClass[index]}" data-repetitions={slideData.answer.filter(a => a === answer).length}>
          {answer}
        </span>
      {/each}
    </div>
  {/if}
    <div class="user--exercise-match-container">
       
      {#each (slideData.pictureArray || slideData.textArray) as item, index}
        <div class="user--exercise-item-wrapper" data-item-wrapper={index} style="border: 3px solid {$answerFeedback[index] === 'correct' ? 'rgba(135, 194, 177, 1)' : $answerFeedback[index] === 'incorrect' ? 'rgba(255, 147, 147, 1)' : 'transparent'};">
          {#if slideData.pictureArray}
          <div class="image-wrapper">
            {#if $imageLoading[index]}
              <div class="image-loader"></div>
            {/if}
            <img
              src={item}
              alt="Match Image"
              class="user--exercise-match-image"
              on:load={() => imageLoading.update(loads => { loads[index] = false; return loads; })}
              on:error={() => imageLoading.update(loads => { loads[index] = false; return loads; })}
              style="display: { $imageLoading[index] ? 'none' : 'block' };"
            />
          </div>
          {:else}
            <!-- <p class="user--exercise-match-description">{item}</p> -->
          {/if}
          {#if slideData.textArray && slideData.textArray[index]}
            <div class="user--class-exercise-match-desc">{slideData.textArray[index]}</div>
          {/if}
          <input
            type="text"
            class="user--class-exercise-match-input"
            data-input-index={index}
            placeholder="Ответ"
            bind:value={$selectedMatchSequence[index]} 
            on:input={(event) => handleInput(index, event.target.value)}
          />
        </div>
      {/each}
    </div>
    
    <div class="user--class-exercise-buttons-wrap">

      {#if $submitted}
      {#if location == "classroom"}
        <div class="submitted-icon">{@html iconSubmitted}</div>
      {:else if (location == "homework" || location == "revision")}
      <button class="user--class-generic-button-3"  on:click={(event) => {
        event.currentTarget.classList.add('inactive');
        event.currentTarget.textContent = 'Загружаю...';
        proceedNext();
      }}
      >Продолжить</button
    >
      {/if}
        {:else}
    {#if $userStore.student_status != 0}
    <button on:click={submitAnswers} class="user--class-generic-button-3 {$allAnswered ? '' : 'user--button-clicked'}">Ответить</button>
    {/if}
    {/if}

    {#if slideData.hint && slideData.hint.some((hint) => hint !== "")}
    <button class="user--class-generic-button-3" on:click={expandHint}
      >Подсказка</button
    >
  {/if}
  </div>
  </div>
  
  <style>
    .user--exercise-item-wrapper {
      transition: border-color 0.3s;
    }
    .coloured {
       border: 2px solid #0C5CA4;
    }
    .centered-div {
      width: 60px;
      height: 60px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .loading-huge {
      width: 60px;
      height: 60px;
      background: linear-gradient(45deg, #020344, #28b8d5);
      border-radius: 50%;
      position: relative;
    }
    .jello-horizontal {
      -webkit-animation: jello-horizontal 0.9s infinite both;
      animation: jello-horizontal 0.9s infinite both;
    }
    .submitted-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    }
    @-webkit-keyframes jello-horizontal {
      0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
      30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
      }
      40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
      }
      50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
      }
      65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
      }
      75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
      }
      100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    }
    @keyframes jello-horizontal {
      0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
      30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
      }
      40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
      }
      50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
      }
      65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
      }
      75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
      }
      100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    }
  </style>
  