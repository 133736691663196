<script>
  import { fly, blur } from "svelte/transition";
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { translate } from "../translation";
  import { currentSection } from "../store.js";
  import axios from "axios";
  import Cookies from "js-cookie";
  import { writable, get } from "svelte/store";
  import PushNotification from "./PushNotification.svelte";
  import CreateExercise from "./CreateExercise.svelte";
  import CreateSlide from "./CreateSlide.svelte";

  const selectedComponent = writable(null);
  export let locationType;
  export let constructorObject;
  // Function to set the selected component
  function selectComponent(component) {
    selectedComponent.set(component);
  }
  let isMobile = false;

if (typeof window !== "undefined") {
  isMobile = window.innerWidth < 800;
  window.addEventListener("resize", () => {
    isMobile = window.innerWidth < 800;
  });
}
</script>

<div
  class={$selectedComponent != null
    ? "constructor-rendered-main"
    : "user--linkblock-clear"}
>
  <!-- COMP -->
  {#if $selectedComponent === null}
    <div
      class="user--linkblock-wrap height-regulator"
      on:click={() => selectComponent("CreateExercise")}
    >
      <div class="user--linkblock-wrap-h1" style="background-color: #0c5ca460">
        Упражнение
      </div>
      <div class="user--linkblock-inside">
        <div class="user--linkblock-wrap-inner-layer" style="{isMobile ? 'background-color: transparent' : ''}">
          <div class="user--linkblock-wrap-inner-layer-icon">
            <svg viewBox="0 0 57 55" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.2754 49.125C23.2754 47.8133 24.2601 46.75 25.4748 46.75H54.8009C56.0157 46.75 57.0004 47.8133 57.0004 49.125C57.0004 50.4367 56.0157 51.5 54.8009 51.5H25.4748C24.2601 51.5 23.2754 50.4367 23.2754 49.125Z"
                fill="#69D8A3"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.2754 28.4625C23.2754 27.2819 24.2601 26.325 25.4748 26.325H54.8009C56.0157 26.325 57.0004 27.2819 57.0004 28.4625C57.0004 29.643 56.0157 30.6 54.8009 30.6H25.4748C24.2601 30.6 23.2754 29.643 23.2754 28.4625Z"
                fill="#69D8A3"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.2754 8.0374C23.2754 6.85689 24.2601 5.8999 25.4748 5.8999H54.8009C56.0157 5.8999 57.0004 6.85689 57.0004 8.0374C57.0004 9.21791 56.0157 10.1749 54.8009 10.1749H25.4748C24.2601 10.1749 23.2754 9.21791 23.2754 8.0374Z"
                fill="#69D8A3"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.505 0.849198C16.365 1.71486 16.365 3.11838 15.505 3.98404L6.69588 12.8507C5.83584 13.7164 4.44144 13.7164 3.58139 12.8507L0.645031 9.89515C-0.21501 9.02949 -0.21501 7.62597 0.645031 6.76031C1.50507 5.89465 2.89947 5.89465 3.75951 6.76031L5.13864 8.14844L12.3905 0.849198C13.2505 -0.0164644 14.6449 -0.0164644 15.505 0.849198Z"
                fill="#69D8A3"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.505 21.2742C16.365 22.1399 16.365 23.5434 15.505 24.4091L6.69588 33.2758C5.83584 34.1414 4.44144 34.1414 3.58139 33.2758L0.645031 30.3202C-0.21501 29.4545 -0.21501 28.051 0.645031 27.1854C1.50507 26.3197 2.89947 26.3197 3.75951 27.1854L5.13864 28.5735L12.3905 21.2742C13.2505 20.4086 14.6449 20.4086 15.505 21.2742Z"
                fill="#69D8A3"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.505 41.6993C16.365 42.565 16.365 43.9685 15.505 44.8341L6.69588 53.7008C5.83584 54.5665 4.44144 54.5665 3.58139 53.7008L0.645031 50.7452C-0.21501 49.8796 -0.21501 48.4761 0.645031 47.6104C1.50507 46.7447 2.89947 46.7447 3.75951 47.6104L5.13864 48.9985L12.3905 41.6993C13.2505 40.8336 14.6449 40.8336 15.505 41.6993Z"
                fill="#69D8A3"
              ></path>
            </svg>
          </div>
          <div class="user--linkblock-wrap-inner-column">
            <span class="user--linkblock-wrap-inner-desc"
              >Упражнения помогают проверить и закрепить знания учащегося по
              определенной теме во время занятий или во внеклассное время</span
            >
            <div class="user--class-generic-button-3">Создать</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="user--linkblock-wrap height-regulator"
      on:click={() => selectComponent("CreateSlide")}
    >
      <div class="user--linkblock-wrap-h1" style="background-color: #0c5ca460">
        Слайд
      </div>
      <div class="user--linkblock-inside">
        <div class="user--linkblock-wrap-inner-layer" style="{isMobile ? 'background-color: transparent' : ''}">
          <div class="user--linkblock-wrap-inner-layer-icon">
            <svg viewBox="0 0 61 49" fill="none">
              <path
                d="M0.0839844 8.53133C0.0839844 3.91171 3.82895 0.166748 8.44857 0.166748H52.5527C57.1724 0.166748 60.9173 3.91171 60.9173 8.53133V40.4688C60.9173 45.0885 57.1724 48.8334 52.5527 48.8334H8.44857C3.82895 48.8334 0.0839844 45.0885 0.0839844 40.4688V8.53133ZM14.5319 12.3334C13.272 12.3334 12.2507 13.3548 12.2507 14.6147C12.2507 15.8746 13.272 16.8959 14.5319 16.8959H28.2194C29.4793 16.8959 30.5006 15.8746 30.5006 14.6147C30.5006 13.3548 29.4793 12.3334 28.2194 12.3334H14.5319ZM14.5319 21.4584C13.272 21.4584 12.2507 22.4798 12.2507 23.7397C12.2507 24.9995 13.272 26.0209 14.5319 26.0209H40.3861C41.6459 26.0209 42.6673 24.9995 42.6673 23.7397C42.6673 22.4798 41.6459 21.4584 40.3861 21.4584H14.5319ZM12.2507 32.8647C12.2507 34.1245 13.272 35.1459 14.5319 35.1459H34.3027C35.5626 35.1459 36.584 34.1245 36.584 32.8647C36.584 31.6048 35.5626 30.5834 34.3027 30.5834H14.5319C13.272 30.5834 12.2507 31.6048 12.2507 32.8647Z"
                fill="#8973DF"
              ></path>
            </svg>
          </div>
          <div class="user--linkblock-wrap-inner-column">
            <span class="user--linkblock-wrap-inner-desc"
              >Слайды содержат в себе информацию по определенной теме, которая
              будет показана учащимся во время занятий</span
            >
            <div class="user--class-generic-button-3">Создать</div>
          </div>
        </div>
      </div>
    </div>
  {:else if $selectedComponent === "CreateExercise"}
    <CreateExercise {locationType} {constructorObject} returnToSelect={(val) => selectedComponent.set(val)}/>
  {:else if $selectedComponent === "CreateSlide"}
    <CreateSlide {locationType} {constructorObject} returnToSelect={(val) => selectedComponent.set(val)}/>
  {/if}
</div>

<style>
  .constructor-rendered-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  .height-regulator {
    height: max-content !important;
    background: #ffffff !important;
    border: 3px solid #0c5ca420;
  }
  @media (max-width: 750px) { 
    .height-regulator {
      width: 100% !important;
    }
    .constructor-rendered-main { 
      gap: 5px;
    }
  }
</style>
