<script>
    import { writable, get } from 'svelte/store';
    import {userStore, trackContentUpload, exerciseStore, API_BASE_URL} from '../../store.js'
    export let selectedExercise;
    export let existingData = null;
    import { onMount, onDestroy } from 'svelte';
    export let locationType;
import axios from 'axios'; 
export let constructorObject;
export let editObject;
export let editExerciseID;
let uploading = false;
let exerciseData;
let imageUploading = false;
  
    const resetSelectedExercise = () => {
      selectedExercise.set(null);
    };
  
    const exerciseHeader = writable("");
    const slideNameInput = writable("");
    const mediaData = writable({});
    const questionBlock = writable({ miniBlocks: [] });
  
    // const classNumber = 1;
    // const slideNumber = 1;
    // const slideSection = 1;
    // const slideCourse = 1;
    const assignAward = "false";
    const badgeDetails = null;

    const plusIcon = `
<svg style="width: 100%; height: 100%;" viewBox="0 0 32 32" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-466.000000, -1089.000000)" fill="#ffffff">
            <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle" sketch:type="MSShapeGroup">

</path>
        </g>
    </g>
</svg>`;


const returnBtn = `<svg style="width: 100%; height: 100%" viewBox="0 0 48 48" fill="none" >
<path d="M12.9998 8L6 14L12.9998 21" stroke="#85ADD1" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 14H28.9938C35.8768 14 41.7221 19.6204 41.9904 26.5C42.2739 33.7696 36.2671 40 28.9938 40H11.9984" stroke="#85ADD1" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

  const minusIcon = `<svg style="width: 100%; height: 100%;" viewBox="0 0 32 32" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-518.000000, -1089.000000)" fill="#ffffff">
            <path d="M540,1106 L528,1106 C527.447,1106 527,1105.55 527,1105 C527,1104.45 527.447,1104 528,1104 L540,1104 C540.553,1104 541,1104.45 541,1105 C541,1105.55 540.553,1106 540,1106 L540,1106 Z M534,1089 C525.163,1089 518,1096.16 518,1105 C518,1113.84 525.163,1121 534,1121 C542.837,1121 550,1113.84 550,1105 C550,1096.16 542.837,1089 534,1089 L534,1089 Z" id="minus-circle" sketch:type="MSShapeGroup">

</path>
        </g>
    </g>
</svg>`;

  const uploadIcon = `<svg style="width: 100%; height: 100%" viewBox="0 0 24 24" fill="none">
<path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#ffffff"/>
</svg>`;
  
    function initializeExistingData(data) {
      slideNameInput.set(data.slideName || "Выполните задание");
      exerciseHeader.set(data.contents[0].textContent || "");
      questionBlock.set({
        miniBlocks: data.contents[0].pictureArray.map((picture, index) => ({
          image: picture,
          descriptor: data.contents[0].textArray[index] || "",
          answer: data.contents[0].answer[index] || ""
        }))
      });
      mediaData.set(data.contents[0].mediaData || {});
    }
  
    if (editObject) {
      initializeExistingData(editObject);
    } else {
      addMiniBlock(); // Initialize with one default mini-block
    }
  
    function addMiniBlock() {
      questionBlock.update(block => {
        block.miniBlocks.push({ image: null, descriptor: "", answer: "" });
        return block;
      });
    }
  
    function removeMiniBlock(miniIndex) {
      questionBlock.update(block => {
        block.miniBlocks.splice(miniIndex, 1);
        return block;
      });
    }
  
    function handleMiniDescriptorInput(miniIndex, value) {
      questionBlock.update(block => {
        block.miniBlocks[miniIndex].descriptor = value;
        return block;
      });
    }
  
    function handleMiniAnswerInput(miniIndex, value) {
      questionBlock.update(block => {
        block.miniBlocks[miniIndex].answer = value;
        return block;
      });
    }
  
    function handleMiniFileChange(event, miniIndex) {
    imageUploading = true;
    const file = event.target.files[0];
    if (file) {
        const formData = new FormData();
        formData.append('slide_image', file);
        formData.append('token', $userStore.token);

        axios.post(`${$API_BASE_URL}/uploadImage`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            if (response.data.status === 'success') {
                questionBlock.update(block => {
                    block.miniBlocks[miniIndex].image = response.data.url;
                    imageUploading = false;
                    return block;
                });
            } else {
                console.error('Image upload failed:', response.data.message);
            }
        })
        .catch(error => {
            console.error('Error uploading image:', error);
        });
    }
}

  
    function submitExercise() {
      uploading = true;
      const descriptors = [];
      const answers = [];
      const pictureArrays = [];
  
      get(questionBlock).miniBlocks.forEach(miniBlock => {
        descriptors.push(miniBlock.descriptor);
        answers.push(miniBlock.answer);
        pictureArrays.push(miniBlock.image);
      });
  
      exerciseData = {
        isExercise: "true",
        token: $userStore.token,
        exerciseID: editExerciseID ? editExerciseID : "",
        classNumber: constructorObject.classNumber,
        slideNumber: constructorObject.slideIndex,
        slideSection: constructorObject.section,
        slideCourse: constructorObject.courseId,
        slideName: get(slideNameInput) || "Выполните задание",
        isPassed: "false",
        contents: [{
          id: 1,
          textContent: get(exerciseHeader),
          textArray: descriptors,
          answer: answers,
          pictureArray: pictureArrays,
          role: "exercise",
          type: "match",
          customBackground: "#C20B03",
          additionalField: "",
          ...get(mediaData),
          ...(assignAward === "true" ? { "assign_award": "true" } : {}),
          ...(badgeDetails ? { "badgeDetails": badgeDetails } : {}),
        }]
      };
  


     
  
       // submission 

    if (locationType === "builder") {
    axios.post(`${$API_BASE_URL}/uploadExercise`, exerciseData)
    .then(response => {
      console.log(response.data);
      if (response.data.result == 'success') {
        trackContentUpload.update(value => !value);
        uploading = false;
      } else {
        console.error('Failed to upload exercise:', response.data.message);
      }
    })
    .catch(error => console.error('Error uploading exercise:', error));
  } else if (locationType == "edit") {
    axios.post(`${$API_BASE_URL}/editExercise`, exerciseData)
    .then(response => {
      console.log(response.data);
      if (response.data.result == 'success') {
        trackContentUpload.update(value => !value);
        uploading = false;
      } else {
        console.error('Failed to upload exercise:', response.data.message);
      }
    })
    .catch(error => console.error('Error uploading exercise:', error));
  }
    }

    const unsubscribe = exerciseStore.subscribe(store => {
        exerciseData = {
            ...exerciseData,
            chars: store.chars,
            test: store.test,
            hasChar: store.hasChar,
            hasPdf: store.hasPdf,
            hasVideo: store.hasVideo,
            videoLink: store.videoLink,
            isListening: store.isListening,
            audioLink: store.audioLink,
            pdfLink: store.pdfLink,
            hasImage: store.hasImage,
            imageLink: store.imageLink,
        };
    });

    onDestroy(() => {
        unsubscribe();
    });
  </script>
  
  <style>
    .exercise-construction-main-wrap {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }
    .image-preview {
      width: 300px;
      height: 300px;
      object-fit: cover;
      margin: 0 auto;
      margin-block: 30px;
      border-radius: 30px;
    }
    .image-upload { 
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
    .controls {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 20px;
    }
    .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  .return-btn {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .inner-icon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0; 
    }
    .inner-text {
      overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; 
  display: inline-block;
  flex-grow: 1; 
    }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @media (max-width: 750px) {
    .image-preview {
      width: 150px;
      height: 150px;
    }
  }

  </style>
  
  <div class="exercise-construction-main-wrap">
    {#if !editObject}  
    <div class="return-btn" on:click={resetSelectedExercise}>{@html returnBtn}</div>
    {/if}
    <input class="user--class-exercise-header-slidename exercise-builder-header" type="text" bind:value={$slideNameInput} placeholder="Название задания" />
    <input class="user--class-exercise-header user--class-exercise-header-main exercise-builder-header" type="text" bind:value={$exerciseHeader} placeholder="Инструкция к заданию" />
  
    <div class="user--exercise-builder-block-match-inside">
      <div class="user--exercise-builder-block-description">Добавьте описание и изображение</div>
  
      {#each $questionBlock.miniBlocks as miniBlock, miniIndex}
        <div class="user--exercise-item-wrapper-gap">
          <div class="image-upload">
            {#if imageUploading}
              <div class="loading-huge jello-horizontal"></div>
             {:else}
            <button class="user--class-generic-button-3" on:click={() => document.getElementById(`file-upload-${miniIndex}`).click()}>Загрузить изображение</button>
            {/if}
            <input style="display: none;" type="file" id={`file-upload-${miniIndex}`} accept="image/*" on:change={(e) => handleMiniFileChange(e, miniIndex)} />
            {#if miniBlock.image}
              <img src={miniBlock.image} alt="Image Preview" class="image-preview" />
            {/if}
          </div>
  
          <input class="user--exercise-builder-sequence-input" type="text" bind:value={miniBlock.descriptor} placeholder="Описание к изображению" on:input={(e) => handleMiniDescriptorInput(miniIndex, e.target.value)} />
          <input class="user--exercise-builder-sequence-input" type="text" bind:value={miniBlock.answer} placeholder="Ответ к этому упражнению" on:input={(e) => handleMiniAnswerInput(miniIndex, e.target.value)} />
  
          <div class="controls">
            <button class="user--class-generic-button-3" on:click={() => removeMiniBlock(miniIndex)}><div class="inner-icon">{@html minusIcon}</div><span class="inner-text">Блок с вопросами</span></button>
          </div>
        </div>
      {/each}
  
      <div class="controls">
        <button class="user--class-generic-button-3" on:click={addMiniBlock}><div class="inner-icon">{@html plusIcon}</div><span class="inner-text">Блок с вопросами</span></button>
      </div>
    </div>
  {#if uploading}
    <div class="loading-huge jello-horizontal"></div>
    {:else}
    <button class="user--class-generic-button-3 user--class-generic-button-3-h" on:click={submitExercise}><div class="inner-icon">{@html uploadIcon}</div><span class="inner-text">{editObject ? 'Обновить' : 'Загрузить'}</span></button>
    {/if}
  </div>
  