<script>
  import { writable } from "svelte/store";
  import { fly, blur } from "svelte/transition";
  import { onMount } from 'svelte';
  import {get} from 'svelte/store'
  import {websocketData} from '../store.js';
  import {sendSocketEvent} from '../websocket.js';
  
  // local assets
  const activePrevious = `<svg style="width: 100%; height: 100%" viewBox="0 0 359 359" fill="none" >
<circle cx="179.5" cy="179.5" r="176" fill="white" stroke="#85ADD1" stroke-width="7"/>
<path d="M108.25 170.25H263.25C265.305 170.25 267.277 171.067 268.73 172.52C270.183 173.973 271 175.945 271 178C271 180.056 270.183 182.027 268.73 183.48C267.277 184.934 265.305 185.75 263.25 185.75H108.25C106.195 185.75 104.223 184.934 102.77 183.48C101.317 182.027 100.5 180.056 100.5 178C100.5 175.945 101.317 173.973 102.77 172.52C104.223 171.067 106.195 170.25 108.25 170.25Z" fill="#85ADD1"/>
<path d="M111.461 178.002L175.739 242.265C177.194 243.72 178.012 245.694 178.012 247.752C178.012 249.81 177.194 251.783 175.739 253.239C174.284 254.694 172.31 255.511 170.252 255.511C168.194 255.511 166.22 254.694 164.765 253.239L95.0152 183.489C94.2934 182.769 93.7208 181.914 93.3301 180.973C92.9394 180.031 92.7383 179.022 92.7383 178.002C92.7383 176.983 92.9394 175.973 93.3301 175.032C93.7208 174.09 94.2934 173.235 95.0152 172.515L164.765 102.765C166.22 101.31 168.194 100.492 170.252 100.492C172.31 100.492 174.284 101.31 175.739 102.765C177.194 104.22 178.012 106.194 178.012 108.252C178.012 110.31 177.194 112.284 175.739 113.739L111.461 178.002Z" fill="#85ADD1"/>
</svg>`;
const activeNext = `<svg style="width: 100%; height: 100%" viewBox="0 0 359 359" fill="none" >
<circle cx="179.5" cy="179.5" r="176" transform="rotate(-180 179.5 179.5)" fill="white" stroke="#85ADD1" stroke-width="7"/>
<path d="M250.75 188.75L95.7502 188.75C93.6947 188.75 91.7233 187.933 90.2697 186.48C88.8168 185.027 88 183.055 88 181C88 178.944 88.8168 176.973 90.2697 175.52C91.7233 174.066 93.6947 173.25 95.7502 173.25L250.75 173.25C252.805 173.25 254.777 174.066 256.23 175.52C257.683 176.973 258.5 178.944 258.5 181C258.5 183.055 257.683 185.027 256.23 186.48C254.777 187.933 252.805 188.75 250.75 188.75Z" fill="#85ADD1"/>
<path d="M247.539 180.998L183.261 116.735C181.806 115.28 180.988 113.306 180.988 111.248C180.988 109.19 181.806 107.217 183.261 105.761C184.716 104.306 186.69 103.489 188.748 103.489C190.806 103.489 192.78 104.306 194.235 105.761L263.985 175.511C264.707 176.231 265.279 177.086 265.67 178.027C266.061 178.969 266.262 179.978 266.262 180.998C266.262 182.017 266.061 183.027 265.67 183.968C265.279 184.91 264.707 185.765 263.985 186.485L194.235 256.235C192.78 257.69 190.806 258.508 188.748 258.508C186.69 258.508 184.716 257.69 183.261 256.235C181.806 254.78 180.988 252.806 180.988 250.748C180.988 248.69 181.806 246.716 183.261 245.261L247.539 180.998Z" fill="#85ADD1"/>
</svg>
`;
const inactiveNext = `<svg style="width: 100%; height: 100%" viewBox="0 0 359 359" fill="none" >
<circle cx="179.5" cy="179.5" r="176" transform="rotate(-180 179.5 179.5)" fill="white" stroke="#DDDDDD" stroke-width="7"/>
<path d="M250.75 188.75L95.7502 188.75C93.6947 188.75 91.7233 187.933 90.2697 186.48C88.8168 185.027 88 183.055 88 181C88 178.944 88.8168 176.973 90.2697 175.52C91.7233 174.066 93.6947 173.25 95.7502 173.25L250.75 173.25C252.805 173.25 254.777 174.066 256.23 175.52C257.683 176.973 258.5 178.944 258.5 181C258.5 183.055 257.683 185.027 256.23 186.48C254.777 187.933 252.805 188.75 250.75 188.75Z" fill="#DDDDDD"/>
<path d="M247.539 180.998L183.261 116.735C181.806 115.28 180.988 113.306 180.988 111.248C180.988 109.19 181.806 107.217 183.261 105.761C184.716 104.306 186.69 103.489 188.748 103.489C190.806 103.489 192.78 104.306 194.235 105.761L263.985 175.511C264.707 176.231 265.279 177.086 265.67 178.027C266.061 178.969 266.262 179.978 266.262 180.998C266.262 182.017 266.061 183.027 265.67 183.968C265.279 184.91 264.707 185.765 263.985 186.485L194.235 256.235C192.78 257.69 190.806 258.508 188.748 258.508C186.69 258.508 184.716 257.69 183.261 256.235C181.806 254.78 180.988 252.806 180.988 250.748C180.988 248.69 181.806 246.716 183.261 245.261L247.539 180.998Z" fill="#DDDDDD"/>
</svg>
`;
const inactivePrevious = `<svg style="width: 100%; height: 100%" viewBox="0 0 359 359" fill="none" >
<circle cx="179.5" cy="179.5" r="176" fill="white" stroke="#DDDDDD" stroke-width="7"/>
<path d="M108.25 170.25H263.25C265.305 170.25 267.277 171.067 268.73 172.52C270.183 173.973 271 175.945 271 178C271 180.056 270.183 182.027 268.73 183.48C267.277 184.934 265.305 185.75 263.25 185.75H108.25C106.195 185.75 104.223 184.934 102.77 183.48C101.317 182.027 100.5 180.056 100.5 178C100.5 175.945 101.317 173.973 102.77 172.52C104.223 171.067 106.195 170.25 108.25 170.25Z" fill="#DDDDDD"/>
<path d="M111.461 178.002L175.739 242.265C177.194 243.72 178.012 245.694 178.012 247.752C178.012 249.81 177.194 251.783 175.739 253.239C174.284 254.694 172.31 255.511 170.252 255.511C168.194 255.511 166.22 254.694 164.765 253.239L95.0152 183.489C94.2934 182.769 93.7208 181.914 93.3301 180.973C92.9394 180.031 92.7383 179.022 92.7383 178.002C92.7383 176.983 92.9394 175.973 93.3301 175.032C93.7208 174.09 94.2934 173.235 95.0152 172.515L164.765 102.765C166.22 101.31 168.194 100.492 170.252 100.492C172.31 100.492 174.284 101.31 175.739 102.765C177.194 104.22 178.012 106.194 178.012 108.252C178.012 110.31 177.194 112.284 175.739 113.739L111.461 178.002Z" fill="#DDDDDD"/>
</svg>`;

  function setSlide(slideNumber) {
    sendSocketEvent('setSlide', slideNumber); 
  }

  

  function scrollLeft() {
    const newSlidePrev = $websocketData.currentSlide > 1 ? $websocketData.currentSlide - 1 : $websocketData.currentSlide;
    sendSocketEvent('setSlide', newSlidePrev); 
  }

  function scrollRight() {
    const newSlideNext = $websocketData.currentSlide < $websocketData.slidesAmount ? $websocketData.currentSlide + 1 : $websocketData.currentSlide;
    sendSocketEvent('setSlide', newSlideNext);
  }
</script>

<div class="carousel-wrapper" in:fly={{y: 300}} out:fly={{y: 300}}>
  <div class="nav-buttons">
    <div class="nav-button { $websocketData.currentSlide === 1 ? 'disabled' : ''}" on:click={scrollLeft}>
      {@html $websocketData.currentSlide === 1 ? inactivePrevious : activePrevious}
    </div>
    <div class="nav-button { $websocketData.currentSlide === $websocketData.slidesAmount ? 'disabled' : ''}" on:click={scrollRight}>
      {@html $websocketData.currentSlide === $websocketData.slidesAmount ? inactiveNext : activeNext}
    </div>
  </div>
  <div class="circle-container">
    {#each Array($websocketData.slidesAmount) as _, index}
    <div on:click={() => { if (index + 1 === $websocketData.currentSlide) { return; } else { setSlide(index + 1); } }} class="circle {index + 1 === $websocketData.currentSlide ? 'selected' : ''} {$websocketData.slidesSet.includes(index + 1) ? 'seen' : ''}">
        {index + 1}
      </div>
    {/each}
  </div>
</div>

<style>
  .carousel-wrapper {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    height: 50%;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 40px;
    border: 3px solid #85ADD1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }

  .nav-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;
  }

  .nav-button {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .nav-button.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
  .circle-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    gap: 20px;
  }

  .circle {
    width: max-content;
    height: max-content;
    font-size: 30px;
    font-weight: 900;
    padding: 20px;
    color: #85ADD1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s linear;
  }
  .circle:hover {
    color: rgb(12, 92, 164);
  }
  .selected {
    color: rgb(12, 92, 164) !important;
    pointer-events: none;
  }
  .seen {
    position: relative;
}

.seen::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: 0;
    border-radius: 50%;
    background-color: rgb(12, 92, 164, 0.4);
}
@media (max-width: 750px) {
  .carousel-wrapper {
    width: 95%;
    z-index: 1000;
    overflow-y: overflow;
  }
  .nav-buttons {
    width: 100%;
    justify-content: space-around;
  }
}
</style>
