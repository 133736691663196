<script>
    import { writable, derived, get } from 'svelte/store';
    import { sendSocketEvent } from "../websocket.js";
    import {userStore, highlightAnswer} from '../store.js';
    import { onMount } from "svelte";

    const iconSubmitted = `<svg fill="#4CBB9A" style="width: 100%; height: 100%" viewBox="0 0 32 32"><path d="m16 0c8.836556 0 16 7.163444 16 16s-7.163444 16-16 16-16-7.163444-16-16 7.163444-16 16-16zm0 2c-7.7319865 0-14 6.2680135-14 14s6.2680135 14 14 14 14-6.2680135 14-14-6.2680135-14-14-14zm6.6208153 9.8786797c.3905243.3905242.3905243 1.0236892 0 1.4142135l-7.0710678 7.0710678c-.3626297.3626297-.9344751.3885319-1.3269928.0777064l-.0872208-.0777064-4.24264068-4.2426407c-.39052429-.3905242-.39052429-1.0236892 0-1.4142135.39052428-.3905243 1.02368928-.3905243 1.41421358 0l3.5348268 3.5348268 6.3646681-6.3632539c.3905243-.3905243 1.0236893-.3905243 1.4142136 0z"/></svg>`;
   

    export let slideData;
    export let exerciseID;
    export let location; // location can be 'revision', 'homework', or 'classroom'
    export let completedData;

    

    let particleAnswers = writable(new Array(slideData.particleFirst.length).fill(''));
    let answerFeedback = writable(new Array(slideData.particleFirst.length).fill(''));
    let submitted = writable(false);

    // Handle user input and send WebSocket event
    function handleInput(event, index) {
        particleAnswers.update(answers => {
            answers[index] = event.target.value;
            

             // Recomposed event detail object
                const eventDetails = {
            actionName: {
                kind: "particle", // This represents the type of the action
                particleIndex: index, // Index of the particle being updated
                answer: event.target.value,  // The value entered by the student
                id: exerciseID  // The exercise ID
            },
            isAllCorrect: false,  // Set to false initially
            answers: get(particleAnswers).map(ans => ans ? ans.toLocaleLowerCase('ru') : '')
            };

            sendSocketEvent('studentAnswerDynamic', eventDetails); // Send the WebSocket event to the teacher panel

            return answers;
        });
    }

    // WebSocket event handler for dynamic highlighting
    // WebSocket event handler for dynamic highlighting
$: if ($highlightAnswer.id === exerciseID) {
    const eventAnswer = $highlightAnswer.answer;
    const particleIndex = $highlightAnswer.particleIndex;

    // Ensure that particleIndex is valid and the correct answer exists
    if (typeof particleIndex !== 'undefined' && slideData.answer[particleIndex]) {
        const correctAnswer = slideData.answer[particleIndex].toLocaleLowerCase('ru');
        const userAnswer = eventAnswer.toLocaleLowerCase('ru');
        
        // Update feedback based on the highlighted answer
        answerFeedback.update(fb => {
            fb[particleIndex] = userAnswer === correctAnswer ? 'correct' : 'incorrect';
            return fb;
        });

        // Handle input with the highlighted answer
        handleInput({ target: { value: eventAnswer } }, particleIndex);
    } else {
        console.error(`Invalid particleIndex or answer: particleIndex = ${particleIndex}, answer = ${slideData.answer[particleIndex]}`);
    }
}


    function checkIfAllFieldsFilledForParticle() {
        return get(particleAnswers).every(answer => answer && answer.trim() !== '');
    }

    function checkParticleAnswers(correctAnswers, userAnswers) {
        let totalQuestions = 0;
        let totalScore = 0;

        correctAnswers.forEach((correctAnswer, index) => {
            const userAnswer = userAnswers[index] ? userAnswers[index].trim().toLowerCase() : '';
            if (correctAnswer) {
                totalQuestions++;
                const correct = correctAnswer.trim().toLowerCase();
                if (correct === userAnswer) {
                    totalScore += 100;
                    answerFeedback.update(fb => {
                        fb[index] = 'correct';
                        return fb;
                    });
                } else {
                    answerFeedback.update(fb => {
                        fb[index] = 'incorrect';
                        return fb;
                    });
                }
            } else {
                answerFeedback.update(fb => {
                    fb[index] = 'not-defined';
                    return fb;
                });
            }
        });
        return { totalQuestions, totalScore };
    }

    // Submit answers and send WebSocket event
    function submitAnswers() {
        const correctAnswers = slideData.answer.map(ans => ans.toLowerCase());
        const userAnswers = get(particleAnswers).map(ans => ans.toLowerCase());
        const { totalQuestions, totalScore } = checkParticleAnswers(correctAnswers, userAnswers);

        submitted.set(true);

        let endpoint;
        if (location === "revision") {
            endpoint = "exerciseCompletedRevision";
        } else if (location === "homework") {
            endpoint = "exerciseCompletedRevision";
        } else if (location === "classroom") {
            endpoint = "exerciseCompleted";
        }  

        const eventDetails = {
            actionName: {
                exerciseID: exerciseID,
                isCorrect: totalScore === 100 ? 'true' : 'false', // Check if all answers are correct
                isCreative: false,
                partialScore: totalScore
            },
            answers: userAnswers,
            totalQuestions: totalQuestions,
            totalScore: totalScore
        };

        sendSocketEvent(endpoint, eventDetails);
    }

    // Derived store to track if all fields are filled
    const allFieldsFilled = derived(particleAnswers, $particleAnswers => {
        return $particleAnswers.every(answer => answer && answer.trim() !== '');
    });

    // Populate answers and feedback from completed data if available
    onMount(() => {
        if (completedData && completedData.studentAnswer != null) {
            try {
                const parsedStudentAnswer = JSON.parse(completedData.studentAnswer);
                if (Array.isArray(parsedStudentAnswer)) {
                    parsedStudentAnswer.forEach((answer, index) => {
                        handleInput({ target: { value: answer.toLocaleLowerCase('ru') } }, index);
                    });

                    answerFeedback.update(fb => {
                        const ua = get(particleAnswers);
                        for (let index = 0; index < slideData.answer.length; index++) {
                            if (ua[index] && ua[index].toLocaleLowerCase('ru') === slideData.answer[index].toLocaleLowerCase('ru')) {
                                fb[index] = 'correct';
                            } else {
                                fb[index] = 'incorrect';
                            }
                        }
                        return fb;
                    });

                    submitted.set(true);
                }
            } catch (error) {
                console.error("Error parsing completedData studentAnswer:", error);
            }
        }
    });

    function proceedNext() {
        sendSocketEvent('proceedSelf', '');
    }
</script>


<div class="user--class-exercise-header">
    {@html slideData.textContent}
  </div>

  
<div class="particle-exercise">
    <div class="user--exercise-block-tablelist-wrapper">
        {#each slideData.particleFirst as particleText, index}
            <div class="user--exercise-particle-line-wrapper">
                <div class="user--exercise-particle-line-left">
                    <span>{index + 1}</span>
                    <div class="user--exercise-particle-line-left-input">{@html particleText}</div>
                </div>
                <div class="user--exercise-particle-answer-block">
                    <input
                        type="text"
                        data-particle-index={index}
                        on:input={(e) => handleInput(e, index)}
                        bind:value={$particleAnswers[index]} 
                        placeholder="Ответ"
                        style="border: 3px solid {$answerFeedback[index] === 'correct' ? 'rgba(135, 194, 177, 1)' : $answerFeedback[index] === 'incorrect' ? 'rgba(255, 147, 147, 1)' : '#0C5CA450'}"
                    />
                </div>
                <div class="user--exercise-particle-line-right">
                    <div class="user--exercise-particle-line-right-input">{@html slideData.particleSecond[index]}</div>
                    <span>{String.fromCharCode(65 + index)}</span>
                </div>
            </div>
        {/each}
    </div>

    {#if $submitted}
    {#if location == "classroom"}
      <div class="submitted-icon">{@html iconSubmitted}</div>
    {:else if (location == "homework" || location == "revision")}
    <button class="user--class-generic-button-3"  on:click={(event) => {
      event.currentTarget.classList.add('inactive');
      event.currentTarget.textContent = 'Загружаю...';
      proceedNext();
    }}
    >Продолжить</button
  >
    {/if}
      {:else}
    {#if $userStore.student_status != 0}
        <button on:click={submitAnswers} class="user--class-generic-button-3 { $allFieldsFilled ? '' : 'user--button-clicked' }">Ответить</button>
    {/if}    
    {/if}
</div>

  <style>
    .correct {
    border-color: rgba(135, 194, 177, 1);
}
.incorrect {
    border-color: rgba(255, 147, 147, 1);
}
.not-defined {
    border-color: rgba(192, 192, 192, 1);
}
.centered-div {
    width: 60px;
    height: 60px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
}
.jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
}
.submitted-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    }
@-webkit-keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
@keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
.user--exercise-item-wrapper {
    transition: border-color 0.3s;
}
.user--exercise-particle-answer-block input {
    transition: border-color 0.3s;
}

@media (max-width: 750px) {
    .user--exercise-particle-line-left, .user--exercise-particle-line-right {
        max-width: 35%;
    }
    .user--exercise-particle-answer-block {
        min-width: 20%;
    }
}

  </style>
  