<script>
  import { fly, blur } from "svelte/transition";
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { translate } from "../translation";
  import { currentSection } from "../store.js";
  import axios from "axios";
  import Cookies from "js-cookie";
  import { writable } from "svelte/store";
  import Footer from "./Footer.svelte";
  import PushNotification from "./PushNotification.svelte";
  function navigateTo(section) {
    push(`/${section}`);
  }
  function changeSection(section) {
    currentSection.set(section);
    navigateTo(section);
  }
  let notifications = [];
  function addNotification(header, message, type = "info", duration = 3000) {
    notifications = [...notifications, { header, message, type, duration }];
    setTimeout(() => {
      notifications = notifications.slice(1);
    }, duration);
  }
  let loading = true;

  let isMobile = false;

  // Check if the screen width is less than 800px
  if (typeof window !== "undefined") {
    isMobile = window.innerWidth < 800;
    window.addEventListener("resize", () => {
      isMobile = window.innerWidth < 800;
    });
  }
</script>

{#each notifications as { header, message, type, duration }}
  <PushNotification {header} {message} {type} {duration} />
{/each}
<div class="teacher-panel-wrap" in:blur={{ duration: 200 }}>
  <div class="user--myPlayground-wrap">
    <h1 class="user--myPlayground-hero">
      {translate("Инструменты", "Tools", "工具")}
    </h1>
    <div class="user--linkblock-united">
      <div
        class="user--linkblock-wrap"
        data-block="my-schedule"
        id="my-schedule"
        style="{!isMobile ? 'border-color: #cacaca50' : ''} {isMobile
          ? 'background-color: rgba(137, 115, 223, 0.2)'
          : ''}"
        on:click={() => changeSection("materialspro")}
      >
        <div
          class="user--linkblock-wrap-h1"
          style="background-color: rgba(137, 115, 223, 0.7)"
        >
          {translate("Материалы", "Materials", "材料")}
        </div>
        <div class="user--linkblock-inside">
          <div class="user--linkblock-wrap-inner">
            <div class="user--linkblock-inside-circle-icon">
              <svg viewBox="0 0 24 24" fill="none">
                <path
                  d="M2 6.75C2 5.23122 3.23122 4 4.75 4H19.25C20.7688 4 22 5.23122 22 6.75V17.25C22 18.7688 20.7688 20 19.25 20H4.75C3.23122 20 2 18.7688 2 17.25V6.75ZM6.75 8C6.33579 8 6 8.33579 6 8.75C6 9.16421 6.33579 9.5 6.75 9.5H11.25C11.6642 9.5 12 9.16421 12 8.75C12 8.33579 11.6642 8 11.25 8H6.75ZM6.75 11C6.33579 11 6 11.3358 6 11.75C6 12.1642 6.33579 12.5 6.75 12.5H15.25C15.6642 12.5 16 12.1642 16 11.75C16 11.3358 15.6642 11 15.25 11H6.75ZM6 14.75C6 15.1642 6.33579 15.5 6.75 15.5H13.25C13.6642 15.5 14 15.1642 14 14.75C14 14.3358 13.6642 14 13.25 14H6.75C6.33579 14 6 14.3358 6 14.75Z"
                  fill="#8973DF"
                ></path>
              </svg>
            </div>
            <span class="user--linkblock-wrap-inner-desc"
              >{translate(
                "Просмотрите все доступные материалы курсов, по которым вы ведёте занятия, а также наполните их с помощью встроенных инструментов",
                "View all available materials for the courses you teach and create new by using constructor tools",
                "查看您所教课程可用的材料并创造新的内容"
              )}</span
            >
            <div class="user--class-generic-button-3">
              {translate("Перейти", "Proceed", "前往")}
            </div>
          </div>
        </div>
      </div>

      <div
        class="user--linkblock-wrap"
        data-block="my-resources"
        id="my-resources"
        style="{!isMobile ? 'border-color: #cacaca50' : ''} {isMobile
          ? 'background-color: rgba(115, 223, 171, 0.2)'
          : ''}"
        on:click={() => changeSection("manage")}
      >
        <div
          class="user--linkblock-wrap-h1"
          style="background-color: rgba(115, 223, 171, 0.7)"
        >
          {translate("Управление", "Manage", "管理")}
        </div>
        <div class="user--linkblock-inside">
          <div class="user--linkblock-wrap-inner">
            <div class="user--linkblock-inside-circle-icon">
              <svg viewBox="0 0 24 24" fill="none">
                <path
                  d="M12.4445 19.6875H20.9445M14.4443 5.68747L5.44587 14.6859C4.78722 15.3446 4.26719 16.1441 4.10888 17.062C3.94903 17.9888 3.89583 19.139 4.44432 19.6875C4.99281 20.236 6.14299 20.1828 7.0698 20.0229C7.98772 19.8646 8.78722 19.3446 9.44587 18.6859L18.4443 9.68747M14.4443 5.68747C14.4443 5.68747 17.4443 2.68747 19.4443 4.68747C21.4443 6.68747 18.4443 9.68747 18.4443 9.68747M14.4443 5.68747L18.4443 9.68747"
                  stroke="#73DFAB"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
            <span class="user--linkblock-wrap-inner-desc"
              >{translate(
                "Просматривайте, добавляйте и редактируйте список занятий, учащихся и историю проведенных занятий",
                "View, add, and edit the list of lessons, students, and the history of conducted classes",
                "查看、添加和编辑课程、学生和上课历史列表"
              )}</span
            >
            <div class="user--class-generic-button-3">
              {translate("Управлять", "Manage", "管理")}
            </div>
          </div>
        </div>
      </div>

      <div
        class="user--linkblock-wrap"
        data-block="my-schedule"
        id="my-schedule"
        style="{!isMobile ? 'border-color: #cacaca50' : ''} {isMobile
          ? 'background-color: rgba(236, 97, 166, 0.2)'
          : ''}"
        on:click={() => changeSection("schedule")}
      >
        <div
          class="user--linkblock-wrap-h1"
          style="background-color: rgba(236, 97, 166, 0.7)"
        >
          {translate("Расписание", "Schedule", "课表")}
        </div>
        <div class="user--linkblock-inside">
          <div class="user--linkblock-wrap-inner">
            <div class="user--linkblock-inside-circle-icon">
              <svg fill="#ec61a6" viewBox="0 0 24 24"
                ><path
                  d="M12,14a1,1,0,1,0-1-1A1,1,0,0,0,12,14Zm5,0a1,1,0,1,0-1-1A1,1,0,0,0,17,14Zm-5,4a1,1,0,1,0-1-1A1,1,0,0,0,12,18Zm5,0a1,1,0,1,0-1-1A1,1,0,0,0,17,18ZM7,14a1,1,0,1,0-1-1A1,1,0,0,0,7,14ZM19,4H18V3a1,1,0,0,0-2,0V4H8V3A1,1,0,0,0,6,3V4H5A3,3,0,0,0,2,7V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm1,15a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V10H20ZM20,8H4V7A1,1,0,0,1,5,6H19a1,1,0,0,1,1,1ZM7,18a1,1,0,1,0-1-1A1,1,0,0,0,7,18Z"
                /></svg
              >
            </div>
            <span class="user--linkblock-wrap-inner-desc"
              >{translate(
                "Используйте расписание, чтобы просматривать доступные и занятые слоты Вашего учебного времени",
                "Preview your schedule slots",
                "预览您的课程时间段"
              )}</span
            >
            <div class="user--class-generic-button-3">
              {translate("Посмотреть", "Preview", "查看")}
            </div>
          </div>
        </div>
      </div>

      <div
        class="user--linkblock-wrap"
        data-block="my-payment"
        id="my-payment"
        style="{!isMobile ? 'border-color: #cacaca50' : ''} {isMobile
          ? 'background-color: rgba(110, 219, 180, 0.2)'
          : ''}"
        on:click={() => changeSection("payment")}
      >
        <div
          class="user--linkblock-wrap-h1"
          style="background-color: rgba(110, 219, 180, 0.7)"
        >
          {translate("Оплата", "Payment", "支付")}
        </div>
        <div class="user--linkblock-inside">
          <div class="user--linkblock-wrap-inner">
            <div class="user--linkblock-inside-circle-icon">
              <svg
                version="1.1"
                id="_x32_"
                viewBox="0 0 512 512"
                xml:space="preserve"
              >
                <style type="text/css">
                </style>
                <g>
                  <path
                    class="st0"
                    style="fill:rgb(110, 219, 180)"
                    d="M508.488,169.344c-6.422-14.469-20.484-23.844-35.766-23.844l-1.813,0.047l-10.344,0.547
		c-23.219-32.281-55.765-57.156-94.968-72.438c-25.625-10.031-54.203-16-84.938-17.719c-6.391-0.344-12.891-0.531-19.391-0.531
		c-4.672,0-9.438,0.094-14.281,0.266c-14.297,0.547-28.422,2.391-42,5.484c-2.172,0.5-4.375,1.031-6.563,1.594
		c-27.203-15.969-53.781-19.563-72.203-19.563c-1.531,0-3.016,0.031-4.438,0.063c-3.766,0.125-6.516,0.266-8.594,0.5
		c-4.188,0.438-7.844,1.563-10.953,3.188c-6.406,2.719-11.922,7.344-15.781,13.375c-5.672,9-7,19.781-3.766,29.688l12.109,37.109
		c-2.406,2.922-4.672,5.891-6.813,8.859c-9.641,13.563-16.359,26.063-22.266,37.094c-7.984,14.906-13.172,24.156-18.906,26.438
		l-0.5,0.188l-1.453,0.563l-3.219,1.219c-14.266,5.344-47.656,17.875-40.703,52.594l1.516,7.688
		c5.922,30.094,9.859,49.063,12.484,57l1.531,4.672c5.313,16.172,8.625,24.391,25.688,35.391c7.5,4.813,16.625,6.531,26.281,8.313
		c2.875,0.531,7.063,1.313,8.953,1.844c1.828,0.906,3.766,1.625,5.781,2.078c6.781,1.578,12.281,2.375,16.719,3.016
		c2.875,0.406,6.438,0.938,7.406,1.344c0.484,0.344,4.141,3.328,13.734,20.656l0.5,0.844c5.875,9.688,12.203,25.406,14.297,30.578
		c1.109,2.734,2.094,5.25,2.953,7.391c1.922,4.875,2.797,7.125,3.766,9.063c8.313,16.563,23.109,24.875,44.031,24.875h29.359
		c18.859,0,37.891-14,37.891-45.25v-5.875v-4.469h51.891v4.469v5.875c0,31.25,18.672,45.25,37.172,45.25h27.547
		c20.641,0,35.109-8.531,42.984-25.328c0.969-2.078,1.859-4.469,3.781-9.672c0.625-1.672,1.328-3.563,2.078-5.594
		c12.203-24.125,23.968-42.875,36.984-58.906c26.953-31.688,41.203-70.594,41.203-112.484c0-11.234-1.156-22.734-3.453-34.266
		c1.781-2.203,3.844-4.656,6.234-7.297C512.098,202.125,515.254,184.563,508.488,169.344z M480.848,197.719
		c-7.781,8.563-12.75,15.625-15.031,19.063c3.703,13.75,5.5,27.313,5.5,40.047c0,38.453-13.75,70.672-35.172,95.766
		c-17.14,21.094-30.187,43.906-40.843,65.094l0,0c-2.734,7.25-4.766,12.938-5.563,14.688c-2.75,5.875-6.891,10.281-19.297,10.281
		c-12.391,0-19.281,0-27.547,0c-8.25,0-11-8.813-11-19.094c0-1.594,0-3.594,0-5.875v-30.641H227.661v30.641c0,2.281,0,4.281,0,5.875
		c0,10.281-2.938,19.094-11.719,19.094c-8.813,0-16.172,0-29.359,0c-13.234,0-17.625-4.406-20.563-10.281
		c-0.859-1.75-3-7.438-5.922-14.688c-4.234-10.5-10.125-24.344-16.172-34.344c-22.188-40-26.547-31.25-54.875-37.781l-0.016-0.031
		c-6.313-3.625-26.594-4.781-32.703-8.719c-11.109-7.156-10.656-8.656-16.547-26.281c-3.109-9.375-10.609-48.688-13.188-61.625
		c-2.813-14.016,13.156-18.688,27.844-24.313l2.016-0.781c23.391-9.281,28.844-38.938,52.844-72.656
		c4.297-6.016,9.375-12,15.094-17.75l-16.828-51.5c-0.813-2.5-0.484-5.25,0.938-7.5c1.438-2.25,3.781-3.75,6.438-4.063
		c0,0-3.656-0.594,7.656-0.938c1.172-0.031,2.375-0.047,3.625-0.047c17.422,0,42.906,3.891,68.016,21.828
		c5.313-1.688,10.828-3.219,16.547-4.531c11.688-2.656,24.063-4.344,37.188-4.844c4.5-0.172,8.938-0.25,13.297-0.25
		c6.109,0,12.078,0.156,17.906,0.469c29.313,1.688,54.859,7.375,76.906,16c42.781,16.656,72.421,44.344,90.921,74.938
		c13.734-0.688,15.797-0.797,25.188-1.281h0.531C482.77,171.688,491.801,185.578,480.848,197.719z"
                  />
                  <path
                    class="st0"
                    style="fill:rgb(110, 219, 180)"
                    d="M125.458,224.313c-10.781,0-19.516,8.719-19.516,19.516c0,10.766,8.734,19.516,19.516,19.516
		s19.516-8.75,19.516-19.516C144.973,233.031,136.239,224.313,125.458,224.313z"
                  />
                  <path
                    class="st0"
                    style="fill:rgb(110, 219, 180)"
                    d="M248.942,106.547l-1.344,24.297c2.125,0.063,4.547,0.188,7.531,0.344c22.125,1.188,56.031,3.125,98.141,14.75
		l1.375-25.094c-43.672-11.094-75.781-12.688-98.188-13.938C253.598,106.75,251.098,106.656,248.942,106.547z"
                  />
                </g>
              </svg>
            </div>
            <span class="user--linkblock-wrap-inner-desc"
              >{translate(
                "Просматривайте и контролируйте статус оплаты занятий Вашими учениками",
                "Preview and control the payment status the classes that you've ceated",
                "查看并更改您安排的课付款记录和动态"
              )}</span
            >
            <div class="user--class-generic-button-3">
              {translate("Посмотреть", "Preview", "查看")}
            </div>
          </div>
        </div>
      </div>

      <div
        class="user--linkblock-wrap"
        data-block="assignmentcheck"
        id="assignmentcheck"
        style="{!isMobile ? 'border-color: #cacaca50' : ''} {isMobile
          ? 'background-color: rgba(224, 136, 147, 0.2)'
          : ''}"
        on:click={() => changeSection("assignmentcheck")}
      >
        <div
          class="user--linkblock-wrap-h1"
          style="background-color: rgba(224, 136, 147, 0.7)"
        >
          {translate("Проверка заданий", "Assignment check", "学生作业")}
        </div>
        <div class="user--linkblock-inside">
          <div class="user--linkblock-wrap-inner">
            <div class="user--linkblock-inside-circle-icon">
              <svg viewBox="0 0 24 24" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6ZM17.8 8.6C18.1314 8.15817 18.0418 7.53137 17.6 7.2C17.1582 6.86863 16.5314 6.95817 16.2 7.4L10.8918 14.4776L8.70711 12.2929C8.31658 11.9024 7.68342 11.9024 7.29289 12.2929C6.90237 12.6834 6.90237 13.3166 7.29289 13.7071L10.2929 16.7071C10.4979 16.9121 10.7817 17.018 11.0709 16.9975C11.3601 16.9769 11.6261 16.8319 11.8 16.6L17.8 8.6Z"
                  fill="#E08893"
                ></path>
              </svg>
            </div>
            <span class="user--linkblock-wrap-inner-desc"
              >{translate(
                "Просмотрите и проверьте ответы ваших учащихся на те вопросы, которые предполагают развернутый или неопределённый ответ",
                "Review and check your students' answers to questions that require detailed or open-ended answers",
                "查看并检查学生对需要详细或开放式回答的问题的答案"
              )}</span
            >
            <div class="user--class-generic-button-3">
              {translate("Посмотреть", "Preview", "查看")}
            </div>
          </div>
        </div>
      </div>

      <div
        class="user--linkblock-wrap"
        data-block="my-library"
        id="my-library"
        style="{!isMobile ? 'border-color: #cacaca50' : ''} {isMobile
          ? 'background-color: rgba(61, 174, 230, 0.2)'
          : ''}"
        on:click={() => changeSection("library")}
      >
        <div
          class="user--linkblock-wrap-h1"
          style="background-color: rgba(61, 174, 230, 0.7)"
        >
          {translate("Библиотека", "Library", "浏览材料")}
        </div>
        <div class="user--linkblock-inside">
          <div class="user--linkblock-wrap-inner">
            <div class="user--linkblock-inside-circle-icon">
              <svg viewBox="0 0 60 53" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M59.1833 5.3C59.1833 2.95729 56.1031 2.124 54.0653 1.57272C53.8781 1.52208 53.6997 1.4738 53.5332 1.42697C50.9883 0.710677 47.3741 0 43.0424 0C38.4051 0 34.5816 1.5211 31.956 2.99916C31.5544 3.22524 31.132 3.53754 30.7503 3.81979C30.2462 4.1927 29.8131 4.51316 29.5917 4.51316C29.3703 4.51316 28.9372 4.1927 28.433 3.81979C28.0513 3.53754 27.6289 3.22524 27.2273 2.99916C24.6017 1.5211 20.7781 0 16.1409 0C11.8092 0 8.19501 0.710677 5.65007 1.42697C5.49003 1.472 5.31856 1.5185 5.13862 1.56732C3.10982 2.11764 0 2.96116 0 5.3V39.75C0 40.6685 0.482748 41.5213 1.27586 42.0044C2.53759 42.7723 4.20266 42.1 5.67821 41.5043C6.19297 41.2963 6.68468 41.0978 7.12815 40.973C9.29098 40.3643 12.4022 39.75 16.1409 39.75C19.534 39.75 22.0741 40.8527 23.9784 42.007C24.4675 42.3035 24.9619 42.6817 25.4693 43.0697C26.7234 44.0292 28.0577 45.05 29.5917 45.05C31.1299 45.05 32.4575 44.0329 33.7095 43.0739C34.2174 42.6846 34.7129 42.3051 35.2049 42.007C37.1093 40.8527 39.6493 39.75 43.0424 39.75C46.7812 39.75 49.8924 40.3643 52.0552 40.973C52.519 41.1036 53.0226 41.313 53.5453 41.5305C54.9983 42.135 56.5989 42.8009 57.9074 42.0044C58.7005 41.5213 59.1833 40.6685 59.1833 39.75V5.3ZM6.27483 6.77735C5.73441 6.94727 5.3803 7.44857 5.3803 8.0074V34.1826C5.3803 35.0675 6.24355 35.7042 7.11671 35.497C9.51176 34.9289 12.5839 34.45 16.1409 34.45C20.8839 34.45 24.425 36.0384 26.9015 37.5561V9.15753C26.3605 8.73421 25.5664 8.16849 24.5581 7.60084C22.476 6.4289 19.5741 5.3 16.1409 5.3C11.8773 5.3 8.41988 6.10287 6.27483 6.77735ZM43.0424 34.45C38.2994 34.45 34.7584 36.0384 32.2818 37.5561V9.15753C32.8228 8.73421 33.6169 8.16849 34.6252 7.60084C36.7074 6.4289 39.6093 5.3 43.0424 5.3C47.306 5.3 50.7634 6.10287 52.9086 6.77735C53.449 6.94727 53.803 7.44857 53.803 8.0074V34.1826C53.803 35.0675 52.9398 35.7042 52.0665 35.497C49.6715 34.9289 46.5993 34.45 43.0424 34.45Z"
                  fill="#3daee6"
                ></path>
                <path
                  d="M2.88641 48.7831C2.27816 47.4475 2.88609 45.8797 4.24422 45.2814C5.50589 44.7299 6.81204 44.2664 8.12571 43.8517C10.3052 43.164 13.3188 42.4 16.1235 42.4C19.2677 42.4 21.9513 44.0666 23.8963 45.2745C23.9961 45.3365 24.094 45.3972 24.1899 45.4565C26.4561 46.8576 27.9803 47.7 29.596 47.7C31.2097 47.7 32.7248 46.8597 34.9882 45.4576C35.0811 45.4001 35.1757 45.3413 35.2722 45.2811C37.2189 44.0703 39.9043 42.4 43.0684 42.4C45.8882 42.4 48.9012 43.1635 51.0797 43.8522C53.0359 44.4707 54.4871 45.0824 54.86 45.2435C56.2234 45.8365 56.9297 47.4199 56.3032 48.7878C55.6967 50.1131 54.0778 50.7011 52.7346 50.1139C51.6592 49.6475 50.5486 49.2516 49.4301 48.8978C47.4178 48.2616 45.0418 47.7 43.0684 47.7C41.5619 47.7 40.1144 48.5443 37.698 50.0429C35.6949 51.2852 32.9298 53 29.596 53C26.2599 53 23.4858 51.2831 21.477 50.0397C19.0558 48.5409 17.6115 47.7 16.1235 47.7C14.1667 47.7 11.7914 48.261 9.77224 48.8984C8.64731 49.2532 7.52738 49.648 6.44624 50.1189C5.08822 50.7165 3.49456 50.1187 2.88641 48.7831Z"
                  fill="#3daee6"
                ></path>
              </svg>
            </div>
            <span class="user--linkblock-wrap-inner-desc"
              >{translate(
                "Просматривайте публично доступные материалы и используйте в своих курсах",
                "Browse and use publicly available materials in your courses",
                "浏览材料"
              )}</span
            >
            <div class="user--class-generic-button-3">
              {translate("Посмотреть", "Browse", "查看")}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<Footer />

<style>
  .teacher-panel-wrap {
    width: 100%;
    height: max-content;
  }
</style>
