<script>
  import { onMount } from "svelte";
  import { writable, get } from "svelte/store";
  import { userStore, websocketData } from "../store.js";
  import axios from "axios";
  import Exercise from "./Exercise.svelte";
  import PushNotification from "./PushNotification.svelte";
  import { sendSocketEvent } from "../websocket.js";

  function safeJSONParse(value) {
      if (typeof value === "string") {
        try {
          return JSON.parse(value);
        } catch (error) {
          return null; 
        }
      }

  return value; 
}


  let notifications = [];
  let loading = writable(true);
  let slideData = writable(null);
  let exerciseID = writable(null);
  let location = "revision";

  function addNotification(header, message, type = "info", duration = 3000) {
    notifications = [...notifications, { header, message, type, duration }];
    setTimeout(() => {
      notifications = notifications.slice(1);
    }, duration);
  }

  const handleNotification = (event) => {
    const { header, message, type, duration } = event.detail;
    addNotification(header, message, type, duration);
  };

  let isFetchingRevision = writable(true);

  function fetchRevision() {
    sendSocketEvent("loadRevision");
    //   readinessConfirmationSent = true;
  }

  $: if ($websocketData && typeof $websocketData === "object" && Object.keys($websocketData).length > 0) {
    // console.log('[DEBUG] - wsdata (Revision):', $websocketData)
    isFetchingRevision.set(false);

  if ($websocketData.slideData) {
    const parsedSlideData = safeJSONParse($websocketData.slideData);
    if (parsedSlideData) {
      slideData.set(parsedSlideData); // Set parsed data to the store
      console.log(parsedSlideData);
    }
  }
}


  onMount(() => {
    fetchRevision();
  });
</script>

{#each notifications as { header, message, type, duration }}
  <PushNotification {header} {message} {type} {duration} />
{/each}

<div class="user--block-expanded-wrapper" in:blur={{ duration: 200 }}>
  {#if $isFetchingRevision}
    <div class="loading-huge jello-horizontal"></div>
    {:else if $websocketData.slideData && safeJSONParse($websocketData.slideData)?.isExercise == "true"}
    <Exercise
      slideData={safeJSONParse($websocketData.slideData)}
      exerciseID={1}
      location="revision"
    />
  {:else}
    <span class="null-contents-display">Доступных для повторений заданий не найдено</span>
  
  {/if}
</div>

<style>
  .centered-div {
    width: 60px;
    height: 60px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  .null-contents-display {
    font-size: 23px;
    font-weight: 350;
    color: #5c5c5c;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
</style>
