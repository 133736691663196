<script>
  import { fly, blur } from "svelte/transition";
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { isAuthorized, userStore, API_BASE_URL } from "../store.js";
  import { writable } from "svelte/store";
  import { translate } from "../translation";
  import axios from "axios";
  import PushNotification from "./PushNotification.svelte";
  import ParticleExercise from "./ParticleExercise.svelte";

  let notifications = [];
  let emptyNotification = false;
  let payments = writable([]);
  let formingPayment = writable(false);
  let paymentsLoaded = writable(false);
  let section = 2;
  let visiblePaymentsCount = writable(10);
  function addNotification(header, message, type = "info", duration = 3000) {
    notifications = [...notifications, { header, message, type, duration }];
    setTimeout(() => {
      notifications = notifications.slice(1);
    }, duration);
  }

  async function formPayment(paymentObject) {
    // console.log(paymentObject);
    formingPayment.set(true);

    const paymentObj = {
      token: $userStore.token,
      class_id: paymentObject.class_id,
      student_id: paymentObject.student_id,
      amount: parseInt(paymentObject.class_price),
      payment_id: paymentObject.payment_id,
    };

   // console.log("prepared payment object:", paymentObj);

    try {
      const response = await axios.post(
        `${$API_BASE_URL}/formPayment`,
        paymentObj
      );
      // console.log(response.data);
      if (response.data.confirmation_url) {
        addNotification(
          "Готовимся к оплате",
          'После оплаты не забудь нажать кнопку "вернуться в магазин"',
          "info",
          5000
        );
        window.location.href = response.data.confirmation_url;
      } else {
        console.error("Error forming payment:", response.data.message);
        alert(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error("Error during payment formation:", error);
      alert("An unexpected error occurred while forming the payment.");
    } finally {
      formingPayment.set(false);
    }
  }

  function fetchPayments() {
    // console.log($userStore);
    axios
      .post(`${$API_BASE_URL}/fetchPayments`, {
        token: $userStore.token,
        PayActiveSection: section,
        userStatus: $userStore.student_status,
      })
      .then((response) => {
       // console.log("fetchPayments response:", response.data);
        payments.set(response.data.data);
        paymentsLoaded.set(true);
        if (response.data.data.length === 0) {
          emptyNotification = true;
        }
      })
      .catch((error) => console.error("Error fetching payments:", error));
  }

  onMount(() => {
    fetchPayments();
  });

  function selectSection(sec) {
    section = sec;
    fetchPayments(); 
  }

  function translateMonth(month) {
    const months = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];
    return months[month - 1];
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-based
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day} ${translateMonth(month)}, ${hours}:${minutes}`;
  }
  function showMorePayments() {
    visiblePaymentsCount.update((n) => n + 10);
  }

  function translateStatus(status) {
    switch (status) {
      case "pending":
        return "Неоплачено";
      case "paid":
        return "Оплачено";
      case "completed":
        return "Завершено";
      default:
        return status;
    }
  }
</script>

{#each notifications as { header, message, type, duration }}
  <PushNotification {header} {message} {type} {duration} />
{/each}

<div class="user--block-expanded-wrapper">
  <div class="section-selector">
    <div
      class="section-select-button {section === 1 ? 'active' : ''}"
      on:click={() => selectSection(1)}
    >
      Все
    </div>
    <div
      class="section-select-button {section === 2 ? 'active' : ''}"
      on:click={() => selectSection(2)}
    >
      Недавние
    </div>
  </div>

  <!-- forEach -->
  {#if $paymentsLoaded}
  {#if emptyNotification}
    <span class="null-contents-display"
    >Информация о платежах недоступна</span
  >
  {/if}
    {#each $payments.slice(0, $visiblePaymentsCount) as payment}
      <div class="payment-block">
        <div class="payment-header">
          {formatDate(payment.start_time)}
          {$userStore.student_status === "0" ? ", " + payment.student_name : ""}
        </div>
        <div class="payment-status {payment.payment_status}">
          {translateStatus(payment.payment_status)}
        </div>
        <div class="payment-comment">
          {#if payment.payment_status == "pending"}
            {$userStore.student_status === "0"
              ? `Учащийся ${payment.student_name} ещё не оплатил занятие, которое вы создали. Не забудьте напомнить ученику оплатить его вовремя. Ваша ставка: ${payment.class_price} руб.`
              : `Ваш преподаватель ${payment.teacher_name} ожидает оплату занятия. Стоимость занятия: ${payment.class_price} руб.`}
          {:else if payment.payment_status == "paid"}
            {$userStore.student_status === "0"
              ? `Учащийся ${payment.student_name} оплатил созданное вами занятие. Ставка: ${payment.class_price} руб.`
              : `Вы оплатили занятие с преподавателем ${payment.teacher_name} по ставке ${payment.class_price} руб.`}
          {:else if payment.payment_status == "complete"}
            {$userStore.student_status === "0"
              ? `Занятие с учащимся ${payment.student_name} было успешно проведено. Ставка: ${payment.class_price} руб.`
              : `Ваше занятие с преподавателем ${payment.teacher_name} было успешно проведено`}
          {/if}
        </div>
        {#if $userStore.student_status === "1" && payment.payment_status === "pending"}
          {#if $formingPayment}
            <div class="loading-huge jello-horizontal"></div>
          {:else}
            <div
              class="user--class-generic-button-3-tiny"
              on:click={() => {
                formPayment(payment);
              }}
            >
              Оплатить
            </div>
          {/if}
        {/if}
      </div>
    {/each}

    {#if $payments.length > $visiblePaymentsCount}
      <div
        class="user--class-generic-button-3-tiny"
        on:click={showMorePayments}
      >
        Показать больше
      </div>
    {/if}
  {:else}
    <div class="loading-huge jello-horizontal"></div>
  {/if}
  <!-- /forEach -->
</div>

<style>
  .payment-block {
    width: 100%;
    height: max-content;
    padding: 40px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 3px solid #0c5ca450;
  }
  .payment-header {
    width: max-content;
    height: max-content;
    font-size: 35px;
    font-weight: 900;
    color: #5c5c5c;
  }
  .payment-status {
    width: max-content;
    height: max-content;
    font-size: 30px;
    font-weight: 900;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 50px;
  }
  .unpaid {
    background-color: #ff9696;
  }
  .paid {
    background-color: #0c5ca450;
  }
  .complete {
    background-color: #cfeedf;
  }
  .payment-comment {
    width: 70%;
    height: max-content;
    font-size: 22px;
    font-weight: 350;
    color: #5c5c5c;
    text-align: center;
  }
  .active {
    background-color: #0c5ca460 !important;
    color: #ffffff !important;
  }
  .section-select-button:hover {
    background-color: #0c5ca460;
    color: #ffffff;
  }
  .section-selector {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: max-content;
    height: max-content;
    padding: 10px;
    border-radius: 50px;
    background-color: #0c5ca420;
  }
  .pending {
    background-color: #ff9696;
  }
  .paid {
    background-color: #42be82;
  }
  .complete {
    background-color: #0c5ca450;
  }
  .section-select-button {
    width: max-content;
    height: max-content;
    padding-block: 15px;
    padding-inline: 25px;
    background-color: #ffffff;
    color: rgba(12, 85, 152, 0.8);
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    border-radius: 100px;
    margin: 0 auto;
    transition: all 0.2s linear;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .loading-huge {
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #020344, #28b8d5);
    border-radius: 50%;
    position: relative;
  }
  .jello-horizontal {
    -webkit-animation: jello-horizontal 0.9s infinite both;
    animation: jello-horizontal 0.9s infinite both;
  }
  @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  .null-contents-display {
    font-size: 23px;
    font-weight: 350;
    color: #5c5c5c;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  @media (max-width: 750px) {
    .payment-header {
      width: 100%;
      margin: 0 auto;
      font-size: 25px;
      text-align: center;
    }
    .payment-comment {
      width: 100%;
    }
    .payment-status {
      font-size: 25px;
      padding: 10px 30px;
    }
    .payment-block {
      padding: 15px;
    }

  }
</style>
